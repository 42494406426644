import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col
} from "reactstrap";

// core components

function SectionBlog6(props = {}) {
  const { cookies, form, webimageArr = [] } = props;
  // const { text2 = '', text3 = '' } = form;
  const dbLang = cookies.get('dbLang') || 'name';
  let arr = []
  arr = webimageArr.filter(item => item.navId === form.objectId)
  if (!arr.length) {
    arr = [{ icon: "nc-app", title1name: "標題", content1name: '內文' },
    { icon: "nc-app", title1name: "標題", content1name: '內文' },
    { icon: "nc-app", title1name: "標題", content1name: '內文' },
    { icon: "nc-app", title1name: "標題", content1name: '內文' }]
  }
  return (
    <>
      <div className="blog-3" style={{ margin: '100px 0px' }}>
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="10">
              {/* <h2 className="title" style={{ fontSize: '40px', textAlign: 'center' }}>{text2}</h2> */}
              {/* <h2 className="title" style={{ fontSize: '40px', textAlign: 'center' }}>{form['text2' + dbLang] || ''}</h2> */}
              <h2 dangerouslySetInnerHTML={{ __html: `<p style="text-align: center; font-family: sans-serif; font-size: 45px;">${form['text2' + dbLang] || ''}</p>` }}></h2>
              <br />
              <br />
              <br />
              {arr.map((item, index) => <>
                {index % 2 === 0 ?
                  <Card className="card-plain card-blog">
                    <Row>
                      <Col md="4" xs="4">
                        <div className="card-image">
                          <img
                            alt="..."
                            className="img"
                            style={{ width: '100%', height: '100%' }}
                            src={(item.file1 && item.file1.url) || require("assets/img/sections/jeff-sheldon.jpg")}
                          />
                        </div>
                      </Col>
                      <Col md="8" xs="8">
                        <CardBody>
                          {/* <h6 className="card-category text-info">Enterprise</h6> */}
                          <CardTitle tag="h3">
                            <a style={{ fontSize: '22px', fontWeight: '400', color: `${index % 4 ? '#6BD197' : '#50BDDA'}` }} href="#pablo" onClick={e => e.preventDefault()}>
                              {/* {item['title1' + dbLang] || ''} */}
                              <p dangerouslySetInnerHTML={{ __html: `<p style="font-size: 30px;">${item['title1' + dbLang] || ''}</p>` }}></p>
                            </a>
                          </CardTitle>
                          <p className="card-description">
                            {/* {item['content1' + dbLang] || ''} */}
                            <p dangerouslySetInnerHTML={{ __html: `<p style="font-size: 20px;">${item['content1' + dbLang] || ''}</p>` }}></p>
                            {/* No matter what happens — funding versus acquisition
                            versus who knows what — Ljung says SoundCloud is not
                            going away “anytime in the foreseeable future.” I hope
                            that’s true, but I can’t help but feel a bit
                          skeptical. — and…{" "}
                            <a href="#pablo" onClick={e => e.preventDefault()}>
                              Read More
                          </a>
                          </p>
                          <p className="author">
                            by{" "}
                            <a href="#pablo" onClick={e => e.preventDefault()}>
                              <b>Megan Rose Dickey</b>
                            </a>
                          , 11 days ago */}
                          </p>
                          {/* <p className="author">
                            by{" "}
                            <a href="#pablo" onClick={e => e.preventDefault()}>
                              <b>Megan Rose Dickey</b>
                            </a>
                          , 11 days ago
                          </p> */}
                        </CardBody>
                      </Col>
                    </Row>
                  </Card> :
                  index === 3 ?
                    <Card className="card-plain card-blog">
                      <Row>
                        <Col md="8" xs="8">
                          <CardBody>
                            <CardTitle tag="h3">
                              <a style={{ fontSize: '22px', fontWeight: '400', color: `${index % 3 ? '#F7C23C' : '#F55A3D'}` }} href="#pablo" onClick={e => e.preventDefault()}>
                                {/* {item['title1' + dbLang] || ''} */}
                                <p dangerouslySetInnerHTML={{ __html: `<p style="font-size: 30px;">${item['title1' + dbLang] || ''}</p>` }}></p>
                              </a>
                            </CardTitle>
                            <p className="card-description">
                              {/* {item['content1' + dbLang] || ''} */}
                              <p dangerouslySetInnerHTML={{ __html: `<p style="font-size: 20px;">${item['content1' + dbLang] || ''}</p>` }}></p>
                            </p>
                          </CardBody>
                        </Col>
                        <Col md="4" xs="4">
                          <div className="card-image" style={{ overflow: 'hidden', borderRadius: '15px' }}>
                            <a href="#pablo" onClick={e => e.preventDefault()}>
                              <img
                                alt="..."
                                className="img"
                                style={{ marginTop: '-70%', marginBottom: '-5%' }}
                                src={(item.file1 && item.file1.url) || require("assets/img/sections/rawpixel-comm.jpg")}
                              />
                            </a>
                          </div>
                        </Col>
                      </Row>
                    </Card> :
                    <Card className="card-plain card-blog">
                      <Row>
                        <Col md="8" xs="8">
                          <CardBody>
                            {/* <h6 className="card-category text-danger">
                            <i className="fa fa-free-code-camp mr-1" />
                          Trending
                        </h6> */}
                            <CardTitle tag="h3">
                              <a style={{ fontSize: '22px', fontWeight: '400', color: `${index % 3 ? '#F7C23C' : '#F55A3D'}` }} href="#pablo" onClick={e => e.preventDefault()}>
                                {/* {item['title1' + dbLang] || ''} */}
                                <p dangerouslySetInnerHTML={{ __html: `<p style="font-size: 30px;">${item['title1' + dbLang] || ''}</p>` }}></p>
                              </a>
                            </CardTitle>
                            <p className="card-description">
                              {/* {item['content1' + dbLang] || ''} */}
                              <p dangerouslySetInnerHTML={{ __html: `<p style="font-size: 20px;">${item['content1' + dbLang] || ''}</p>` }}></p>
                              {/* These issues might be making it a bit harder for Uber
                            to hire right now in the competitive Silicon Valley
                            job market. Acqui-hiring companies like it’s doing
                            here with Swipe Labs lets it roll up a bunch of good
                          talent — and…{" "}
                            <a href="#pablo" onClick={e => e.preventDefault()}>
                              Read More
                          </a>
                          </p>
                          <p className="author">
                            by{" "}
                            <a href="#pablo" onClick={e => e.preventDefault()}>
                              <b>Josh Constine</b>
                            </a>
                          , 2 days ago */}
                            </p>
                            {/* <p className="author">
                            by{" "}
                            <a href="#pablo" onClick={e => e.preventDefault()}>
                              <b>Megan Rose Dickey</b>
                            </a>
                          , 2 days ago
                          </p> */}
                          </CardBody>
                        </Col>
                        <Col md="4" xs="4">
                          <div className="card-image">
                            <a href="#pablo" onClick={e => e.preventDefault()}>
                              <img
                                alt="..."
                                className="img"
                                style={{ width: '100%', height: '100%' }}
                                // style={{ marginTop: '-100px' }}
                                src={(item.file1 && item.file1.url) || require("assets/img/sections/rawpixel-comm.jpg")}
                              />
                            </a>
                          </div>
                        </Col>
                      </Row>
                    </Card>}
              </>)}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionBlog6;