import React from 'react';
// react plugin used to create DropdownMenu for selecting items
import Select from 'react-select';

// reactstrap components
import { Button, FormGroup, Container, Row, Col } from 'reactstrap';

// core components

function SectionFooterAreas4(props = {}) {
  const { form, companyObj } = props;
  // console.log(companyObj)
  const {
    text2 = 'text2',
    text3 = 'text3',
    text4 = 'text4',
    text5 = 'text5',
    text6 = 'text6',
    text7 = 'text7',
    text8 = 'text8',
    text9 = 'text9',
    text10 = 'text10',
    text11 = 'text11',
    text12 = 'text12',
    text13 = 'text13',
    text14 = 'text14',
    text15 = 'text15',
    text16 = 'text16',
    text17 = 'text17',
    text18 = 'text18',
    text19 = 'text19',
    text20 = 'text20',
    text21 = 'text21',
  } = form;
  const [languageSelect, setLanguageSelect] = React.useState({
    value: 'en',
    label: 'English',
  });
  const [curencySelect, setCurencySelect] = React.useState({
    value: 'USD',
    label: 'USD',
  });
  return (
    <>
      <div className="separator" />
      <footer className="footer footer-black footer-big">
        <Container>
          <Row>
            <Col className="text-center ml-auto mr-auto" md="2" sm="3" xs="12">
              <h4>{text2}</h4>
              <div className="social-area">
                <Button
                  className="btn-just-icon btn-round mr-1"
                  color="facebook"
                >
                  <i className="fa fa-facebook" />
                </Button>
                <Button
                  className="btn-just-icon btn-round mr-1"
                  color="twitter"
                >
                  <i className="fa fa-twitter" />
                </Button>
                <Button className="btn-just-icon btn-round" color="google">
                  <i className="fa fa-google-plus" />
                </Button>
              </div>
            </Col>
            <Col className="ml-auto mr-auto" md="9" sm="9" xs="12">
              <Row>
                <Col md="3" sm="3" xs="6">
                  <div className="links">
                    <ul className="uppercase-links stacked-links">
                      <li>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          {text3}
                        </a>
                      </li>
                      <li>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          {text4}
                        </a>
                      </li>
                      <li>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          {text5}
                        </a>
                      </li>
                      <li>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          {text6}
                        </a>
                      </li>
                      <li>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          {text7}
                        </a>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col md="3" sm="3" xs="6">
                  <div className="links">
                    <ul className="uppercase-links stacked-links">
                      <li>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          {text8}
                        </a>
                      </li>
                      <li>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          {text9}
                        </a>
                      </li>
                      <li>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          {text10}
                        </a>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col md="3" sm="3" xs="6">
                  <div className="links">
                    <ul className="uppercase-links stacked-links">
                      <li>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          {text11}
                        </a>
                      </li>
                      <li>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          {text12}
                        </a>
                      </li>
                      <li>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          {text13}
                        </a>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col md="3" sm="3" xs="6">
                  <div className="links">
                    <ul className="stacked-links">
                      <li>
                        <h4>
                          {text14} <br />
                          <small>{text15}</small>
                        </h4>
                      </li>
                      <li>
                        <h4>
                          {text16} <br />
                          <small>{text17}</small>
                        </h4>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
              <hr />
              <div className="copyright">
                <div className="pull-left">
                  © {new Date().getFullYear()} {companyObj.name}
                </div>
                <div className="links pull-right">
                  <ul>
                    <li className="mr-1">
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        {text18}
                      </a>
                    </li>
                    |{' '}
                    <li className="mr-1">
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        {text19}
                      </a>
                    </li>
                    |{' '}
                    <li>
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        {text20}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default SectionFooterAreas4;
