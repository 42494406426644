import React, { Component } from 'react';
import { Container, Header, Form, Icon, Button } from 'semantic-ui-react';

export class ModalEditSocial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      imagePreviewUrlArr: [],
      photo: '',
      form: [],
      addressText: '',
      phoneText: '',
      timeText: '',
      emailText: '',
    };
  }

  componentDidMount = () => { };

  handleChange = id => {
    const { form } = this.state;
    const { value } = document.getElementById(id);
    this.setState({ form: { ...form, [id]: value } });
  };

  handleColorDropdown = () => {
    const { colorDropdown } = this.state;
    if (colorDropdown === false) {
      const colorDropdown = document.getElementById('colorDropdown');
      colorDropdown.style.display = 'grid';
      this.setState({ colorDropdown: true });
    } else {
      const colorDropdown = document.getElementById('colorDropdown');
      colorDropdown.style.display = 'none';
      this.setState({ colorDropdown: false });
    }
  };

  closeEdit = () => {
    this.setState({ show: false, form: {} });
  };

  onEdit = () => {
    this.setState({ show: true });
  };

  render() {
    const { handleSocial, id } = this.props;
    const { show, form } = this.state;
    return (<>
      <a className="j-pop-image" onClick={this.onEdit}>編輯</a>
      <Modal open={show} onClose={this.closeEdit} size="lg">
        <Modal.Header>
          <Modal.Title>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="white-popup" id="popup-sociallinksetting">
            <form className="setBox" data-parsley-required-message="尚未填寫">
              <div className="top_title">
                <h3>社群連結設定</h3>
                <p>善用社群網路串連，從裏到外整合到其他社群</p>
              </div>
              <div className="fillinBox">
                <div
                  className="color-edit-toolbar"
                  onClick={this.handleColorDropdown}
                >
                  <a className="j-n-dropdown icon-bgcolor">
                    <span
                      className="circleline btn_in"
                      style={{ backgroundColor: 'rgb(0, 0, 0)' }}
                    />
                    <span>圖示顏色</span>
                  </a>
                  <div
                    className="j-n-list-color choose-iconcolor"
                    id="colorDropdown"
                    style={{ display: 'none' }}
                  >
                    <a
                      className="j-n-action default"
                      data-css="color"
                      data-value=""
                    >
                      <i className="fas fa-ban" />
                      預設
                    </a>
                    <ul className="the-colors clearfix">
                      <li>
                        <a
                          className="j-n-action"
                          title="#F44336"
                          data-css="color"
                          data-value="#F44336"
                          style={{ backgroundColor: '#F44336' }}
                        >
                          <i
                            className="fas fa-check"
                            style={{ display: 'none' }}
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          className="j-n-action"
                          title="#E91E63"
                          data-css="color"
                          data-value="#E91E63"
                          style={{ backgroundColor: '#E91E63' }}
                        >
                          <i
                            className="fas fa-check"
                            style={{ display: 'none' }}
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          className="j-n-action"
                          title="#9C27B0"
                          data-css="color"
                          data-value="#9C27B0"
                          style={{ backgroundColor: '#9C27B0' }}
                        >
                          <i
                            className="fas fa-check"
                            style={{ display: 'none' }}
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          className="j-n-action"
                          title="#014D88"
                          data-css="color"
                          data-value="#014D88"
                          style={{ backgroundColor: '#014D88' }}
                        >
                          <i
                            className="fas fa-check"
                            style={{ display: 'none' }}
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          className="j-n-action"
                          title="#2196F3"
                          data-css="color"
                          data-value="#2196F3"
                          style={{ backgroundColor: '#2196F3' }}
                        >
                          <i
                            className="fas fa-check"
                            style={{ display: 'none' }}
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          className="j-n-action"
                          title="#01BCD4"
                          data-css="color"
                          data-value="#01BCD4"
                          style={{ backgroundColor: '#01BCD4' }}
                        >
                          <i
                            className="fas fa-check"
                            style={{ display: 'none' }}
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          className="j-n-action"
                          title="#8BC34A"
                          data-css="color"
                          data-value="#8BC34A"
                          style={{ backgroundColor: '#8BC34A' }}
                        >
                          <i
                            className="fas fa-check"
                            style={{ display: 'none' }}
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          className="j-n-action"
                          title="#CDDC39"
                          data-css="color"
                          data-value="#CDDC39"
                          style={{ backgroundColor: '#CDDC39' }}
                        >
                          <i
                            className="fas fa-check"
                            style={{ display: 'none' }}
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          className="j-n-action"
                          title="#FFC107"
                          data-css="color"
                          data-value="#FFC107"
                          style={{ backgroundColor: '#FFC107' }}
                        >
                          <i
                            className="fas fa-check"
                            style={{ display: 'none' }}
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          className="j-n-action"
                          title="#FFEB3B"
                          data-css="color"
                          data-value="#FFEB3B"
                          style={{ backgroundColor: '#FFEB3B' }}
                        >
                          <i
                            className="fas fa-check"
                            style={{ display: 'none' }}
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          className="j-n-action"
                          title="#FFFFFF"
                          data-css="color"
                          data-value="#FFFFFF"
                          style={{
                            backgroundColor: '#ffffff',
                            border: '1px solid #aaaaaa',
                          }}
                        />
                      </li>
                      <li>
                        <a
                          className="j-n-action"
                          title="#AAAAAA"
                          data-css="color"
                          data-value="#AAAAAA"
                          style={{ backgroundColor: '#AAAAAA' }}
                        >
                          <i
                            className="fas fa-check"
                            style={{ display: 'none' }}
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          className="j-n-action"
                          title="#000000"
                          data-css="color"
                          data-value="#000000"
                          style={{ backgroundColor: '#000000' }}
                        >
                          <i
                            className="white fas fa-check"
                            style={{ display: 'none' }}
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          className="j-n-action"
                          title="#714343"
                          data-css="color"
                          data-value="#714343"
                          style={{ backgroundColor: '#714343' }}
                        >
                          <i
                            className="fas fa-check"
                            style={{ display: 'none' }}
                          />
                        </a>
                      </li>
                    </ul>
                    <div className="user-customize">
                      <p>自定義顏色</p>
                      <input
                        className="color-code"
                        type="text"
                        name="color"
                        placeholder="自定義顏色"
                      />
                    </div>
                  </div>
                </div>
                <ul
                  className="j-n-medias socialBox_list clearfix"
                  style={{ display: 'block' }}
                >
                  <li>
                    <div className="left_title">
                      <label className="facebook">
                        <i className="fab fa-facebook-square" />
                        <span>Facebook</span>
                      </label>
                    </div>
                    <div className="link_input">
                      <input
                        type="text"
                        name="facebook"
                        id="facebook"
                        placeholder="請輸入連結網址"
                        value={form.facebook}
                        onChange={() => this.handleChange('facebook')}
                        data-parsley-trigger="change"
                        data-parsley-pattern="^(?:http:|https:|)\/\/[^\.]+\..*[^\.]+$"
                        data-parsley-pattern-message="格式錯誤"
                      />
                    </div>
                  </li>
                  <li>
                    <div className="left_title">
                      <label className="instagram">
                        <i className="fab fa-instagram" />
                        <span>Instagram</span>
                      </label>
                    </div>
                    <div className="link_input">
                      <input
                        type="text"
                        name="instagram"
                        id="instagram"
                        placeholder="請輸入連結網址"
                        value={form.instagram}
                        onChange={() => this.handleChange('instagram')}
                        data-parsley-trigger="change"
                        data-parsley-pattern="^(?:http:|https:|)\/\/[^\.]+\..*[^\.]+$"
                        data-parsley-pattern-message="格式錯誤"
                      />
                    </div>
                  </li>
                  <li>
                    <div className="left_title">
                      <label className="line">
                        <i className="fab fa-line" />
                        <span>LINE</span>
                      </label>
                    </div>
                    <div className="link_input">
                      <input
                        type="url"
                        name="line"
                        id="line"
                        placeholder="請輸入連結網址"
                        value={form.line}
                        onChange={() => this.handleChange('line')}
                        data-parsley-trigger="change"
                        data-parsley-pattern="^(?:http:|https:|)\/\/[^\.]+\..*[^\.]+$"
                        data-parsley-pattern-message="格式錯誤"
                      />
                    </div>
                  </li>
                  <li>
                    <div className="left_title">
                      <label className="linkedin">
                        <i className="fab fa-linkedin" />
                        <span>LinkedIn</span>
                      </label>
                    </div>
                    <div className="link_input">
                      <input
                        type="text"
                        name="linkedin"
                        id="linkedin"
                        placeholder="請輸入連結網址"
                        value={form.linkedin}
                        onChange={() => this.handleChange('linkedin')}
                        data-parsley-trigger="change"
                        data-parsley-pattern="^(?:http:|https:|)\/\/[^\.]+\..*[^\.]+$"
                        data-parsley-pattern-message="格式錯誤"
                      />
                    </div>
                  </li>
                  <li>
                    <div className="left_title">
                      <label className="pinterest">
                        <i className="fab fa-pinterest" />
                        <span>Pinterest</span>
                      </label>
                    </div>
                    <div className="link_input">
                      <input
                        type="text"
                        name="pinterest"
                        id="pinterest"
                        placeholder="請輸入連結網址"
                        value={form.pinterest}
                        onChange={() => this.handleChange('pinterest')}
                        data-parsley-trigger="change"
                        data-parsley-pattern="^(?:http:|https:|)\/\/[^\.]+\..*[^\.]+$"
                        data-parsley-pattern-message="格式錯誤"
                      />
                    </div>
                  </li>
                  <li>
                    <div className="left_title">
                      <label className="telegram">
                        <i className="fab fa-telegram-plane" />
                        <span>Telegram</span>
                      </label>
                    </div>
                    <div className="link_input">
                      <input
                        type="text"
                        name="telegram"
                        id="telegram"
                        placeholder="請輸入連結網址"
                        value={form.telegram}
                        onChange={() => this.handleChange('telegram')}
                        data-parsley-trigger="change"
                        data-parsley-pattern="^(?:http:|https:|)\/\/[^\.]+\..*[^\.]+$"
                        data-parsley-pattern-message="格式錯誤"
                      />
                    </div>
                  </li>
                  <li>
                    <div className="left_title">
                      <label className="tumblr">
                        <i className="fab fa-tumblr-square" />
                        <span>Tumblr</span>
                      </label>
                    </div>
                    <div className="link_input">
                      <input
                        type="text"
                        name="tumblr"
                        id="tumblr"
                        placeholder="請輸入連結網址"
                        value={form.tumblr}
                        onChange={() => this.handleChange('tumblr')}
                        data-parsley-trigger="change"
                        data-parsley-pattern="^(?:http:|https:|)\/\/[^\.]+\..*[^\.]+$"
                        data-parsley-pattern-message="格式錯誤"
                      />
                    </div>
                  </li>
                  <li>
                    <div className="left_title">
                      <label className="twitter">
                        <i className="fab fa-twitter" />
                        <span>Twitter</span>
                      </label>
                    </div>
                    <div className="link_input">
                      <input
                        type="text"
                        name="twitter"
                        id="twitter"
                        placeholder="請輸入連結網址"
                        value={form.twitter}
                        onChange={() => this.handleChange('twitter')}
                        data-parsley-trigger="change"
                        data-parsley-pattern="^(?:http:|https:|)\/\/[^\.]+\..*[^\.]+$"
                        data-parsley-pattern-message="格式錯誤"
                      />
                    </div>
                  </li>
                  <li>
                    <div className="left_title">
                      <label className="weibo">
                        <i className="fab fa-weibo" />
                        <span>Weibo</span>
                      </label>
                    </div>
                    <div className="link_input">
                      <input
                        type="text"
                        name="weibo"
                        id="weibo"
                        placeholder="請輸入連結網址"
                        value={form.weibo}
                        onChange={() => this.handleChange('weibo')}
                        data-parsley-trigger="change"
                        data-parsley-pattern="^(?:http:|https:|)\/\/[^\.]+\..*[^\.]+$"
                        data-parsley-pattern-message="格式錯誤"
                      />
                    </div>
                  </li>
                  <li>
                    <div className="left_title">
                      <label className="weixin">
                        <i className="fab fa-weixin" />
                        <span>WeChat</span>
                      </label>
                    </div>
                    <div className="link_input">
                      <input
                        type="text"
                        name="weixin"
                        id="weixin"
                        placeholder="請輸入連結網址"
                        value={form.weixin}
                        onChange={() => this.handleChange('weixin')}
                        data-parsley-trigger="change"
                        data-parsley-pattern="^(?:http:|https:|)\/\/[^\.]+\..*[^\.]+$"
                        data-parsley-pattern-message="格式錯誤"
                      />
                    </div>
                  </li>
                  <li>
                    <div className="left_title">
                      <label className="whatsapp">
                        <i className="fab fa-whatsapp" />
                        <span>WhatsApp</span>
                      </label>
                    </div>
                    <div className="link_input">
                      <input
                        type="text"
                        name="whatsapp"
                        id="whatsapp"
                        placeholder="請輸入連結網址"
                        value={form.whatsapp}
                        onChange={() => this.handleChange('whatsapp')}
                        data-parsley-trigger="change"
                        data-parsley-pattern="^(?:http:|https:|)\/\/[^\.]+\..*[^\.]+$"
                        data-parsley-pattern-message="格式錯誤"
                      />
                    </div>
                  </li>
                  <li>
                    <div className="left_title">
                      <label className="youtube">
                        <i className="fab fa-youtube" />
                        <span>YouTube</span>
                      </label>
                    </div>
                    <div className="link_input">
                      <input
                        type="text"
                        name="youtube"
                        id="youtube"
                        placeholder="請輸入連結網址"
                        value={form.youtube}
                        onChange={() => this.handleChange('youtube')}
                        data-parsley-trigger="change"
                        data-parsley-pattern="^(?:http:|https:|)\/\/[^\.]+\..*[^\.]+$"
                        data-parsley-pattern-message="格式錯誤"
                      />
                    </div>
                  </li>
                </ul>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ backgroundColor: '#27d0ca', color: '#fff' }}
            content="套用"
            onClick={() => {
              handleSocial(form);
              this.closeEdit();
            }}
          />
        </Modal.Footer>
      </Modal>
    </>);
  }
}

export default ModalEditSocial;
