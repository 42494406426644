import React from 'react';

// reactstrap components
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
  // Carousel,
  // CarouselItem,
  // CarouselIndicators
} from 'reactstrap';

// core components

function SectionTestimonials1(props = {}) {
  // carousel - TESTIMONIALS 2
  const { form } = props;
  //  console.log(form)
  const {
    text2 = 'text2',
    text3 = 'text3',
    text4 = 'text4',
    text5 = 'text5',
    text6 = 'text6',
    text7 = 'text7',
    text8 = 'text8',
    text9 = 'text9',
    text10 = 'text10',
    text11 = 'text11',
    text12 = 'text12',
  } = form;
  return (
    <>
      <div
        className="section section-testimonials cd-section"
        id="testimonials"
      >
        {/* ********* TESTIMONIALS 1 ********* */}
        <div
          className="testimonials-1 section-image"
          style={{
            backgroundImage: `url(${require('assets/img/sections/uriel-soberanes.jpg')})`,
          }}
        >
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                <h2 className="title">{text2}</h2>
                <h5 className="description">{text3}</h5>
              </Col>
            </Row>
            <div className="space-top" />
            <Row>
              <Col md="4">
                <Card className="card-testimonial">
                  <div className="card-icon">
                    <i className="fa fa-quote-right" />
                  </div>
                  <CardBody>
                    <p className="card-description">{text4}</p>
                    <CardFooter>
                      <CardTitle tag="h4">{text5}</CardTitle>
                      <h6 className="card-category">{text6}</h6>
                      <div className="card-avatar">
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require('assets/img/faces/ayo-ogunseinde-2.jpg')}
                          />
                        </a>
                      </div>
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-testimonial">
                  <div className="card-icon">
                    <i className="fa fa-quote-right" />
                  </div>
                  <CardBody>
                    <p className="card-description">{text7}</p>
                    <CardFooter>
                      <CardTitle tag="h4">{text8}</CardTitle>
                      <h6 className="card-category">{text9}</h6>
                      <div className="card-avatar">
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require('assets/img/faces/joe-gardner-2.jpg')}
                          />
                        </a>
                      </div>
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-testimonial">
                  <div className="card-icon">
                    <i className="fa fa-quote-right" />
                  </div>
                  <CardBody>
                    <p className="card-description">{text10}</p>
                    <CardFooter>
                      <CardTitle tag="h4">{text11}</CardTitle>
                      <h6 className="card-category">{text12}</h6>
                      <div className="card-avatar">
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require('assets/img/faces/kaci-baum-2.jpg')}
                          />
                        </a>
                      </div>
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        {/* ********* END TESTIMONIALS 1 ********* */}
      </div>
    </>
  );
}

export default SectionTestimonials1;
