import React, { Component } from 'react';
// import { connect } from 'react-redux'
// import { createProject } from '../../store/actions/projectActions'
// import { Redirect } from 'react-router-dom'
import { Container, Header, Form, Icon, Grid, Image } from 'semantic-ui-react';
import ColorNavbar from 'components/Navbars/ColorNavbar.js';
import FooterBlack from 'components/Footers/FooterBlack.js';
import PageHeader from 'components/Headers/PageHeader';
import { Col, Row, CardImg } from 'reactstrap';
import Parse from 'widget/parse';

export class StartUp extends Component {
  constructor(props) {
    super(props);
    //    console.log(props);
    this.state = {};
  }

  handleChange = (e, { value }) => this.setState({ value });

  //   handleChange = (e) => {
  //     // console.log(e);
  //     this.setState({
  //       [e.target.id]: e.target.value
  //     })
  //   }

  //   handleSubmit = (e) => {
  //     // console.log(e);
  //     e.preventDefault();
  //     this.props.createProject(this.state);
  //     this.props.history.push('/');
  //   }
  render() {
    //     const { auth } = this.props;
    //     if (!auth.uid) return <Redirect to='/signin' />
    // const { value } = this.state

    return (
      <>
        {/* <Container style={content}> */}
        <div className="ContentsBox clearfix">
          <Header textAlign="center" size="large">
            請選擇喜歡的版型
          </Header>
          <br />
          <div className="j-n-theme-list">
            <ul className="theme-list clearfix">
              <li>
                <div className="theme-box">
                  <div className="theme-cover">
                    <img
                      className="j-elm-thumbnail"
                      src="//img.holkee.com/site/theme/cover/cover_23.jpg"
                      alt=""
                    />
                  </div>
                  <div className="tools">
                    <div className="both-box clearfix">
                      <div className="qrcode">
                        <img
                          className="j-elm-qrcode"
                          src="https://www.holkee.com/qrcode?choe=UTF-8&q=https%3A%2F%2Fwww.holkee.com%2Fex-jack-studio-zh-tw"
                          alt=""
                        />
                        <p>
                          "手機掃描 QRcode"
                          <br />
                          "預覽網頁樣式"
                        </p>
                      </div>
                      <p className="theme-btn">
                        <a
                          className="j-n-preview"
                          href="http://localhost:3000/page/ClUOpjaEaZ"
                        >
                          預覽
                        </a>
                        <a className="j-n-apply" href="https://www.google.com/">
                          套用
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="theme-info clearfix">
                  <p className="j-elm-description theme-name">Jack studio</p>
                </div>
              </li>
              <li>
                <div className="theme-box">
                  <div className="theme-cover">
                    <img
                      className="j-elm-thumbnail"
                      src="//img.holkee.com/site/theme/cover/cover_21.jpg"
                      alt=""
                    />
                  </div>
                  <div className="tools">
                    <div className="both-box clearfix">
                      <div className="qrcode">
                        <img
                          className="j-elm-qrcode"
                          src="https://www.holkee.com/qrcode?choe=UTF-8&q=https%3A%2F%2Fwww.holkee.com%2Fex-jack-studio-zh-tw"
                          alt=""
                        />
                        <p>
                          "手機掃描 QRcode"
                          <br />
                          "預覽網頁樣式"
                        </p>
                      </div>
                      <p className="theme-btn">
                        <a
                          className="j-n-preview"
                          href="http://localhost:3000/page/rQflxwCqSl"
                        >
                          預覽
                        </a>
                        <a className="j-n-apply" href="https://www.google.com/">
                          套用
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="theme-info clearfix">
                  <p className="j-elm-description theme-name">網路紅人</p>
                </div>
              </li>
              <li>
                <div className="theme-box">
                  <div className="theme-cover">
                    <img
                      className="j-elm-thumbnail"
                      src="//img.holkee.com/site/theme/cover/cover_20.jpg"
                      alt=""
                    />
                  </div>
                  <div className="tools">
                    <div className="both-box clearfix">
                      <div className="qrcode">
                        <img
                          className="j-elm-qrcode"
                          src="https://www.holkee.com/qrcode?choe=UTF-8&q=https%3A%2F%2Fwww.holkee.com%2Fex-jack-studio-zh-tw"
                          alt=""
                        />
                        <p>
                          "手機掃描 QRcode"
                          <br />
                          "預覽網頁樣式"
                        </p>
                      </div>
                      <p className="theme-btn">
                        <a
                          className="j-n-preview"
                          href="http://localhost:3000/page/hOLHi753Ko"
                        >
                          預覽
                        </a>
                        <a className="j-n-apply" href="https://www.google.com/">
                          套用
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="theme-info clearfix">
                  <p className="j-elm-description theme-name">時間工匠</p>
                </div>
              </li>
              <li>
                <div className="theme-box">
                  <div className="theme-cover">
                    <img
                      className="j-elm-thumbnail"
                      src="//img.holkee.com/site/theme/cover/cover_17.jpg"
                      alt=""
                    />
                  </div>
                  <div className="tools">
                    <div className="both-box clearfix">
                      <div className="qrcode">
                        <img
                          className="j-elm-qrcode"
                          src="https://www.holkee.com/qrcode?choe=UTF-8&q=https%3A%2F%2Fwww.holkee.com%2Fex-jack-studio-zh-tw"
                          alt=""
                        />
                        <p>
                          "手機掃描 QRcode"
                          <br />
                          "預覽網頁樣式"
                        </p>
                      </div>
                      <p className="theme-btn">
                        <a
                          className="j-n-preview"
                          href="http://localhost:3000/page/QhFRxiIyHv"
                        >
                          預覽
                        </a>
                        <a className="j-n-apply" href="https://www.google.com/">
                          套用
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="theme-info clearfix">
                  <p className="j-elm-description theme-name">紳士西裝訂製</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        {/* <Row>
          <Col md='4'>
            <CardImg top width="100%" src="https://react.semantic-ui.com/images/wireframe/image.png" alt="Card image cap" />
          </Col>
          <Col md='4'>
            <Image src='https://react.semantic-ui.com/images/wireframe/image.png' />
          </Col>
          <Col md='4'>
            <Image src='https://react.semantic-ui.com/images/wireframe/image.png' />
          </Col>
        </Row> */}
        {/* </Container> */}
      </>
    );
  }
}

// const mapStateToProps = (state) => {
//   return {
//     auth: state.firebase.auth
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     createProject: (project) => dispatch(createProject(project))
//   }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(HostIndex)
export default StartUp;
