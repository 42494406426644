import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
//text2 = '小標題',
function SectionFeature4(props = {}) {
  const { cookies, form, webimageArr = [], imagePreviewUrl } = props;
  // console.log("feature4 webimgageArr:", webimageArr)
  // console.log("form:", form)
  const { img1File } = form;
  const dbLang = cookies.get('dbLang') || 'name';
  let arr = []
  arr = webimageArr.filter(item => item.navId === form.objectId)
  if (!arr.length) {
    arr = [{ icon: "nc-app", title1name: "標題", content1name: '內文' },
    { icon: "nc-app", title1name: "標題", content1name: '內文' },
    { icon: "nc-app", title1name: "標題", content1name: '內文' },
    { icon: "nc-app", title1name: "標題", content1name: '內文' }]
  }
  const picUrl = (img1File && img1File.url) || imagePreviewUrl;
  // console.log("dbLang:", dbLang)
  // console.log("feature4 arr:", arr)//==>webimage內文
  return (
    <>
      <div className="section section-feature cd-section" style={{ margin: '50px 0px' }} id="features">
        {/* ********* FEATURES 4 ********* */}
        <div className="features-4">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                {/* <h2 className="title">{text2}</h2> */}
                {/* <h2 style={{ textAlign: 'center', fontWeight: 600, fontFamily: 'serif' }}>{form['text4' + dbLang]}</h2> */}
                <h2 dangerouslySetInnerHTML={{ __html: `<p style="text-align: center; font-family: sans-serif; font-size: 45px;">${form['text4' + dbLang]}</p>` }}></h2>
              </Col>
            </Row>
            <Row>
              <Col lg="5" md="12" xs='12' style={{ display: 'flex', alignItems: 'center' }}>
                <div>
                  {/* <img
                    alt="..."
                    src={picUrl || require("assets/img/sections/ipad.png")}
                    style={{ marginTop: '20%', width: '100%' }}
                  /> */}
                  {/* <h5 style={{ textAlign: 'center' }}>{form['text2' + dbLang] || ''}</h5>
                  <h4 style={{ textAlign: 'center' }}>{form['text3' + dbLang] || ''}</h4> */}
                  <h5 dangerouslySetInnerHTML={{ __html: `<p style="text-align: center; font-size: 30px;">${form['text2' + dbLang] || ''}</p>` }}></h5>
                  <h4 dangerouslySetInnerHTML={{ __html: `<p style="text-align: center; font-size: 30px;">${form['text3' + dbLang] || ''}</p>` }}></h4>
                </div>
              </Col>
              <Col lg="6" md="12" xs='12' style={{ marginLeft: '5%' }}>
                {arr.map((item) => <>
                  <div className="info-horizontal">
                    <div className="icon icon-info">
                      <i aria-hidden={true} className={`nc-icon ${item.icon}`} />
                    </div>
                    <div className="description">
                      {/* <h4 className="info-title" style={{ marginTop: '10px' }}>{item['title1' + dbLang] || ''}</h4> */}
                      <h4 dangerouslySetInnerHTML={{ __html: `<p style="margin-top: 10px; font-size: 30px;">${item['title1' + dbLang] || ''}</p>` }}></h4>
                      <br />
                      {/* <p style={{ fontSize: '13px' }}> {item['content1' + dbLang] || ''}</p> */}
                      <p dangerouslySetInnerHTML={{ __html: `<p style="font-size: 20px;">${item['content1' + dbLang] || ''}</p>` }}></p>
                    </div>
                  </div>
                </>)}
              </Col>
            </Row>
          </Container>
        </div>
        {/* ********* END FEATURES 4 ********* */}
      </div>
    </>
  );
}

export default SectionFeature4;
