import React from "react";

import {
  Container,
  Row,
  Col
} from "reactstrap";
import { withCookies } from 'react-cookie';

function SectionFeature5(props = {}) {
  const { cookies, form, webimageArr } = props;
  const { text2 = '' } = form;
  const dbLang = cookies.get('dbLang') || 'name';

  let arr = []
  arr = webimageArr.filter(item => item.navId === form.objectId)
  if (!arr.length) {
    arr = [{ icon: "nc-app", title1name: "標題", content1name: '內文' },
    { icon: "nc-app", title1name: "標題", content1name: '內文' },
    { icon: "nc-app", title1name: "標題", content1name: '內文' },
    { icon: "nc-app", title1name: "標題", content1name: '內文' }]
  }
  const arr2 = [];

  for (let i = 0; i < arr.length; i += 2) {
    const obj = {
      '1': arr[i],
      '2': i + 1 < arr.length ? arr[i + 1] : {},
    }
    arr2.push(obj);
  }

  return (
    <>
      <div className="section section-feature cd-section" id="features">
        {/* ********* FEATURES 5 ********* */}
        <div
          className="features-5 section-image"
          style={{
            backgroundImage:
              "url(" +
              require("assets/img/sections/the-how-photographer.jpg") +
              ")"
          }}
        >
          <Container>
            <Row>
              <div className="ml-auto mr-auto">
                <h2 className="title text-center">
                  {text2}
                </h2>
              </div>
            </Row>
            {arr2.map((item, index) => (<Row key={"a" + index} className={index === arr2.length - 1 ? "bottom-line" : ''}>
              <Col className="ml-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className={`nc-icon ${item['1'].icon}`} />
                  </div>
                  <h4 className="title">{item['1']['title1' + dbLang] || ''}</h4>
                  <p>
                    {item['1']['content1' + dbLang] || ''}
                  </p>
                </div>
              </Col>
              <Col className="mr-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className={`nc-icon ${item['2'].icon}`} />
                  </div>
                  <h4 className="title">{item['2']['title1' + dbLang] || ''}</h4>
                  <p>
                    {item['2']['content1' + dbLang] || ''}
                  </p>
                </div>
              </Col>
            </Row>))}
          </Container>
        </div>
        {/* ********* END FEATURES 5 ********* */}
      </div>
    </>
  );
}

export default withCookies(SectionFeature5);
