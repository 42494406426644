import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col
} from "reactstrap";

// core components

function SectionBlog1(props = {}) {
  const { form, cookies, webimageArr } = props;
  const { text2 = 'text2', text3 = 'text3' } = form;
  const dbLang = cookies.get('dbLang') || 'name';
  let arr = []
  arr = webimageArr.filter(item => item.navId === form.objectId)
  if (!arr.length) {
    arr = [{ icon: "nc-app", title1name: "標題", content1name: '內文' },
    { icon: "nc-app", title1name: "標題", content1name: '內文' },
    { icon: "nc-app", title1name: "標題", content1name: '內文' },
    { icon: "nc-app", title1name: "標題", content1name: '內文' }]
  }
  return (
    <>
      <div className="section secion-blog cd-section" id="blogs">
        {/* ********* BLOGS 1 ********* */}
        <div className="blog-1" id="blog-1">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="10">
                <h2 className="title">{text2}</h2>
                <br />
                {arr.map((item, index) => <>
                  {index % 2 !== 0 ?
                    <Card className="card-plain card-blog">
                      <Row>
                        <Col md="5">
                          <div className="card-image">
                            <img
                              alt="..."
                              className="img"
                              src={require("assets/img/sections/stephanie-krist.jpg")}
                            />
                          </div>
                        </Col>
                        <Col md="7">
                          <CardBody>
                            {/* <h6 className="card-category text-info">{text3}</h6> */}
                            <CardTitle tag="h3">
                              <a href="#pablo" onClick={e => e.preventDefault()}>
                                {item['title1' + dbLang] || ''}
                              </a>
                            </CardTitle>
                            <p className="card-description">
                              {item['content1' + dbLang] || ''}
                            </p>
                            <br />
                            <a href="#pablo" onClick={e => e.preventDefault()}>
                              Read More
                            </a>
                            {/* <p className="author">
                          by{" "}
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <b>Tim Merel</b>
                          </a>
                          , 11 hours ago
                        </p> */}
                          </CardBody>
                        </Col>
                      </Row>
                    </Card> :
                    <Card className="card-plain card-blog">
                      <Row>
                        <Col md="7">
                          <CardBody>
                            {/* <h6 className="card-category text-danger">
                              <i className="fa fa-free-code-camp mr-1" />
                              {text6}
                            </h6> */}
                            <CardTitle tag="h3">
                              <a href="#pablo" onClick={e => e.preventDefault()}>
                                {item['title1' + dbLang] || ''}
                              </a>
                            </CardTitle>
                            <p className="card-description">
                              {item['content1' + dbLang] || ''}
                            </p>
                            <br />
                            <a href="#pablo" onClick={e => e.preventDefault()}>
                              Read More
                            </a>
                            {/* <p className="author">
                          by{" "}
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <b>John Biggs</b>
                          </a>
                          , 3 seconds ago
                        </p> */}
                          </CardBody>
                        </Col>
                        <Col md="5">
                          <div className="card-image">
                            <img
                              alt="..."
                              className="img"
                              src={require("assets/img/sections/federico-beccari.jpg")}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Card>}
                </>)}
              </Col>
            </Row>
          </Container>
        </div>
        {/* ********* END BLOGS 1 ********* */}
      </div>
    </>
  );
}

export default SectionBlog1;