import React, { Component } from 'react';
import { Header, Form, Icon, Grid, Image, Modal } from 'semantic-ui-react';

import { withCookies } from 'react-cookie';
import {
  dateCol,
  imgBtnCol,
  imgPreviewCol,
} from 'widget/material-table';
import { RowImage } from 'widget/material-table/RowImage';

import ImageMapper from 'react-image-mapper';

import intl from 'react-intl-universal';

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col,
} from 'reactstrap';

const bannerImg = {
  height: 390,
  backgroundImage: `url(${'/hp1/16.jpg'})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: '50% 53%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};
const bannerText = {
  fontSize: '2.8rem',
  fontWeight: '600',
  letterSpacing: '2px',
  color: 'rgba(255, 255, 255, .9)',
  width: '1140px',
  textAlign: 'right',
};

const style = {
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const path = '/images/aichi_floorMaps/';

const MAP = {
  name: 'my-map',
  areas: [
    // { name: "1", shape: "poly", coords: [25,33,27,300,128,240,128,94], preFillColor: "green", fillColor: "blue"  },
    // { name: "2", shape: "poly", coords: [219,118,220,210,283,210,284,119], preFillColor: "pink"  },
    // { name: "3", shape: "poly", coords: [381,241,383,94,462,53,457,282], fillColor: "yellow"  },
    // { name: "4", shape: "poly", coords: [245,285,290,285,274,239,249,238], preFillColor: "red"  },
    // { name: "5", shape: "circle", coords: [170, 100, 25] },
    // { name: "room--RF", shape: "rect", coords: [147, 1336, 253, 1384] },
    { name: 'building', shape: 'rect', coords: [355, 450, 110, 500] },
    { name: 'obuilding', shape: 'rect', coords: [750, 18, 505, 67] },
    { name: 'room room__B1--A', shape: 'rect', coords: [190, 980, 100, 1030] },
    { name: 'room room__B1--B', shape: 'rect', coords: [280, 980, 190, 1030] },
    { name: 'room room__B1--C', shape: 'rect', coords: [350, 1040, 280, 960] },
    { name: 'room room__1F--A', shape: 'rect', coords: [300, 860, 155, 910] },
    { name: 'room room__2F--A', shape: 'rect', coords: [355, 730, 265, 770] },
    { name: 'room room__2F--D', shape: 'rect', coords: [295, 770, 150, 810] },
    { name: 'room room__3F--B', shape: 'rect', coords: [355, 620, 265, 660] },
    { name: 'room room__3F--D', shape: 'rect', coords: [310, 660, 220, 700] },
    { name: 'room room__3F--E', shape: 'rect', coords: [210, 630, 120, 680] },
    { name: 'room room--RF', shape: 'rect', coords: [290, 520, 170, 570] },
    { name: 'room room__O1F', shape: 'rect', coords: [705, 845, 510, 890] },
    { name: 'room room__O2F--A', shape: 'rect', coords: [736, 738, 658, 783] },
    { name: 'room room__O3F--A', shape: 'rect', coords: [736, 630, 658, 675] },
    { name: 'room room__O4F--A', shape: 'rect', coords: [736, 522, 658, 568] },
    { name: 'room room__O4F--B', shape: 'rect', coords: [620, 522, 505, 568] },
    { name: 'room room__O5F--A', shape: 'rect', coords: [736, 417, 658, 462] },
    { name: 'room room__O5F--B', shape: 'rect', coords: [620, 417, 505, 462] },
    { name: 'room room__O6F--A', shape: 'rect', coords: [736, 310, 658, 355] },
    // { name: "room room__O6F--B", shape: "rect", coords: [620, 300, 505, 360] },
    { name: 'room room__O7F--A', shape: 'rect', coords: [736, 205, 658, 250] },
    // { name: "room room__O7F--B", shape: "rect", coords: [620, 190, 505, 255] },
    { name: 'room room--ORF', shape: 'rect', coords: [678, 95, 562, 140] },
  ],
};

const photos = [
  { objectId: '0', name: '本館', imgfile: { url: `/https://parse3.infowin.com.tw/parse/files/parseServer/3b9edfa38575fd605fbffb3a5d5687f0_1.jpeg` } },
  { objectId: '1', name: '別館', imgfile: { url: `/https://parse3.infowin.com.tw/parse/files/parseServer/cfa2e6beb0676fb64ab4b00d796ff400_1.jpeg` } },
  { objectId: '2', name: '休憩所', imgfile: { url: `/https://parse3.infowin.com.tw/parse/files/parseServer/fe39453c479315df27df3733d9131f1d_1.jpeg` } },
  { objectId: '3', name: '和室', imgfile: { url: `/https://parse3.infowin.com.tw/parse/files/parseServer/5fbdca55f5df647222af561cf916c82a_1.jpeg` } },
  { objectId: '4', name: '舞台', imgfile: { url: `/https://parse3.infowin.com.tw/parse/files/parseServer/562c0d235132a280a1dded7f66da5e09_1.jpeg` } },
  { objectId: '5', name: '教室', imgfile: { url: `/https://parse3.infowin.com.tw/parse/files/parseServer/ac7527fa90c4a7c42c5694f5f5a59d13_1.jpeg` } },
  { objectId: '6', name: 'セミナー室', imgfile: { url: `/https://parse3.infowin.com.tw/parse/files/parseServer/7a08f73af66bf4d7ae31d44a7f45ace0_1.jpeg` } },
  { objectId: '7', name: '教室', imgfile: { url: `/https://parse3.infowin.com.tw/parse/files/parseServer/fc04c466519932ee90ed93f4564e3588_1.jpeg` } },
  { objectId: '8', name: '教職員室', imgfile: { url: `/https://parse3.infowin.com.tw/parse/files/parseServer/e8f981a883c9768bb879c3d2549e5ef4_1.jpeg` } },
  { objectId: '9', name: '文化教室', imgfile: { url: `/https://parse3.infowin.com.tw/parse/files/parseServer/fc6e3692955f2a382adfbffec4d49bfc_1.jpeg` } },
  { objectId: '10', name: '音樂室', imgfile: { url: `/https://parse3.infowin.com.tw/parse/files/parseServer/d936111361c3523767130ce10b8326ee_1.jpeg` } },
  { objectId: '11', name: '図書室', imgfile: { url: `/https://parse3.infowin.com.tw/parse/files/parseServer/f2ccd55191630c7839bde57a94cd8de6_1.jpeg` } },
  { objectId: '12', name: '休憩所', imgfile: { url: `/https://parse3.infowin.com.tw/parse/files/parseServer/5d6ca0f366ae9c75a19528965dd9dcba_1.jpeg` } },
  { objectId: '13', name: '教室', imgfile: { url: `/https://parse3.infowin.com.tw/parse/files/parseServer/b51b19a9f2cf6e5eddf5517bdcae712b_1.jpeg` } },
  { objectId: '14', name: '教室', imgfile: { url: `/https://parse3.infowin.com.tw/parse/files/parseServer/a3853a6d9eb6a68972322b04b980c1f7_1.jpeg` } },
  { objectId: '15', name: '小教室', imgfile: { url: `/https://parse3.infowin.com.tw/parse/files/parseServer/c041252e9b970548d504453b87dbcd6e_1.jpeg` } },
  { objectId: '16', name: '教室', imgfile: { url: `/https://parse3.infowin.com.tw/parse/files/parseServer/b51b19a9f2cf6e5eddf5517bdcae712b_1.jpeg` } },
  { objectId: '17', name: '小教室', imgfile: { url: `/https://parse3.infowin.com.tw/parse/files/parseServer/d4097f26b1c10c640e724d8b474c9ed1_1.jpeg` } },
  { objectId: '18', name: '教室', imgfile: { url: `/https://parse3.infowin.com.tw/parse/files/parseServer/a3853a6d9eb6a68972322b04b980c1f7_1.jpeg` } },
  { objectId: '19', name: '教室', imgfile: { url: `/${path}O3F_A.jpg` } },
  { objectId: '20', name: '教室', imgfile: { url: `/${path}O2F_A.jpg` } },
  {
    objectId: '21',
    name: 'カフェスペース',
    imgfile: { url: `/https://parse3.infowin.com.tw/parse/files/parseServer/f8a4addeda0944fe927301b834381fca_1.jpeg` },
  },
];

const mapObj = {
  en: 'map.png', // 'en': "mapen.png"
  vi: 'map.png', // 'vi': "mapen.png"
  'zh-Hans': 'map.png', // 'zh-Hans': "mapcn.png"
  'zh-Hant': 'map.png', // 'zh-Hant': "maptw.png"
  ja: 'map.png', // 'ja': "map.png"
};

export class FloorMaps extends Component {
  constructor(props) {
    super(props);
    //    console.log(props);
    this.state = {
      open: false,
      imgSrc: '',
      title: '',
    };
  }

  handleChange = (e, { value }) => this.setState({ value });

  showModal = ({ name }) => {
    // let name = e.target.className
    const item = {};
    // console.log(name)
    switch (name) {
      case 'building':
        this.setState({ imgSrc: `https://parse3.infowin.com.tw/parse/files/parseServer/3b9edfa38575fd605fbffb3a5d5687f0_1.jpeg`, title: '本館', open: true });
        // item = {imgSrc: `https://parse3.infowin.com.tw/parse/files/parseServer/fe39453c479315df27df3733d9131f1d_1.jpeg`, title: '休憩所(喫煙所)'};
        break;
      case 'obuilding':
        this.setState({ imgSrc: `https://parse3.infowin.com.tw/parse/files/parseServer/cfa2e6beb0676fb64ab4b00d796ff400_1.jpeg`, title: '別館', open: true });
        // item = {imgSrc: `https://parse3.infowin.com.tw/parse/files/parseServer/fe39453c479315df27df3733d9131f1d_1.jpeg`, title: '休憩所(喫煙所)'};
        break;
      case 'room room--RF':
        this.setState({ imgSrc: `https://parse3.infowin.com.tw/parse/files/parseServer/fe39453c479315df27df3733d9131f1d_1.jpeg`, title: '休憩所', open: true });
        // item = {imgSrc: `https://parse3.infowin.com.tw/parse/files/parseServer/fe39453c479315df27df3733d9131f1d_1.jpeg`, title: '休憩所(喫煙所)'};
        break;
      case 'room room__3F--E':
        this.setState({
          imgSrc: `https://parse3.infowin.com.tw/parse/files/parseServer/5fbdca55f5df647222af561cf916c82a_1.jpeg`,
          title: '和室',
          open: true,
        });
        break;
      case 'room room__3F--D':
        this.setState({ imgSrc: `https://parse3.infowin.com.tw/parse/files/parseServer/562c0d235132a280a1dded7f66da5e09_1.jpeg`, title: '舞台', open: true });
        break;
      // case 'room room__3F--C':
      //   this.setState({ imgSrc: `${path}3F_C.jpg`, title: '教室', open: true })
      //   break;
      // case 'room room__3F--B':
      //   this.setState({ imgSrc: `${path}3F_B.jpg`, title: '教室', open: true })
      //   break;
      case 'room room__3F--B':
        this.setState({ imgSrc: `https://parse3.infowin.com.tw/parse/files/parseServer/ac7527fa90c4a7c42c5694f5f5a59d13_1.jpeg`, title: '教室', open: true });
        break;
      case 'room room__2F--D':
        this.setState({
          imgSrc: `https://parse3.infowin.com.tw/parse/files/parseServer/7a08f73af66bf4d7ae31d44a7f45ace0_1.jpeg`,
          title: 'セミナー室',
          open: true,
        });
        break;
      // case 'room room__2F--C':
      //   this.setState({ imgSrc: `${path}2F_C.jpg`, title: '教室', open: true })
      //   break;
      // case 'room room__2F--B':
      //   this.setState({ imgSrc: `${path}2F_B.jpg`, title: '教室', open: true })
      //   break;
      case 'room room__2F--A':
        this.setState({ imgSrc: `https://parse3.infowin.com.tw/parse/files/parseServer/fc04c466519932ee90ed93f4564e3588_1.jpeg`, title: '教室', open: true });
        break;
      case 'room room__1F--A':
        this.setState({
          imgSrc: `https://parse3.infowin.com.tw/parse/files/parseServer/e8f981a883c9768bb879c3d2549e5ef4_1.jpeg`,
          title: '教職員室',
          open: true,
        });
        break;
      case 'room room__B1--C':
        this.setState({
          imgSrc: `https://parse3.infowin.com.tw/parse/files/parseServer/fc6e3692955f2a382adfbffec4d49bfc_1.jpeg`,
          title: '文化教室',
          open: true,
        });
        break;
      case 'room room__B1--B':
        this.setState({
          imgSrc: `https://parse3.infowin.com.tw/parse/files/parseServer/d936111361c3523767130ce10b8326ee_1.jpeg`,
          title: '音樂室',
          open: true,
        });
        break;
      case 'room room__B1--A':
        this.setState({
          imgSrc: `https://parse3.infowin.com.tw/parse/files/parseServer/f2ccd55191630c7839bde57a94cd8de6_1.jpeg`,
          title: '図書室',
          open: true,
        });
        break;
      case 'room room--ORF':
        this.setState({
          imgSrc: `https://parse3.infowin.com.tw/parse/files/parseServer/5d6ca0f366ae9c75a19528965dd9dcba_1.jpeg`,
          title: '休憩所',
          open: true,
        });
        break;
      // case 'room room__O7F--B':
      //   this.setState({ imgSrc: `${path}O7F_B.jpg`, title: '学院長室', open: true })
      //   break;
      case 'room room__O7F--A':
        this.setState({
          imgSrc: `https://parse3.infowin.com.tw/parse/files/parseServer/b51b19a9f2cf6e5eddf5517bdcae712b_1.jpeg`,
          title: '教室',
          open: true,
        });
        break;
      // case 'room room__O6F--B':
      //   this.setState({ imgSrc: `${path}O6F_B.jpg`, title: '保健室', open: true })
      //   break;
      case 'room room__O6F--A':
        this.setState({
          imgSrc: `https://parse3.infowin.com.tw/parse/files/parseServer/a3853a6d9eb6a68972322b04b980c1f7_1.jpeg`,
          title: '教室',
          open: true,
        });
        break;
      case 'room room__O5F--B':
        this.setState({
          imgSrc: `https://parse3.infowin.com.tw/parse/files/parseServer/c041252e9b970548d504453b87dbcd6e_1.jpeg`,
          title: '小教室',
          open: true,
        });
        break;
      case 'room room__O5F--A':
        this.setState({
          imgSrc: `https://parse3.infowin.com.tw/parse/files/parseServer/b51b19a9f2cf6e5eddf5517bdcae712b_1.jpeg`,
          title: '教室',
          open: true,
        });
        break;
      case 'room room__O4F--B':
        this.setState({
          imgSrc: `https://parse3.infowin.com.tw/parse/files/parseServer/d4097f26b1c10c640e724d8b474c9ed1_1.jpeg`,
          title: '小教室',
          open: true,
        });
        break;
      case 'room room__O4F--A':
        this.setState({
          imgSrc: `https://parse3.infowin.com.tw/parse/files/parseServer/a3853a6d9eb6a68972322b04b980c1f7_1.jpeg`,
          title: '教室',
          open: true,
        });
        break;
      case 'room room__O3F--A':
        this.setState({
          imgSrc: `${path}O3F_A.jpg`,
          title: '教室',
          open: true,
        });
        break;
      case 'room room__O2F--A':
        this.setState({
          imgSrc: `${path}O2F_A.jpg`,
          title: '教室',
          open: true,
        });
        break;
      case 'room room__O1F':
        this.setState({
          imgSrc: `https://parse3.infowin.com.tw/parse/files/parseServer/f8a4addeda0944fe927301b834381fca_1.jpeg`,
          title: 'カフェスペース',
          open: true,
        });
        break;
      default:
        break;
    }
    this.setState({ open: true });
  };

  closeModal = () => this.setState({ open: false });

  render() {
    const { cookies } = this.props;
    const { open, title, imgSrc } = this.state;

    const lang = cookies.get('docLang');
    // console.log(mapObj[lang])
    return (
      <>
        <div className="section section-floormaps cd-section" id="floormaps">
          {/* ********* FLOORMAPS ********* */}
          <div className="floormaps">
            <Container style={{ marginTop: '60px', marginBottom: '60px' }}>
              {/* <Grid style={{ overflowY: 'scroll' }}> */}
              <Col>
                <Row style={{ marginBottom: '30px' }}>
                  <Col>
                    <div
                      className="container clearfix"
                      style={{ letterSpacing: '1px' }}
                    >
                      <h3>
                        {intl.get(
                          '文字をクリックすると施設の写真が見られます。',
                        )}
                      </h3>
                      <br />
                      <br />
                    </div>
                  </Col>
                </Row>
                {/* <div className="container clearfix" justifyContent='center' style={{ letterSpacing: '1px' }}>
                <h2>文字をクリックすると施設の写真が見られます。</h2>
              </div> */}
                <ImageMapper
                  src={`/${mapObj[lang]}`}
                  map={MAP}
                  width={800}
                  justifyContent="center"
                  // onLoad={() => this.load()}
                  style={{ cursor: 'pointer', margin: 0 }}
                  onClick={area => this.showModal(area)}
                  fillColor="rgba(150, 255, 150, 0.5)"
                // strokeColor='rgba(0, 0, 0, 0.5)'
                // onMouseEnter={area => this.enterArea(area)}
                // onMouseLeave={area => this.leaveArea(area)}
                // onMouseMove={(area, _, evt) => this.moveOnArea(area, evt)}
                // onImageClick={() => imgPreviewCol(photos)}
                // onImageMouseMove={evt => this.moveOnImage(evt)}
                />
              </Col>
            </Container>
            <Modal
              size="fullscreen"
              basic
              open={open}
              onClose={this.closeModal}
            >
              <Modal.Header>
                <div>
                  <Header as="h2" style={{ margin: 0, color: '#fff' }}>
                    {intl.get('校舍')}
                  </Header>
                </div>
                <div style={{ float: 'right', marginBottom: '10px' }}>
                  <Icon
                    size="large"
                    floated="right"
                    style={{ cursor: 'pointer', margin: 0 }}
                    name="close"
                    onClick={this.closeModal}
                  />
                </div>
              </Modal.Header>
              <Modal.Content>
                <Image src={imgSrc} />
              </Modal.Content>
            </Modal>
          </div>
        </div>
      </>
    );
  }
}

export default withCookies(FloorMaps);
