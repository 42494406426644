import React from 'react';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  UncontrolledCollapse,
  FormGroup,
  Form,
  Input,
  NavbarBrand,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from 'reactstrap';
import { Image } from 'semantic-ui-react';
// import ColorNavbar from "components/Navbars/ColorNavbar.js";
// import '../../assets/css/paper-kit.min.scoped.css'

// core components

const SectionHeader5 = (props = {}) => {
  // carousel - header 3
  const { form } = props;
  return (
    <>
      {/* <div className="section section-header cd-section" id="headers"> */}
      {/* ********* HEADER 2 ********* */}
      <div className="header-2">
        <div
          className="page-header"
          style={{
            backgroundColor: '#a5dee4',
          }}
        >
          {/* <div className="filter" /> */}
          <div className="content-center">
            <Container>
              {/* <Row>
                <Col style={{ marginTop: '5%' }} className="ml-auto mr-auto text-center" md="12"> */}
              <Image
                style={{ marginTop: '15%' }}
                src="images/first page/1.png"
              />
              {/* </Col>
              </Row> */}
            </Container>
          </div>
        </div>
      </div>
      {/* ********* END HEADER 2 ********* */}
    </>
  );
};

export default SectionHeader5;
