import React, { Component } from 'react';
import {
  Container,
  Table,
} from 'semantic-ui-react';
import Carousel, { ModalGateway, Modal } from 'react-images';
import intl from 'react-intl-universal';

// import * as createHistory from 'history';
// import ColorNavbar from 'components/Navbars/ColorNavbar.js';
// import FooterBlack from 'components/Footers/FooterBlack.js';
// import PageHeader from 'components/Headers/PageHeader';
import Parse from 'widget/parse';
import { previewUrl } from 'widget/material-table/PreloadFile';

export class FileDownload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileCenter1Arr: [],
      fileCenter2Arr: [],
    };
  }

  componentDidMount = async () => {
    const { companyObj } = this.props;
    const companyId = companyObj.objectId;
    const data1 = await Parse.queryData('fileCenter2', { companyId });

    this.setState({ fileCenter2Arr: data1 });
  };

  render() {
    const { pageObj, form, imagePreviewUrl, isLoading } = this.props;
    const { text1, img1File, img1Url, dbLang, flieType } = form;
    const { fileCenter2Arr } = this.state;
    const fileCenter2Data = fileCenter2Arr.filter(
      item => item.fileCenter1Text === flieType,
    );
    return (<>
      {/* <PageHeader url={picUrl} text={intl.get('檔案下載')} /> */}
      <div className="blog-2 section section-gray">
        <br />
        <br />
        <Container>
          <Table celled textAlign="center" style={{ margin: '0 0 2rem' }}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{intl.get('書類名')}</Table.HeaderCell>
                <Table.HeaderCell>
                  {intl.get('ダウンロード')}
                </Table.HeaderCell>
                {/* {th.map((item, index) => <Table.HeaderCell key={`dataTh${index}`}>{item.text}</Table.HeaderCell>)} */}
              </Table.Row>
            </Table.Header>
            {fileCenter2Data.map((item, index) => (
              <Table.Body>
                <Table.Row key="dataTr">
                  <Table.Cell className="fileDownload">
                    {intl.get(item.name)}
                  </Table.Cell>
                  <Table.Cell className="fileDownload">
                    {previewUrl(item.file1, false)}
                  </Table.Cell>
                  {/* <Table.Cell>{item.name}</Table.Cell> */}
                </Table.Row>
              </Table.Body>
            ))}
          </Table>
        </Container>
        <br />
        <br />
      </div>
    </>);
  }
}

export default FileDownload;
