/*eslint-disable*/
import React, { Component } from 'react'
import { withCookies } from 'react-cookie';
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col
} from "reactstrap";
import Carousel, { ModalGateway, Modal } from 'react-images';
import Parse from 'widget/parse'

// core components

export class SectionProject3 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photos: [],
      album: [],
      isOpen: false
    };
  }

  componentDidMount = async () => {
    const { companyObj } = this.props
    const companyId = companyObj.objectId
    const data = await Parse.queryData('album', { companyId });
    this.setState({ album: data })
  }
  handlePhotos = async (rowData, albumId) => {
    const { companyObj } = this.props
    const companyId = companyObj.objectId
    const data2 = []
    const data = await Parse.queryData('webimage', { companyId, albumId });
    this.setState({ photos: data, isOpen: true, rowData })
  }
  handleClose = () => {
    this.setState({ isOpen: false })
  };
  render() {
    const { cookies } = this.props;
    const { album, photos, rowData, field = 'img1File2', isOpen } = this.state;
    const dbLang = cookies.get('dbLang') || 'name';

    const albumArr = album.filter(item => item.environmentalFacilities === '0')

    const src0 = (rowData && rowData[field.substr(0, field.length - 1)]);
    const source = src0 && src0.url;
    const caption = (rowData && rowData.name) || ''

    const items = photos && photos.length ? photos.map(item => {
      const src = (item && item[field.substr(0, field.length - 1)]);
      const caption = (item && item.name) || ''
      const source = src && src.url;
      return { source, caption };
    }) : [{ source, caption }]

    const currentIndex = photos.findIndex(item => item.objectId === rowData.objectId) !== -1 ?
      photos.findIndex(item => item.objectId === rowData.objectId) : 0;

    const options = { currentIndex }
    return (
      <>
        <div className="section section-project cd-section" id="projects">
          {/* ********* PROJECTS 3 ********* */}
          <div className="projects-3" id="projects-3">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <div className="space-top" />
                  <h2 className="title">環境設施</h2>
                </Col>
              </Row>
              <Row>
                {albumArr.map((item, index) =>
                  <Col md="4">
                    <Card className="card-profile card-plain">
                      <CardImg top tag="div">
                        {/* <a
                      href="/page/db67DA9crJ"
                      target="_blank"
                   > */}
                        <img
                          alt="..."
                          className="img"
                          src={item.img1File2.url}
                          onClick={() => this.handlePhotos(item, item.objectId)}
                        />
                        {/* </a> */}
                      </CardImg>
                      <CardBody>
                        {/* <h6 className="card-category">Productivity tools</h6> */}
                        <CardTitle tag="h4">
                          {item[dbLang]}
                        </CardTitle>
                        {/* <p className="card-description">
                        {item.name}
                      </p> */}
                      </CardBody>
                    </Card>
                  </Col>
                )}
              </Row>
            </Container>
            <ModalGateway>
              {isOpen ? (
                <Modal onClose={this.handleClose}>
                  <Carousel views={items} {...options} />
                </Modal>
              ) : null}
            </ModalGateway>
            {/* ********* END PROJECTS 3 ********* */}
          </div>
        </div>
      </>
    );
  }
}
export default withCookies(SectionProject3)
