import React, { Component } from 'react';
import { Container, Header, Grid, } from 'semantic-ui-react';
// import { Link } from 'react-router-dom';
import intl from 'react-intl-universal';
// import ColorNavbar from 'components/Navbars/ColorNavbar.js';
// import FooterBlack from 'components/Footers/FooterBlack.js';
// import PageHeader from 'components/Headers/PageHeader';

export default class PersonalPolicy extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {};
  // }

  state = {

  }

  componentDidMount() {
    window.scrollTo(0, 0); // 置頂代碼
  }

  handleChange = (e, { value }) => this.setState({ value });

  render() {
    const { t } = this.props;
    const bannerImg = {
      height: 180,
      // backgroundImage: `url(${'/hp2/3.jpg'})`,
      // backgroundRepeat: 'no-repeat',
      // backgroundSize: 'cover',
      // backgroundPosition: '50% 44%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    };
    const bannerText = {
      fontSize: '2.8rem',
      fontWeight: '600',
      letterSpacing: '2px',
      color: 'rgba(120, 120, 120, .9)',
      width: '1140px',
      textAlign: 'right',
    };
    const itemTitle = {
      fontWeight: 600,
      fontSize: 26,
      margin: '0 0 17px 0',
      lineHeight: '1.8',
    };
    const itemText = {
      fontSize: 20,
      margin: '10px 30px',
      lineHeight: '1.8',
    };
    const rightText = {
      fontSize: 20,
      margin: '10px 30px',
      lineHeight: '1.8',
      textAlign: 'right',
    };
    const content = {
      maxWidth: '1140px',
      margin: '100px auto',
      flexDirection: 'column',
    };
    const list = {
      listStyleType: 'none',
    };
    return (
      <Container fluid >
        <Grid>
          <Grid.Row style={bannerImg}>
            <Header as="h1" style={bannerText}>
              <div
                style={{
                }}
              >
                個人情報保護に関する方針
              </div>
            </Header>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column verticalAlign="middle">
              <Grid.Row>
                <p style={itemText}>
                  愛知国際学院（以下AIA）は、「日本語教育事業」「留学事業」を基幹事業として、教育関連サービスの提供に努めております。
                </p>
                <p style={itemText}>
                  これらの事業活動を推進する上で、お客様をはじめ、当グループに関係する方々の個人情報を適切に取り扱い、保護することが極めて重要であると深く認識しております。AIAは、以下のとおり個人情報保護方針を定め、当社役員、社員等に周知徹底することにより、これを実施し、かつ維持することを宣言いたします。
                </p>
                <p style={itemText}>
                  1.{' '}
                  すべての事業で取扱う個人情報及び社員等の個人情報に関し、個人情報の取扱いに関する法令、国が定める指針その他の規範を遵守いたします。
                </p>
                <p style={itemText}>
                  2.{' '}
                  個人情報の収集、利用、提供等の取扱いは、業務上必要な範囲において、適法・公正な手段によって収集し、目的の範囲内で利用、提供等を行います。なお、特定した利用目的の範囲を超えた取扱いの必要性が生じた場合は、改めてご本人様に利用目的などを通知し、同意を得るようにし、目的外利用はいたしません。
                </p>
                <p style={itemText}>
                  3.{' '}
                  AIA内で取扱う全ての個人情報について、不正なアクセス、紛失、破壊、改竄、漏洩等のリスクに対して合理的な安全対策および是正措置を講じます。
                </p>
                <p style={itemText}>
                  4.{' '}
                  AIAは、本人からの当該個人情報の開示、訂正、削除、利用停止等の要請及び苦情や相談に対して、誠実かつ速やかに対応いたします。
                </p>
                <p style={itemText}>
                  5.{' '}
                  個人情報保護のための体制を適切に維持するとともに、状況の変化や個人情報保護に関する社会的動向、お客様の要望等を踏まえ、継続的に改善します。
                </p>
                <br />
              </Grid.Row>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <p style={rightText}>
                平成国際教育開発有限
                <br />
                代表取締役　荘 大右
                <br />
                <br />
                愛知国際学院
                <br />
                学院長　荘 昌憲
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>);
  }
}
