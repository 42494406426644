import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col
} from "reactstrap";

// core components

function SectionBlog5(props = {}) {
  const { form } = props;
  // console.log(form)
  const { text2 = 'text2', text3 = 'text3', text4 = 'text4', text5 = 'text5', text6 = 'text6',
    text7 = 'text7', text8 = 'text8', text9 = 'text9', text10 = 'text10', text11 = 'text11', text12 = 'text12', text13 = 'text13', } = form;
  return (
    <>
      <div className="section secion-blog cd-section" id="blogs">
        {/* ********* BLOGS 5 ********* */}
        <div className="blog-5">
          <Container>
            <h2 className="title text-center"></h2>
            <Row>
              <Col md="7">
                <Card
                  data-background="image"
                  style={{
                    backgroundImage:
                      "url(" +
                      require("assets/img/sections/uriel-soberanes.jpg") +
                      ")"
                  }}
                >
                  <CardBody>
                    <h6 className="card-category">
                      <i className="fa fa-newspaper-o mr-1" />
                      {text2}
                    </h6>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <CardTitle tag="h3">
                        {text3}
                      </CardTitle>
                    </a>
                    <p className="card-description">
                      {text4}
                    </p>
                    {/* <CardFooter>
                      <div className="author">
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <img
                            alt="..."
                            className="avatar img-raised mr-2"
                            src={require("assets/img/faces/joe-gardner-2.jpg")}
                          />
                          <span>Lynne Barrett</span>
                        </a>
                      </div>
                    </CardFooter> */}
                  </CardBody>
                </Card>
              </Col>
              <Col md="5">
                <Card
                  data-background="image"
                  style={{
                    backgroundImage:
                      "url(" +
                      require("assets/img/sections/forest-bg.jpg") +
                      ")"
                  }}
                >
                  <CardBody>
                    <h6 className="card-category">
                      <i className="fa fa-newspaper-o mr-1" />
                      {text5}
                    </h6>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <CardTitle tag="h3">
                        {text6}
                      </CardTitle>
                    </a>
                    <p className="card-description">
                      {text7}
                    </p>
                    {/* <CardFooter>
                      <div className="author">
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <img
                            alt="..."
                            className="avatar img-raised mr-2"
                            src={require("assets/img/faces/erik-lucatero-2.jpg")}
                          />
                          <span>Eric J Barnes</span>
                        </a>
                      </div>
                    </CardFooter> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <Card
                  data-background="image"
                  style={{
                    backgroundImage:
                      "url(" +
                      require("assets/img/sections/pavel-kosov.jpg") +
                      ")"
                  }}
                >
                  <CardBody>
                    <h6 className="card-category">
                      <i className="fa fa-newspaper-o mr-1" />
                      {text8}
                    </h6>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <CardTitle tag="h3">{text9}</CardTitle>
                    </a>
                    <p className="card-description">
                      {text10}
                    </p>
                    {/* <CardFooter>
                      <div className="author">
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <img
                            alt="..."
                            className="avatar img-raised mr-2"
                            src={require("assets/img/faces/kaci-baum-2.jpg")}
                          />
                          <span>Patricia Beatty</span>
                        </a>
                      </div>
                    </CardFooter> */}
                  </CardBody>
                </Card>
              </Col>
              <Col md="6">
                <Card
                  data-background="image"
                  style={{
                    backgroundImage:
                      "url(" +
                      require("assets/img/sections/ilya-yakover.jpg") +
                      ")"
                  }}
                >
                  <CardBody>
                    <h6 className="card-category">
                      <i className="fa fa-newspaper-o mr-1" />
                      {text11}
                    </h6>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <CardTitle tag="h3">
                        {text12}
                      </CardTitle>
                    </a>
                    <p className="card-description">
                      {text13}
                    </p>
                    {/* <CardFooter>
                      <div className="author">
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <img
                            alt="..."
                            className="avatar img-raised mr-2"
                            src={require("assets/img/faces/clem-onojeghuo-3.jpg")}
                          />
                          <span>Robert Brandon</span>
                        </a>
                      </div>
                    </CardFooter> */}
                  </CardBody>
                </Card>
              </Col>
              <Col md="3">
                <Card
                  data-background="image"
                  style={{
                    backgroundImage:
                      "url(" +
                      require("assets/img/sections/vincent-versluis.jpg") +
                      ")"
                  }}
                >
                  <CardBody>
                    <h6 className="card-category">
                      <i className="fa fa-newspaper-o mr-1" />
                      Technology
                    </h6>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <CardTitle tag="h3">A Little Adrift</CardTitle>
                    </a>
                    <p className="card-description">
                      But from the perspective of founders, there’s bound to be
                      some confusion. In an ideal world we will see a meeting of
                      the minds
                    </p>
                    <CardFooter>
                      <div className="author">
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <img
                            alt="..."
                            className="avatar img-raised mr-2"
                            src={require("assets/img/faces/ayo-ogunseinde-2.jpg")}
                          />
                          <span>Ayo Michael</span>
                        </a>
                      </div>
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        {/* ********* END BLOGS 5 ********* */}
      </div>
    </>
  );
}

export default SectionBlog5;
