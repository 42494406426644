import React from 'react';
import { withCookies } from 'react-cookie';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  UncontrolledCollapse,
  FormGroup,
  Form,
  Input,
  NavbarBrand,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from 'reactstrap';
// import '../../assets/css/paper-kit.min.scoped.css'

// core components

// const items = [
//   {
//     src: "url(" + require("assets/img/sections/section-header-1.jpg") + ")",
//     content: (
//       <Container>
//         <Row>
//           <Col className="text-left" md="6">
//             <h1 className="title">{text2}</h1>
//             <h5>
//               Now you have no excuses, it's time to surprise your clients, your
//               competitors, and why not, the world. You probably won't have a
//               better chance to show off all your potential if it's not by
//               designing a website for your own agency or web studio.
//             </h5>
//             <br />
//             <div className="buttons">
//               <Button
//                 className="btn-round"
//                 color="danger"
//                 href="#pablo"
//                 onClick={e => e.preventDefault()}
//                 size="lg"
//              >
//                 Read More
//               </Button>
//               <Button
//                 className="btn-neutral btn-just-icon"
//                 color="link"
//                 href="#pablo"
//                 onClick={e => e.preventDefault()}
//              >
//                 <i className="fa fa-twitter" />
//               </Button>
//               <Button
//                 className="btn-neutral btn-just-icon"
//                 color="link"
//                 href="#pablo"
//                 onClick={e => e.preventDefault()}
//              >
//                 <i className="fa fa-facebook-square" />
//               </Button>
//               <Button
//                 className="btn-neutral btn-just-icon"
//                 color="link"
//                 href="#pablo"
//                 onClick={e => e.preventDefault()}
//              >
//                 <i className="fa fa-get-pocket" />
//               </Button>
//             </div>
//           </Col>
//         </Row>
//       </Container>
//     ),
//     altText: "",
//     caption: ""
//   },
//   {
//     src: "url(" + require("assets/img/sections/section-header-2.jpg") + ")",
//     content: (
//       <Container>
//         <Row>
//           <Col className="ml-auto mr-auto text-center" md="8">
//             <h1 className="title">Awesome Experiences</h1>
//             <h5>
//               Now you have no excuses, it's time to surprise your clients, your
//               competitors, and why not, the world. You probably won't have a
//               better chance to show off all your potential if it's not by
//               designing a website for your own agency or web studio.
//             </h5>
//             <br />
//             <h6>Connect with us:</h6>
//             <div className="buttons">
//               <Button
//                 className="btn-neutral btn-just-icon"
//                 color="link"
//                 href="#pablo"
//                 onClick={e => e.preventDefault()}
//              >
//                 <i className="fa fa-twitter" />
//               </Button>
//               <Button
//                 className="btn-neutral btn-just-icon"
//                 color="link"
//                 href="#pablo"
//                 onClick={e => e.preventDefault()}
//              >
//                 <i className="fa fa-facebook-square" />
//               </Button>
//               <Button
//                 className="btn-neutral btn-just-icon"
//                 color="link"
//                 href="#pablo"
//                 onClick={e => e.preventDefault()}
//              >
//                 <i className="fa fa-instagram" />
//               </Button>
//               <Button
//                 className="btn-neutral btn-just-icon"
//                 color="link"
//                 href="#pablo"
//                 onClick={e => e.preventDefault()}
//              >
//                 <i className="fa fa-google-plus" />
//               </Button>
//             </div>
//           </Col>
//         </Row>
//       </Container>
//     ),
//     altText: "",
//     caption: ""
//   },
//   {
//     src: "url(" + require("assets/img/sections/section-header-3.jpg") + ")",
//     content: (
//       <Container>
//         <Row>
//           <Col className="ml-auto text-right" md="7">
//             <h2 className="title">Premium Offers for Venice</h2>
//             <h5>
//               Now you have no excuses, it's time to surprise your clients, your
//               competitors, and why not, the world. You probably won't have a
//               better chance to show off all your potential if it's not by
//               designing a website for your own agency or web studio.
//             </h5>
//             <br />
//             <div className="buttons">
//               <Button
//                 className="btn-neutral"
//                 color="link"
//                 href="#pablo"
//                 onClick={e => e.preventDefault()}
//                 size="lg"
//              >
//                 <i className="fa fa-share-alt" />
//                 Share Offer
//               </Button>
//               <Button
//                 className="btn-round"
//                 color="success"
//                 href="#pablo"
//                 onClick={e => e.preventDefault()}
//                 size="lg"
//              >
//                 <i className="fa fa-shopping-cart" />
//                 Shop Now
//               </Button>
//             </div>
//           </Col>
//         </Row>
//       </Container>
//     ),
//     altText: "",
//     caption: ""
//   }
// ];

const alignClass = {
  '0': 'text-left',
  '1': 'ml-auto mr-auto text-center',
  '2': 'ml-auto text-right',
};

const alignMd = {
  '0': '6',
  '1': '8',
  '2': '7',
};

const renderHeader = (companyObj, obj, dbLang) => {
  return {
    src: `url(${(obj.file1 && obj.file1.url) ||
      require('assets/img/bg/rawpixel-comm.jpg')}`,
    content: (
      <Container>
        <Row>
          <Col
            className={alignClass[obj.align || '0']}
            md={alignMd[obj.align || '6']}
          >
            <h1 className="title">{obj[`title1${dbLang} `]}</h1>
            <h5>{obj[`content1${dbLang} `]}</h5>
            <br />
            <div className="buttons">
              {/* <Button
                className="btn-round"
                color="danger"
                href="#pablo"
                onClick={e => e.preventDefault()}
                size="lg"
             >
                Read More
                </Button> */}
              {companyObj.twUrl ? (
                <Button
                  className="btn-neutral btn-just-icon"
                  color="link"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  <i className="fa fa-twitter" />
                </Button>
              ) : null}
              {companyObj.fbUrl ? (
                <Button
                  className="btn-neutral btn-just-icon"
                  color="link"
                  href={companyObj.fbUrl}
                  onClick={e => e.preventDefault()}
                >
                  <i className="fa fa-facebook-square" />
                </Button>
              ) : null}
              <Button
                className="btn-neutral btn-just-icon"
                color="link"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <i className="fa fa-get-pocket" />
              </Button>
              {companyObj.igUrl ? (
                <Button
                  className="btn-neutral btn-just-icon"
                  color="link"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  <i className="fa fa-instagram" />
                </Button>
              ) : null}
              {companyObj.googleUrl ? (
                <Button
                  className="btn-neutral btn-just-icon"
                  color="link"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  <i className="fa fa-google-plus" />
                </Button>
              ) : null}
            </div>
          </Col>
        </Row>
      </Container>
    ),
    altText: '',
    caption: '',
  };
};

function SectionHeader3(props = {}) {
  // carousel - header 3

  const { form, companyObj, webimageArr, cookies } = props;
  //  console.log(form)
  // console.log(webimageArr)

  const dbLang = cookies.get('dbLang') || 'name';
  const data = webimageArr.filter(
    item => item.componentId === form.componentId,
  );
  console.log(data);
  const {
    file1 = {},
    file2 = {},
    file3 = {},
    title1 = 'title1',
    title2 = 'title2',
    title3 = 'title3',
    content1 = 'content1',
    content2 = 'content2',
    content3 = 'content3',
  } = form;
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  // const items = [
  //   {
  //     src: "url(" + file1.url || '' + ")",
  //     // src: "url(" + require("assets/img/sections/section-header-1.jpg") + ")",
  //     content: (
  //       <Container>
  //         <Row>
  //           <Col className="text-left" md="6">
  //             <h1 className="title">{title1}</h1>
  //             <h5>
  //               {content1}
  //             </h5>
  //             <br />
  //             <div className="buttons">
  //               {/* <Button
  //                 className="btn-round"
  //                 color="danger"
  //                 href="#pablo"
  //                 onClick={e => e.preventDefault()}
  //                 size="lg"
  //              >
  //                 Read More
  //                 </Button> */}
  //               {companyObj.twUrl ? <Button
  //                 className="btn-neutral btn-just-icon"
  //                 color="link"
  //                 href="#pablo"
  //                 onClick={e => e.preventDefault()}
  //              >
  //                 <i className="fa fa-twitter" />
  //               </Button> : null}
  //               {companyObj.fbUrl ? <Button
  //                 className="btn-neutral btn-just-icon"
  //                 color="link"
  //                 href={companyObj.fbUrl}
  //                 onClick={e => e.preventDefault()}
  //              >
  //                 <i className="fa fa-facebook-square" />
  //               </Button> : null}
  //               {/* <Button
  //                 className="btn-neutral btn-just-icon"
  //                 color="link"
  //                 href="#pablo"
  //                 onClick={e => e.preventDefault()}
  //              >
  //                 <i className="fa fa-get-pocket" />
  //               </Button> */}
  //             </div>
  //           </Col>
  //         </Row>
  //       </Container>
  //     ),
  //     altText: "",
  //     caption: ""
  //   },
  //   {
  //     src: "url(" + file2.url || '' + ")",
  //     content: (
  //       <Container>
  //         <Row>
  //           <Col className="ml-auto mr-auto text-center" md="8">
  //             <h1 className="title">{title2}</h1>
  //             <h5>
  //               {content2}
  //             </h5>
  //             <br />
  //             {companyObj.twUrl || companyObj.fbUrl || companyObj.igUrl || companyObj.googleUrl ? <h6>Connect with us:</h6> : null}
  //             <div className="buttons">
  //               {companyObj.twUrl ? <Button
  //                 className="btn-neutral btn-just-icon"
  //                 color="link"
  //                 href="#pablo"
  //                 onClick={e => e.preventDefault()}
  //              >
  //                 <i className="fa fa-twitter" />
  //               </Button> : null}
  //               {companyObj.fbUrl ? <Button
  //                 className="btn-neutral btn-just-icon"
  //                 color="link"
  //                 href="#pablo"
  //                 onClick={e => e.preventDefault()}
  //              >
  //                 <i className="fa fa-facebook-square" />
  //               </Button> : null}
  //               {companyObj.igUrl ? <Button
  //                 className="btn-neutral btn-just-icon"
  //                 color="link"
  //                 href="#pablo"
  //                 onClick={e => e.preventDefault()}
  //              >
  //                 <i className="fa fa-instagram" />
  //               </Button> : null}
  //               {companyObj.googleUrl ? <Button
  //                 className="btn-neutral btn-just-icon"
  //                 color="link"
  //                 href="#pablo"
  //                 onClick={e => e.preventDefault()}
  //              >
  //                 <i className="fa fa-google-plus" />
  //               </Button> : null}
  //             </div>
  //           </Col>
  //         </Row>
  //       </Container>
  //     ),
  //     altText: "",
  //     caption: ""
  //   },
  //   {
  //     src: "url(" + file3.url || '' + ")",
  //     content: (
  //       <Container>
  //         <Row>
  //           <Col className="ml-auto text-right" md="7">
  //             <h2 className="title">{title3}</h2>
  //             <h5>
  //               {content3}
  //             </h5>
  //             <br />
  //             <div className="buttons">
  //               <Button
  //                 className="btn-neutral"
  //                 color="link"
  //                 href="#pablo"
  //                 onClick={e => e.preventDefault()}
  //                 size="lg"
  //              >
  //                 <i className="fa fa-share-alt" />
  //                   Share Offer
  //                 </Button>
  //               {/* <Button
  //                 className="btn-round"
  //                 color="success"
  //                 href="#pablo"
  //                 onClick={e => e.preventDefault()}
  //                 size="lg"
  //              >
  //                 <i className="fa fa-shopping-cart" />
  //                   Shop Now
  //                 </Button> */}
  //             </div>
  //           </Col>
  //         </Row>
  //       </Container>
  //     ),
  //     altText: "",
  //     caption: ""
  //   }
  // ];

  const items = data.map(item => renderHeader(companyObj, item, dbLang));
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  // video - header 4
  const [videoPlaying, setVideoPlaying] = React.useState(false);
  const videoRef = React.createRef();
  const videoButtonClick = () => {
    if (videoPlaying) {
      setVideoPlaying(false);
      videoRef.current.pause();
    } else {
      setVideoPlaying(true);
      videoRef.current.play();
    }
  };
  return (
    <>
      {/* <div className="section section-header cd-section" id="headers"> */}
      {/* ********* HEADER 3 ********* */}
      <div className="header-3">
        <div className="page-carousel">
          <div className="filter" />
          <Carousel activeIndex={activeIndex} next={next} previous={previous}>
            <CarouselIndicators
              items={items}
              activeIndex={activeIndex}
              onClickHandler={goToIndex}
            />
            {items.map(item => {
              return (
                <CarouselItem
                  onExiting={onExiting}
                  onExited={onExited}
                  key={item.src}
                >
                  <div
                    className="page-header"
                    style={{ backgroundImage: item.src }}
                  >
                    <div className="filter" />
                    <div className="content-center">{item.content}</div>
                  </div>
                </CarouselItem>
              );
            })}
            <a
              className="left carousel-control carousel-control-prev"
              data-slide="prev"
              href="#pablo"
              onClick={e => {
                e.preventDefault();
                previous();
              }}
              role="button"
            >
              <span className="fa fa-angle-left" />
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="right carousel-control carousel-control-next"
              data-slide="next"
              href="#pablo"
              onClick={e => {
                e.preventDefault();
                next();
              }}
              role="button"
            >
              <span className="fa fa-angle-right" />
              <span className="sr-only">Next</span>
            </a>
          </Carousel>
        </div>
      </div>
      {/* ********* END HEADER 3 ********* */}
    </>
  );
}

// export default SectionHeader3;
export default withCookies(SectionHeader3);
