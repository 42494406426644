import React from 'react';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  Container,
  Row,
  Col,
} from 'reactstrap';

// core components

function SectionTeam5(props = {}) {
  const { form } = props;
  //  console.log(form)
  const {
    text2 = 'text2',
    text3 = 'text3',
    text4 = 'text4',
    text5 = 'text5',
    text6 = 'text6',
    text7 = 'text7',
    text8 = 'text8',
    text9 = 'text9',
    text10 = 'text10',
    text11 = 'text11',
    text12 = 'text12',
    text13 = 'text13',
    text14 = 'text14',
    text15 = 'text15',
  } = form;
  return (
    <>
      <div className="section section-team cd-section" id="teams">
        {/* ********* TEAM 5 ********* */}
        <div
          className="team-5 section-image"
          style={{
            backgroundImage: `url(${require('assets/img/sections/martin-knize.jpg')})`,
          }}
        >
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">{text2}</h2>
                <h5 className="description">{text3}</h5>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <Row>
                    <Col md="5">
                      <CardImg top tag="div">
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require('assets/img/faces/erik-lucatero-2.jpg')}
                          />
                        </a>
                      </CardImg>
                    </Col>
                    <Col md="7">
                      <CardBody className="text-left">
                        <CardTitle tag="h4">{text4}</CardTitle>
                        <h6 className="card-category">{text5}</h6>
                        <p className="card-description">{text6}</p>
                        <CardFooter>
                          <Button
                            className="btn-just-icon btn-neutral mr-1"
                            color="link"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            <i className="fa fa-twitter" />
                          </Button>
                          <Button
                            className="btn-just-icon btn-neutral mr-1"
                            color="link"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            <i className="fa fa-facebook" />
                          </Button>
                          <Button
                            className="btn-just-icon btn-neutral"
                            color="link"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            <i className="fa fa-google-plus" />
                          </Button>
                        </CardFooter>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <Row>
                    <Col md="5">
                      <CardImg top tag="div">
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require('assets/img/faces/kaci-baum-2.jpg')}
                          />
                        </a>
                      </CardImg>
                    </Col>
                    <Col md="7">
                      <CardBody className="text-left">
                        <CardTitle tag="h4">{text7}</CardTitle>
                        <h6 className="card-category">{text8}</h6>
                        <p className="card-description">{text9}</p>
                        <CardFooter>
                          <Button
                            className="btn-just-icon btn-neutral mr-1"
                            color="link"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            <i className="fa fa-linkedin" />
                          </Button>
                          <Button
                            className="btn-just-icon btn-neutral mr-1"
                            color="link"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            <i className="fa fa-dribbble" />
                          </Button>
                          <Button
                            className="btn-just-icon btn-neutral"
                            color="link"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            <i className="fa fa-pinterest" />
                          </Button>
                        </CardFooter>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <Row>
                    <Col md="5">
                      <CardImg top tag="div">
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require('assets/img/faces/clem-onojeghuo-3.jpg')}
                          />
                        </a>
                      </CardImg>
                    </Col>
                    <Col md="7">
                      <CardBody className="text-left">
                        <CardTitle tag="h4">{text10}</CardTitle>
                        <h6 className="card-category">{text11}</h6>
                        <p className="card-description">{text12}</p>
                        <CardFooter>
                          <Button
                            className="btn-just-icon btn-neutral mr-1"
                            color="link"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            <i className="fa fa-youtube" />
                          </Button>
                          <Button
                            className="btn-just-icon btn-neutral mr-1"
                            color="link"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            <i className="fa fa-twitter" />
                          </Button>
                          <Button
                            className="btn-just-icon btn-neutral"
                            color="link"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            <i className="fa fa-instagram" />
                          </Button>
                        </CardFooter>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <Row>
                    <Col md="5">
                      <CardImg top tag="div">
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require('assets/img/faces/clem-onojeghuo-2.jpg')}
                          />
                        </a>
                      </CardImg>
                    </Col>
                    <Col md="7">
                      <CardBody className="text-left">
                        <CardTitle tag="h4">{text13}</CardTitle>
                        <h6 className="card-category">{text14}</h6>
                        <p className="card-description">{text15}</p>
                        <CardFooter>
                          <Button
                            className="btn-just-icon btn-neutral mr-1"
                            color="link"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            <i className="fa fa-linkedin" />
                          </Button>
                          <Button
                            className="btn-just-icon btn-neutral mr-1"
                            color="link"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            <i className="fa fa-instagram" />
                          </Button>
                          <Button
                            className="btn-just-icon btn-neutral"
                            color="link"
                            href="#pablo"
                            onClick={e => e.preventDefault()}
                          >
                            <i className="fa fa-dribbble" />
                          </Button>
                        </CardFooter>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        {/* ********* END TEAM 5 ********* */}
      </div>
    </>
  );
}

export default SectionTeam5;
