import React from 'react';
// react plugin used to create DropdownMenu for selecting items
// import Select from "react-select";
import { withCookies } from 'react-cookie';
import intl from 'react-intl-universal';
import {
  Container,
  Image,
  Segment,
  Grid,
  List,
  Header,
} from 'semantic-ui-react';
// import { Link } from 'react-router-dom';

// reactstrap components
// import { Button, FormGroup, Container, Row, Col } from "reactstrap";

// core components

function SectionFooterAreas5(props = {}) {
  const { form, companyObj, cookies } = props;
  // console.log(companyObj);
  const { themeConf = {} } = companyObj
  const { img1File = {}, } = themeConf
  const footerMain = {
    background: '#EEE',
    color: '#555',
    borderTop: '5px solid rgba(0, 0, 0, 0.2)',
    padding: 0,
  };
  const flexRow = {
    display: 'flex',
    alignItems: 'center',
  };
  const flexCenter = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
  const _margin = {
    margin: 0,
    fontSize: '14px',
    borderBottom: '1px dotted #444',
  };
  const flexCol = {
    display: 'flex',
    flexDirection: 'column',
  };
  const listHeader = {
    fontWeight: 'bolder',
  };
  const listDes = {};
  const bgPadding = {
    padding: '80px 0',
  };
  const paddingReset = {
    padding: 0,
  };
  const footerLink = {
    margin: '3px',
    fontSize: '14px',
    color: '#333',
  };
  const footerDiv = {
    margin: '0 3px',
    color: '#333',
  };
  const footerCopyright = {
    padding: '20px 0 70px',
    background: '#DDD',
  };
  const copyrightText = {
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 400,
    marginTop: 0,
  };
  // console.log(companyObj)

  return (
    <Segment style={footerMain} className="no-print">
      <Container>
        <Grid columns={3} style={bgPadding}>
          <Grid.Row style={paddingReset}>
            <Grid.Column>
              <Image
                src={(img1File && img1File.url) || ''}
              />
              {/* {(img1File && img1File.url) || ''} */}
            </Grid.Column>
            <Grid.Column style={flexCol}>
              <List style={flexCol}>
                <List.Header style={listHeader}>
                  {intl.get('Address:')}
                </List.Header>

                <List.Description style={listDes}>
                  {intl.get('〒460-0007')}
                </List.Description>

                <List.Description style={listDes}>
                  {intl.get('愛知県名古屋市中区新栄1－30－3')}
                  <br />
                  {intl.get('愛知県名古屋市中区新栄1－30－29')}
                </List.Description>
              </List>
              <List>
                <List.Item style={flexRow}>
                  <List.Header as="h6" style={_margin}>
                    TEL :&nbsp;
                  </List.Header>
                  <List.Description as="p">
                    {companyObj.phoneNum || ''}
                  </List.Description>
                </List.Item>
                <List.Item style={flexRow}>
                  <List.Header as="h6" style={_margin}>
                    Fax : &nbsp;
                  </List.Header>
                  <List.Description as="p">
                    {companyObj.fax || ''}
                  </List.Description>
                </List.Item>
                <List.Item style={flexRow}>
                  <List.Header as="h6" style={_margin}>
                    Email : &nbsp;
                  </List.Header>
                  <List.Description as="p">
                    {companyObj.email || ''}
                  </List.Description>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column style={flexCol}>
              <List>
                <List.Header style={listHeader}>
                  {intl.get('お問い合わせ：')}
                </List.Header>
                <List.Description style={listDes}>
                  {intl.get('受付時間：9：00～17：30')}
                </List.Description>
                <List.Description style={listDes}>
                  {intl.get('休日：土曜日、日曜日、祝日')}
                </List.Description>
              </List>
              <List>
                <List.Item style={flexRow}>
                  <List.Header as="h6" style={_margin}>
                    QQ : &nbsp;
                  </List.Header>
                  <List.Description as="p">
                    {companyObj.qq || ''}
                  </List.Description>
                </List.Item>
                <List.Item style={flexRow}>
                  <List.Header as="h6" style={_margin}>
                    Skype :&nbsp;{' '}
                  </List.Header>
                  <List.Description as="p">
                    {companyObj.Skype || ''}
                  </List.Description>
                </List.Item>
                {/* <List.Item style={flexRow}>
              <List.Header as='h6' style={_margin}>Wechat : &nbsp;</List.Header>
              <List.Description as='p'>aiajapanese</List.Description>
            </List.Item>
            <List.Item style={flexRow}>
              <List.Header as='h6' style={_margin}>Line :&nbsp; </List.Header>
              <List.Description as='p'>aiajapanese</List.Description>
            </List.Item> */}
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  );
}

// export default SectionFooterAreas;
export default withCookies(SectionFooterAreas5);
