import React, { Component } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col
} from "reactstrap";
import 'react-alice-carousel/lib/alice-carousel.css';

// core components
const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 3 },
};
export class SectionCourse2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      num: 1,
      isPress1: true,
      isPress2: false,
      isPress3: false,
      isPress4: false,
    };
  }

  componentDidMount = async () => {
    // const { companyObj } = this.props
    // // const companyId = companyObj.objectId
    // const data = await Parse.queryData('album', { companyId: 'aKDM0xR8py', isopen: '1' }, { sort: 'date_descend' });
    // this.setState({ album: data })
  }


  render() {
    const { form, cookies, webimageArr = [] } = this.props;
    const dbLang = cookies.get('dbLang') || 'name';
    let arr = []
    arr = webimageArr.filter(item => item.navId === form.objectId)
    const { text2 = '', text3 = '', img1File } = form;
    const { num, } = this.state

    // console.log("sectioncourse2 arr:", arr)
    return (
      <>
        <br />
        <br />
        <br />
        <br />
        <Container>
          <div dangerouslySetInnerHTML={{ __html: (arr && arr[0] && arr[0]['content1' + dbLang]) || '' }} />
        </Container>
      </>
    );
  }
}

export default SectionCourse2;
