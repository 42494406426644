import React from 'react';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
} from 'reactstrap';

// core components

function SectionPricing2(props = {}) {
  const { form } = props;
  // console.log(form)
  const {
    text2 = 'text2',
    text3 = 'text3',
    text4 = 'text4',
    text5 = 'text5',
    text6 = 'text6',
    text7 = 'text7',
    text8 = 'text8',
    text9 = 'text9',
    text10 = 'text10',
    text11 = 'text11',
    text12 = 'text12',
    text13 = 'text13',
    text14 = 'text14',
    text15 = 'text15',
    text16 = 'text16',
    text17 = 'text17',
    text18 = 'text18',
    text19 = 'text19',
    text20 = 'text20',
    text21 = 'text21',
    text22 = 'text22',
  } = form;
  const [activePill, setActivePill] = React.useState('1');
  // pills for the last pricing
  const [pillActive, setPillActive] = React.useState('personal');
  return (
    <>
      <div className="section section-pricing cd-section" id="pricing">
        {/* ********* PRICING 2 ********* */}
        <div className="pricing-2">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                <h2 className="title">{text2}</h2>
                <h5 className="description">{text3}</h5>
                <br />
                <Nav className="nav-pills-danger justify-content-center" pills>
                  <NavItem>
                    <NavLink
                      className={activePill === '1' ? 'active' : ''}
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        setActivePill('1');
                      }}
                    >
                      {text4}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activePill === '2' ? 'active' : ''}
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        setActivePill('2');
                      }}
                    >
                      {text5}
                    </NavLink>
                  </NavItem>
                </Nav>
                {/* Pill panes */}
                <TabContent activeTab={`pill-${activePill}`}>
                  <TabPane tabId={`pill-${activePill}`} />
                  <TabPane tabId={`pill-${activePill}`} />
                </TabContent>
              </Col>
            </Row>
            <div className="space-top" />
            <Row>
              <Col md="4">
                <Card
                  className="card-pricing"
                  data-background="image"
                  style={{
                    backgroundImage: `url(${require('assets/img/sections/daniel-olahh.jpg')})`,
                  }}
                >
                  <CardBody>
                    <h6 className="card-category">{text6}</h6>
                    <CardTitle tag="h1">
                      <small>{text7}</small>
                    </CardTitle>
                    <ul>
                      <li>{text8}</li>
                      <li>{text9}</li>
                      <li>{text10}</li>
                    </ul>
                    <Button
                      className="btn-round"
                      color="warning"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      {text11}
                    </Button>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card
                  className="card-pricing"
                  data-background="image"
                  style={{
                    backgroundImage: `url(${require('assets/img/sections/forest-bg.jpg')})`,
                  }}
                >
                  <CardBody>
                    <h6 className="card-category">{text12}</h6>
                    <CardTitle tag="h1">
                      <small>{text13}</small>
                    </CardTitle>
                    <ul>
                      <li>{text14}</li>
                      <li>{text15}</li>
                      <li>{text16}</li>
                    </ul>
                    <Button
                      className="btn-round"
                      color="success"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      {text19}
                    </Button>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card
                  className="card-pricing"
                  data-background="image"
                  style={{
                    backgroundImage: `url(${require('assets/img/sections/anders-jilden.jpg')})`,
                  }}
                >
                  <CardBody>
                    <h6 className="card-category">{text17}</h6>
                    <CardTitle tag="h1">
                      <small>{text18}</small>
                    </CardTitle>
                    <ul>
                      <li>{text19}</li>
                      <li>{text20}</li>
                      <li>{text21}</li>
                    </ul>
                    <Button
                      className="btn-round"
                      color="danger"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      {text22}
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        {/* ********* END PRICING 2 ********* */}
      </div>
    </>
  );
}

export default SectionPricing2;
