import React, { Component } from 'react';
import AliceCarousel from 'react-alice-carousel';
import Carousel, { ModalGateway, Modal } from 'react-images';
import { Container } from 'semantic-ui-react'
import { Button, FormGroup, Row, Col } from "reactstrap";
import 'react-alice-carousel/lib/alice-carousel.css';
import Parse from 'widget/parse'
const contentCss = {
  maxWidth: '1170px',
  margin: '100px auto',
  flexDirection: 'column',
}
const responsive = { //依照螢幕大小決定顯示幾個圖片
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 3 },
};
const CustomHeader = (rowData) => (
  <h2 style={{ textAlign: 'center', marginTop: '20px' }}>
    {(rowData && rowData.name) || ''}
  </h2>
);
const renderPrevButton = ({ isDisabled }) => {
  return <button style={{ width: '30px', height: '30px', borderRadius: '50%', fontSize: '1rem', fontWeight: 500, border: 'none', outline: 'none' }}>&lt;</button>;
};

const renderNextButton = ({ isDisabled }) => {
  return <button style={{ width: '30px', height: '30px', borderRadius: '50%', fontSize: '1rem', fontWeight: 500, border: 'none', outline: 'none' }}>&gt;</button>;
};

export class SectionSlider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedIndex: 0,
      lightboxIsOpen: false,
      photos: [],
      album: [],
      rowData: {},
      isOpen: false,
      nowIndex: 0
    };
  }

  componentDidMount = async () => {
    const { companyObj } = this.props
    // const companyId = companyObj.objectId
    const data = await Parse.queryData('album', { companyId: 'aKDM0xR8py', isopen: '1' }, { sort: 'date_descend' });
    this.setState({ album: data })
  }

  handlePhotos = async (rowData, albumId) => {
    // const { companyObj } = this.props
    // const companyId = companyObj.objectId
    const photos = await Parse.queryData('webimage', { companyId: 'aKDM0xR8py', albumId });
    this.setState({ photos, rowData, isOpen: true })
    console.log(rowData)
  }

  handleClose = () => {
    this.setState({ isOpen: false })
  };


  renderDotsItem = (e) => {
    const activeIndex = e.activeIndex
    return <><Button
      size="sm"
      style={{
        borderRadius: '50%', backgroundColor: '#FFF2F2', border: 'none', color: '#A1A1A1', fontSize: '1rem'
      }}>
      {activeIndex + 1}
    </Button></>
  }

  render() {
    const { form, cookies, webimageArr } = this.props;
    const { text2 = '標題', text3 = '', img1File = {} } = form;
    const { nowIndex } = this.state
    const dbLang = cookies.get('dbLang') || 'name';
    // const arr = webimageArr.filter(item => item.navId === form.objectId)
    const { album, isOpen, photos, field = 'img1File2', rowData } = this.state
    const items = album.map((item, index) => {
      const style = {
        width: '95%', height: '300px', cursor: 'pointer', backgroundImage:
          `url(${(item.img1File2 && item.img1File2.url) || require("assets/img/bg/rawpixel-comm.jpg")})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
      };
      return (<>
        <div style={style} onClick={() => this.setState({ nowIndex: index - 1 }, () => this.handlePhotos(item, item.objectId))}></div>
        {/* <h5 style={{ marginTop: '10px', textAlign: 'center', fontWeight: 500 }}>{item.name}</h5> */}
        {/**<div dangerouslySetInnerHTML={{ __html: (arr && arr[0] && arr[0]['content1' + dbLang]) || '' }} /> */}
        <h5 style={{ marginTop: '10px', textAlign: 'center', fontWeight: 500 }} dangerouslySetInnerHTML={{ __html: `<p style="text-align: center; font-size: 20px;">${item[dbLang] || ''}</p>` }}></h5>
        <Button
          color="link"
          style={{
            marginLeft: '32%', color: '#866155', fontWeight: 400
          }}
          // href=""
          onClick={() => this.setState({ nowIndex: index - 1 }, () => this.handlePhotos(item, item.objectId))}
        >
          See more
        </Button>
      </>)
    });
    const currentIndex = photos.findIndex(item => item.objectId === rowData.objectId) !== -1 ?
      photos.findIndex(item => item.objectId === rowData.objectId) : 0;

    const options = { currentIndex }

    const src0 = (rowData && rowData[field.substr(0, field.length - 1)]);
    const source = src0 && src0.url;
    const caption = (rowData && rowData.name) || ''

    const items1 = photos && photos.length ? photos.map(item => {
      const src = (item && item[field.substr(0, field.length - 1)]);
      const caption = (item && item.name) || ''
      const source = src && src.url;
      return { source, caption };
    }) : [{ source, caption }]
    return (
      <>
        <Container style={contentCss}>
          {/* <h2 style={{ textAlign: 'center' }}>{text2}</h2> */}
          <br />
          <br />
          <br />
          <br />
          <div style={{ display: 'flex' }}>
            <AliceCarousel
              mouseTracking
              activeIndex={nowIndex}
              autoPlay
              autoPlayStrategy="all"
              autoPlayInterval={7000}
              animationDuration={300}
              animationType="fadeout"
              items={items}
              responsive={responsive}
              renderPrevButton={renderPrevButton}
              renderNextButton={renderNextButton}
              renderDotsItem={this.renderDotsItem}
            />
          </div>
          <ModalGateway>
            {isOpen ? (
              <Modal
                onClose={this.handleClose}
                allowFullscreen={false}
                styles={{
                  blanket: base => ({
                    ...base,
                    backgroundColor: '#F4F5F7',
                  }),
                  positioner: base => ({
                    ...base,
                    display: 'block',
                  }),
                }}
              >
                <Carousel
                  components={{ Header: () => CustomHeader(rowData) }}
                  views={items1}
                  {...options}
                  styles={{
                    container: base => ({
                      ...base,
                      height: '100vh',
                    }),
                    view: base => ({
                      ...base,
                      alignItems: 'center',
                      display: 'flex ',
                      height: 'calc(90vh - 54px)',
                      justifyContent: 'center',

                      ['@media (min-width: 770px)']: {
                        padding: 20,
                      },

                      '&> img': {
                        maxHeight: 'calc(100vh - 94px)',
                      },
                    }),
                    navigationPrev: base => ({
                      ...base,
                      backgroundColor: 'white',
                      boxShadow: '0 1px 6px rgba(0, 0, 0, 0.18)',
                      color: '#6B778C',

                      '&:hover, &:active': {
                        backgroundColor: 'white',
                        color: '#091E42',
                        opacity: 1,
                      },
                      '&:active': {
                        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.14)',
                        transform: 'scale(0.96)',
                      },
                    }),
                    navigationNext: base => ({
                      ...base,
                      backgroundColor: 'white',
                      boxShadow: '0 1px 6px rgba(0, 0, 0, 0.18)',
                      color: '#6B778C',

                      '&:hover, &:active': {
                        backgroundColor: 'white',
                        color: '#091E42',
                        opacity: 1,
                      },
                      '&:active': {
                        boxShadow: '0 1px 3px rgba(0, 0, 0, 0.14)',
                        transform: 'scale(0.96)',
                      },
                    }),
                  }} />
              </Modal>
            ) : null}
          </ModalGateway>
        </Container>
      </>
    );
  }
}
export default SectionSlider;