import React, { Component } from 'react';
import { Container, Select } from 'semantic-ui-react';
// import MaterialTable from 'widget/material-table';
// import ColorNavbar from 'components/Navbars/ColorNavbar.js';
// import FooterBlack from 'components/Footers/FooterBlack.js';
import PageHeader from 'components/Headers/PageHeader';
import { Table } from 'reactstrap';

const columns = () => [
  { title: '日期', field: 'date' },
  { title: '購買項目', field: 'buyItems' },
  { title: '付費方式', field: 'payWays' },
  { title: '付費金額', field: 'payment' },
];
export class RecordInquire extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = (e, { value }) => this.setState({ value });

  render() {
    // const { value, dataArr } = this.state;

    // const bannerImg = {
    //   height: 390,
    //   backgroundImage: `url(${'/images/aichi/g2/DSC_8099.jpg'})`,
    //   backgroundRepeat: 'no-repeat',
    //   backgroundSize: 'cover',
    //   backgroundPosition: '50% 40%',
    //   display: 'flex',
    //   justifyContent: 'center',
    //   alignItems: 'center',
    // };
    // const bannerText = {
    //   fontSize: '2.8rem',
    //   fontWeight: '600',
    //   letterSpacing: '2px',
    //   color: 'rgba(255, 255, 255, .9)',
    //   width: '1140px',
    //   textAlign: 'right',
    // };
    // const content = {
    //   maxWidth: '1170px',
    //   margin: '80px auto 110px',
    //   flexDirection: 'column',
    // };
    // const list = {
    //   listStyleType: 'circle',
    // };
    // const item = {};
    // const itemTitle = {
    //   fontWeight: 400,
    //   margin: '0 0 17px 0',
    //   lineHeight: '1.8',
    // };
    // const itemText = {
    //   margin: '0 0 30px 0',
    //   lineHeight: '1.8',
    // };
    // const itemTextL = {
    //   margin: '0 0 47px 0',
    //   lineHeight: '1.8',
    // };
    // const font = {
    //   red: {
    //     color: 'crimson',
    //   },
    //   bold: {
    //     fontWeight: 700,
    //   },
    // };
    return (<>
      <PageHeader url="/hp1/24.jpg" text="" />
      <Container style={content}>
        <Select />
        <br />
        <br />
        <h4 className="title">
          <small>紀錄</small>
        </h4>
        <Table responsive striped>
          <thead>
            <tr>
              <th className="text-center">#</th>
              <th>日期</th>
              <th>單號</th>
              <th>購買項目</th>
              <th>付費方式</th>
              <th>付費金額</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-center">1</td>
              <td>Moleskine Agenda</td>
              <td>Office</td>
              <td>25</td>
              <td>€ 49</td>
              <td>€ 1,225</td>
            </tr>
            <tr>
              <td className="text-center">2</td>
              <td>Stabilo Pen</td>
              <td>Office</td>
              <td>30</td>
              <td>€ 10</td>
              <td>€ 300</td>
            </tr>
            <tr>
              <td className="text-center">3</td>
              <td>A4 Paper Pack</td>
              <td>Office</td>
              <td>50</td>
              <td>€ 10.99</td>
              <td>€ 109</td>
            </tr>
            <tr>
              <td className="text-center">4</td>
              <td>Apple iPad</td>
              <td>Meeting</td>
              <td>10</td>
              <td>€ 499.00</td>
              <td>€ 4,990</td>
            </tr>
            <tr>
              <td className="text-center">5</td>
              <td>Apple iPhone</td>
              <td>Communication</td>
              <td>10</td>
              <td>€ 599.00</td>
              <td>€ 5,999</td>
            </tr>
            <tr>
              <td colSpan="5" />
              <td className="td-total">Total</td>
              <td className="td-price">
                <small>€</small>
                12,999
              </td>
            </tr>
          </tbody>
        </Table>
      </Container>
    </>);
  }
}

export default RecordInquire;
