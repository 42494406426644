import React, { Component } from 'react';
// import { connect } from 'react-redux'
// import { createProject } from '../../store/actions/projectActions'
// import { Redirect } from 'react-router-dom'
import { Container, Header, Form, Icon, Button } from 'semantic-ui-react';
import { Modal } from 'rsuite';

// import ColorNavbar from 'components/Navbars/ColorNavbar.js';
// import FooterBlack from 'components/Footers/FooterBlack.js';
// import PageHeader from 'components/Headers/PageHeader';

// import Parse from 'widget/parse';
// import PreloadFile from 'widget/material-table/PreloadFile';
// import { readFileAsURL } from 'widget/material-table/RowImage';

export class ModalEditContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      imagePreviewUrlArr: [],
      photo: '',
      form: {},
      addressText: '',
      phoneText: '',
      timeText: '',
      emailText: '',
    };
  }

  componentDidMount = () => {
    const { form } = this.state;
    const addressText = document.getElementById('address').innerHTML || '';
    const phoneText = document.getElementById('phone').innerHTML || '';
    const timeText = document.getElementById('time').innerHTML || '';
    const emailText = document.getElementById('email').innerHTML || '';
    this.setState({
      form: {
        ...form,
        newAddress: addressText,
        newPhone: phoneText,
        newTime: timeText,
        newEmail: emailText,
      },
    });
  };

  handleChange = id => {
    const { form } = this.state;
    const { value } = document.getElementById(id);
    this.setState({ form: { ...form, [id]: value } });
  };

  closeEdit = () => {
    this.setState({ show: false });
  };

  onEdit = () => {
    this.setState({ show: true });
  };

  render() {
    const { handleContact, id } = this.props;
    const { show, form } = this.state;
    return (<>
      <a className="j-pop-image" onClick={this.onEdit}>
        編輯
      </a>
      <Modal open={show} onClose={this.closeEdit} size="lg">
        <Modal.Header>
          <Modal.Title>

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="white-popup" id="popup-contactsetting">
            <form className="setBox" data-parsley-required-message="尚未填寫">
              <div className="top_title">
                <h3>聯絡資訊設定</h3>
                <p>填寫完整資訊，讓訪客更容易與您聯繫</p>
              </div>
              <div className="fillinBox">
                <ul>
                  <li>
                    <label>
                      <p className="label_title">地址</p>
                    </label>
                    <input
                      type="text"
                      name="newAddress"
                      id="newAddress"
                      value={form.newAddress}
                      data-parsley-trigger="change"
                      data-parsley-required="true"
                      onChange={() => this.handleChange('newAddress')}
                    />
                    <p className="the_reminder">
                      地址將會自動帶入 Google Maps，請填寫有效地址
                    </p>
                  </li>
                  <li>
                    <label>
                      <p className="label_title">電話</p>
                    </label>
                    <input
                      type="text"
                      name="newAddress"
                      id="newPhone"
                      value={form.newPhone}
                      onChange={() => this.handleChange('newPhone')}
                    />
                  </li>
                  <li>
                    <label>
                      <p className="label_title">營業時間</p>
                    </label>
                    <input
                      type="text"
                      name="newAddress"
                      id="newTime"
                      value={form.newTime}
                      onChange={() => this.handleChange('newTime')}
                    />
                  </li>
                  <li>
                    <label>
                      <p className="label_title">電子郵件</p>
                    </label>
                    <input
                      type="text"
                      name="newAddress"
                      id="newEmail"
                      value={form.newEmail}
                      onChange={() => this.handleChange('newEmail')}
                    />
                  </li>
                </ul>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ backgroundColor: '#27d0ca', color: '#fff' }}
            content="套用"
            onClick={() => {
              handleContact(form);
              this.closeEdit();
            }}
          />
        </Modal.Footer>
      </Modal>
    </>);
  }
}

// const mapStateToProps = (state) => {
//   return {
//     auth: state.firebase.auth
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     createProject: (project) => dispatch(createProject(project))
//   }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(HostIndex)
export default ModalEditContact;
