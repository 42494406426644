import React, { Component } from 'react';
import { Grid, Divider, List, } from 'semantic-ui-react';
import intl from 'react-intl-universal';

import ColorNavbar from 'components/Navbars/ColorNavbar.js';
import FooterBlack from 'components/Footers/FooterBlack.js';
import PageHeader from 'components/Headers/PageHeader';

export default class PrivacyPolicy extends Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {};
  // }
  state = {

  }

  componentDidMount() {
    window.scrollTo(0, 0); // 置頂代碼
  }

  handleChange = (e, { value }) => this.setState({ value });

  render() {
    const { t } = this.props;
    const bannerImg = {
      height: 180,
      // backgroundImage: `url(${'/hp2/3.jpg'})`,
      // backgroundRepeat: 'no-repeat',
      // backgroundSize: 'cover',
      // backgroundPosition: '50% 44%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    };
    const bannerText = {
      fontSize: '2.8rem',
      fontWeight: '600',
      letterSpacing: '2px',
      color: 'rgba(120, 120, 120, .9)',
      width: '1140px',
      textAlign: 'right',
    };
    const itemTitle = {
      fontWeight: 600,
      fontSize: 26,
      margin: '0 0 17px 0',
      lineHeight: '1.8',
    };
    const itemText = {
      fontSize: 20,
      margin: '0 0 30px 0',
      lineHeight: '1.8',
    };
    const content = {
      maxWidth: '1140px',
      margin: '100px auto',
      flexDirection: 'column',
    };
    const list = {
      listStyleType: 'none',
    };
    return (
      <>
        <Grid style={content}>
          <li style={list}>
            <List.Header as="h4" style={itemTitle}>
              1. 宣言
            </List.Header>
            <List.Description style={itemText}>
              '愛知国際学院（以下「AIA」）は、情報の適切な管理は重要な経営課題のひとつであると考え、全ての情報資産に対する機密性、完全性、可用性の確保・向上に努め、お客様を含む社会全体の信頼に応えるため、情報セキュリティ基本方針（以下、本方針）を策定し、これを実施し推進することを宣言します。'
            </List.Description>
          </li>
          <Divider style={{ marginBottom: '30px' }} />
          <li style={list}>
            <List.Header as="h4" style={itemTitle}>
              2. 適用範囲及び適用者
            </List.Header>
            <List.Description style={itemText}>
              AIAでは、管理下にあるすべての業務活動に関わる情報（含個人情報）を適用範囲とし、役員・従業員（正社員、契約社員、嘱託社員、派遣社員、パート、アルバイト）・業務委託契約者を含めた、情報資産を利用する全ての者に適用します。
            </List.Description>
          </li>
          <Divider style={{ marginBottom: '30px' }} />
          <li style={list}>
            <List.Header as="h4" style={itemTitle}>
              3. 法令順守
            </List.Header>
            <List.Description style={itemText}>
              AIAは、情報セキュリティに関する各種法令、国が定める指針およびその他規範を遵守します。
            </List.Description>
          </li>
          <Divider style={{ marginBottom: '30px' }} />
          <li style={list}>
            <List.Header as="h4" style={itemTitle}>
              4. 情報セキュリティ規程の制定
            </List.Header>
            <List.Description style={itemText}>
              AIAは、情報セキュリティ対策を講じるにあたり、遵守すべき行為および判断などの基準を統一的に定めるため、必要となる基本的な要件を明記した情報セキュリティ規程を制定します。
            </List.Description>
          </li>
          <Divider style={{ marginBottom: '30px' }} />
          <li style={list}>
            <List.Header as="h4" style={itemTitle}>
              5. 情報セキュリティ管理体制の確立
            </List.Header>
            <List.Description style={itemText}>
              AIAは、情報セキュリティ管理体制を整備し、組織として意思統一された情報セキュリティ管理を実施します。
            </List.Description>
          </li>
          <Divider style={{ marginBottom: '30px' }} />
          <li style={list}>
            <List.Header as="h4" style={itemTitle}>
              6. セキュリティ事件・事故の対応
            </List.Header>
            <List.Description style={itemText}>
              AIAは、万が一情報セキュリティの問題が発生した場合迅速に対応し、その被害を最小限に留めます。また再発防止策を含む適切な対策を講じます。
            </List.Description>
          </li>
          <Divider style={{ marginBottom: '30px' }} />
          <li style={list}>
            <List.Header as="h4" style={itemTitle}>
              7. 情報セキュリティ教育・訓練の実施
            </List.Header>
            <List.Description style={itemText}>
              AIAは、役員および全従業員・業務委託契約者に対して情報セキュリティの重要性を認識させ、情報資産が適正に利用されるよう、教育・訓練を実施します。
            </List.Description>
          </li>
          <Divider style={{ marginBottom: '30px' }} />
          <li style={list}>
            <List.Header as="h4" style={itemTitle}>
              8.{' '}
              情報セキュリティ対策実施状況の評価及び継続的改善
            </List.Header>
            <List.Description style={itemText}>
              AIAは、本方針および情報セキュリティ規程が遵守されていることを確認するため、定期的に情報セキュリティ対策実施状況を評価し、継続的な改善を図ります。
            </List.Description>
          </li>
          <Divider style={{ marginBottom: '30px' }} />
          <li style={list}>
            <List.Header as="h4" style={itemTitle}>
              9. 文書の改廃
            </List.Header>
            <List.Description style={itemText}>
              本方針の改廃は、情報セキュリティ委員会が起案し取締役会及び理事会の承認を必要とします。
            </List.Description>
          </li>
        </Grid>
      </>
    );
  }
}
