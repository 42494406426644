/*eslint-disable*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col
} from "reactstrap";

// core components

function SectionProject4(props = {}) {
  const { form } = props;
  //  console.log(form)
  const { text2 = 'text2', text3 = 'text3', text4 = 'text4', text5 = 'text5', text6 = 'text6',
    text7 = 'text7', text8 = 'text8', text9 = 'text9', text10 = 'text10', text11 = 'text11', text12 = 'text12',
    text13 = 'text13', text14 = 'text14', text15 = 'text15', text16 = 'text16', text17 = 'text17',
    text18 = 'text18', text19 = 'text19', text20 = 'text20' } = form;
  const [activePill, setActivePill] = React.useState("1");
  return (
    <>
      <div className="section section-project cd-section" id="projects">
        {/* ********* PROJECTS 4 ********* */}
        <div className="project-4 section section-dark">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">{text2}</h2>
                <h5 className="description">
                  {text3}
                </h5>
              </Col>
            </Row>
            <div className="space-top" />
            <Row>
              <Col className="ml-auto" md="5">
                <Card
                  data-background="image"
                  style={{
                    backgroundImage:
                      "url(" +
                      require("assets/img/sections/sebastien-gabrieles.jpg") +
                      ")"
                  }}
                >
                  <div className="card-icon">
                    <i className="nc-icon nc-mobile" />
                  </div>
                </Card>
              </Col>
              <Col className="mr-auto" md="5">
                <Card className="card-plain">
                  <CardBody>
                    <h6 className="card-category">{text4}</h6>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <CardTitle tag="h3">
                        {text5}
                      </CardTitle>
                    </a>
                    <p className="card-description">
                      {text6}
                    </p>
                    <CardFooter>
                      <Button
                        className="btn-neutral mr-1"
                        color="link"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        <i className="fa fa-book mr-1" />
                        Read More
                      </Button>
                      <Button
                        className="btn-just-icon btn-neutral mr-1"
                        color="link"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        <i className="fa fa-apple" />
                      </Button>
                      <Button
                        className="btn-just-icon btn-neutral mr-1"
                        color="link"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        <i className="fa fa-android" />
                      </Button>
                      <Button
                        className="btn-just-icon btn-neutral"
                        color="link"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        <i className="fa fa-windows" />
                      </Button>
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <br />
            <hr />
            <br />
            <Row>
              <Col className="ml-auto" md="5">
                <Card className="card-plain">
                  <CardBody>
                    <h6 className="card-category">{text7}</h6>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <CardTitle tag="h3">
                        {text8}
                      </CardTitle>
                    </a>
                    <p className="card-description">
                      {text9}
                    </p>
                    <CardFooter>
                      <Button
                        className="btn-neutral mr-1"
                        color="link"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        <i className="fa fa-book mr-1" />
                        Read More
                      </Button>
                      <Button
                        className="btn-just-icon btn-neutral mr-1"
                        color="link"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        <i className="fa fa-apple" />
                      </Button>
                      <Button
                        className="btn-just-icon btn-neutral mr-1"
                        color="link"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        <i className="fa fa-android" />
                      </Button>
                      <Button
                        className="btn-just-icon btn-neutral"
                        color="link"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        <i className="fa fa-windows" />
                      </Button>
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
              <Col className="mr-auto" md="5">
                <Card
                  data-background="image"
                  style={{
                    backgroundImage:
                      "url(" +
                      require("assets/img/sections/john-towner.jpg") +
                      ")"
                  }}
                >
                  <div className="card-icon">
                    <i className="nc-icon nc-controller-modern" />
                  </div>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      {/* ********* END PROJECTS 4 ********* */}
    </>
  );
}

export default SectionProject4;
