import React, { Component } from 'react';
// import { connect } from 'react-redux'
// import { createProject } from '../../store/actions/projectActions'
// import { Redirect } from 'react-router-dom'
import { Container, Header, Form, Icon, Button } from 'semantic-ui-react';
import { Modal } from 'rsuite';

// import ColorNavbar from 'components/Navbars/ColorNavbar.js';
// import FooterBlack from 'components/Footers/FooterBlack.js';
// import PageHeader from 'components/Headers/PageHeader';

// import Parse from 'widget/parse';
// import PreloadFile from 'widget/material-table/PreloadFile';
import { readFileAsURL } from 'widget/material-table/RowImage';

export class ModalEditBackground extends Component {
  constructor(props) {
    super(props);
    //    console.log(props);
    this.state = {
      show: false,
      imagePreviewUrlArr: [],
      photo: '',
    };
  }

  handleChange = (e, { value }) => this.setState({ value });

  closeEdit = () => {
    this.setState({ show: false });
  };

  onEdit = () => {
    this.setState({ show: true });
  };
  //   handleChange = (e) => {
  //     // console.log(e);
  //     this.setState({
  //       [e.target.id]: e.target.value
  //     })
  //   }

  //   handleSubmit = (e) => {
  //     // console.log(e);
  //     e.preventDefault();
  //     this.props.createProject(this.state);
  //     this.props.history.push('/');
  //   }
  handleChangeFile = async file => {
    this.setState({ file, changeConfirm: false });
  };

  handleChange = async e => {
    e.preventDefault();
    const { files } = e.target;
    if (!files || !files.length) {
      return;
    }
    const promises = [];
    const item = files[0];
    const element = readFileAsURL(item);
    promises.push(element);
    const imagePreviewUrlArr = await Promise.all(promises).catch(error =>
      console.log('error', error),
    );
    this.setState({ imagePreviewUrlArr });
  };

  render() {
    const { handleChangeBackground, originPic, background } = this.props;
    const { show, imagePreviewUrlArr } = this.state;
    //     if (!auth.uid) return <Redirect to='/signin' />
    // const { value } = this.state
    return (<>
      <a className="j-n-set-background" onClick={this.onEdit}>
        背景 <i className="fas fa-undo" style={{ display: 'inline-block' }} />
      </a>
      <Modal open={show} onClose={this.closeEdit}>
        <Modal.Header>
          <Modal.Title>

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="white-popup" id="popup-imagesetting">
            <form className="setBox" data-parsley-required-message="尚未填寫">
              <div className="top_title">
                <h3>圖片設定</h3>
              </div>
              <div className="fillinBox">
                <div className="f-upload-image img_upload">
                  <a className="j-n-upload">
                    <div className="the_btn">
                      <label
                        htmlFor="file"
                        style={{
                          width: '100%',
                          height: '100%',
                          cursor: 'pointer',
                        }}
                      >
                        <p>
                          <span>
                            <i className="fas fa-undo-alt" />
                            重新上傳
                          </span>
                        </p>
                      </label>
                      <input
                        id="file"
                        type="file"
                        style={{ display: 'none' }}
                        onChange={this.handleChange}
                        accept="image/*"
                      />
                    </div>
                    <img
                      src={
                        (imagePreviewUrlArr.length &&
                          imagePreviewUrlArr[0]) ||
                        originPic
                      }
                      alt=""
                    />
                  </a>
                  <p className="the_reminder">
                    提醒您，請確保圖片解析度，最佳建議尺寸為寬度 2000px 以上
                    JPG 或 PNG。
                  </p>
                  <input
                    type="hidden"
                    name="src"
                    value="site/theme/unknown/6caf44ea63578b440ab6aac66cd7783d_double.png"
                  />
                  <input type="hidden" name="width" value="1600" />
                  <input type="hidden" name="height" value="500" />
                  <input className="hide" type="file" accept="image/*" />
                </div>
                <p
                  className="for_pop_note color_red"
                  style={{ display: 'none' }}
                >
                  <i
                    className="fa fa-exclamation-circle"
                    aria-hidden="true"
                  />
                  <span />
                </p>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ backgroundColor: '#27d0ca', color: '#fff' }}
            content="套用"
            onClick={() => {
              handleChangeBackground(imagePreviewUrlArr[0], background);
              this.closeEdit();
            }}
          />
        </Modal.Footer>
      </Modal>
    </>);
  }
}

// const mapStateToProps = (state) => {
//   return {
//     auth: state.firebase.auth
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     createProject: (project) => dispatch(createProject(project))
//   }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(HostIndex)
export default ModalEditBackground;
