import React, { Component } from 'react';
// import { connect } from 'react-redux'
// import { createProject } from '../../store/actions/projectActions'
// import { Redirect } from 'react-router-dom'
import { Container, Header, Form, Icon, Grid, Image } from 'semantic-ui-react';
import { ModalGateway, Modal } from 'react-images';
import ColorNavbar from 'components/Navbars/ColorNavbar.js';
import FooterBlack from 'components/Footers/FooterBlack.js';
import PageHeader from 'components/Headers/PageHeader';
import { Col, Row, CardImg } from 'reactstrap';
import Parse from 'widget/parse';
// import './css/startup1.css'
export class StartUp4 extends Component {
  constructor(props) {
    super(props);
    //    console.log(props);
    this.state = {
      isOpen: false,
      photo: '',
    };
  }

  handleChange = (e, { value }) => this.setState({ value });

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  handleOpen = () => {
    this.setState({ isOpen: true });
  };

  //   handleChange = (e) => {
  //     // console.log(e);
  //     this.setState({
  //       [e.target.id]: e.target.value
  //     })
  //   }

  //   handleSubmit = (e) => {
  //     // console.log(e);
  //     e.preventDefault();
  //     this.props.createProject(this.state);
  //     this.props.history.push('/');
  //   }
  render() {
    const { isOpen } = this.state;
    //     const { auth } = this.props;
    //     if (!auth.uid) return <Redirect to='/signin' />
    // const { value } = this.state
    return (
      <>
        <section
          id="goto192761"
          className="section-base mainBox background-setting"
          style={{
            backgroundImage:
              'url(https://img.holkee.com/site/upload/5e8c3737-8236ap0ab-eef4-a35d6839/b663e4ee1f8eaf1945f344be0527926c_double.jpg)',
          }}
        >
          <div className="ContentsBox ">
            <div className="imgBox">
              <img
                src="https://img.holkee.com/site/upload/5e8c3737-8236ap0ab-eef4-a35d6839/ee87fb2db6a0d9c006375e6f88837f16_double.png"
                className="i-amphtml-fill-content i-amphtml-replaced-content"
                alt=""
              />
            </div>
            <p
              className="sub_title"
              style={{
                textAlign: 'center',
                fontSize: '1.0625rem',
                color: '#FFFFFF',
              }}
            >
              商務西裝訂製，英倫復古西裝，婚禮正式西裝
              <br />
              新郎伴郎首選訂製品牌，打造屬於你的紳士衣櫥
            </p>
            <div className="aBox" />
          </div>
        </section>
        <section
          id="goto192762"
          className="section-base about_us_box background-setting"
          style={{
            backgroundImage:
              'url(https://img.holkee.com/site/upload/5e8c3737-8236ap0ab-eef4-a35d6839/2dfaa9adbc08194a90ca590b3cf43d19_double.jpg)',
          }}
        >
          <div className="ContentsBox  clearfix">
            <div className="aBox fl">
              <div className="imgBox">
                <img
                  src="https://img.holkee.com/site/upload/5e8c3737-8236ap0ab-eef4-a35d6839/9d6848cf430250106320f7fb07d1aa55_double.png"
                  className="i-amphtml-fill-content i-amphtml-replaced-content"
                  alt=""
                />
              </div>
            </div>
            <div className="aBox fr">
              <h3
                className="main_title"
                style={{
                  textAlign: 'center',
                  fontSize: '1.75rem',
                  color: '#FFFFFF',
                }}
              >
                品牌故事
              </h3>
              <p
                className="sub_title"
                style={{
                  textAlign: 'left',
                  fontSize: '0.9375rem',
                  color: '#FFFFFF',
                }}
              >
                我們的訂製西裝從2016開始到現在，已服務上千位紳士。
                <br />
                <br />
                結合了英倫 | 復古 |
                時裝等元素，讓西裝更多元化，專注世界上的流行趨勢，每一年都會更新布料，使我們品牌一直走在前端，堅持台灣代工打造屬於亞洲的西服品牌。
                <br />
                <br />
                專營英國百年品牌布料，提供各式系列布樣挑選
                ，每一季都會更新布款和英國同步。
                <br />
                <br />
                提供客製化紳士服務 Gentry Service
                <br />
                <br />✂ Bespoke 手工全訂製西服
                <br />✂ MTM 手工半訂製西服
                <br />✂ RTW 成衣套量西服
                <br />✂ Tailored shirts 量身定製襯衫
                <br />✂ Gentry Wardrobe 品牌單品
              </p>
            </div>
          </div>
        </section>
        <section
          id="goto192763"
          className="section-base about_us_box background-setting"
          style={{
            backgroundImage:
              'url(https://img.holkee.com/site/upload/5e8c3737-8236ap0ab-eef4-a35d6839/00bf535fd96ec1b14b509462c8b18361_double.jpg)',
          }}
        >
          <div className="ContentsBox  clearfix">
            <div className="aBox fr">
              <h3
                className="main_title"
                style={{
                  textAlign: 'center',
                  fontSize: '1.75rem',
                  color: '#FFFFFF',
                }}
              >
                客製化西裝
              </h3>
              <p
                className="sub_title"
                style={{
                  textAlign: 'left',
                  fontSize: '0.9375rem',
                  color: '#FFFFFF',
                }}
              >
                Bespoke Suit┃全訂製西服 <br />
                Bespoke一詞意為「已完成的預約」，西服和紳士皮鞋領域最常使用這個詞，後來也被許多品牌借用，已經成為「頂級手藝的專屬訂製」。
                <br />
                <br />
                Bespoke Suit 從無到有重新打造專屬你的版型，並採純手工製成。
                <br />
                <br />
                由於是全訂製，在費用上也會較高 ，但品質也是最頂尖的選擇。
                <br />
                <br />
                • 溝通樣式 | 使用場合 | 挑選面料 <br />
                • 樣式 : 領型 | 配釦 | 開叉 | 單排釦 | 雙排釦
                <br />
                • 挑選布料 | 西裝布料 | 內里布料
                <br />
                • 頂級全毛襯設計 | 純棉舒適墊肩
                <br />
                • 手工量身 判斷身型及調整尺寸
                <br />
                • 毛胚衣製成 Bespoke最重要的環節
                <br />
                • 毛胚衣試穿調整 <br />
                • 成品製成試穿調整 共需試穿2-3次
                <br />• 工作天 : 30 - 45 天{' '}
              </p>
              <p className="btn">
                <a
                  className="ana-button-click"
                  href=""
                  target="_top"
                  data-vars-event-category="預約訂製"
                  data-vars-event-label=""
                >
                  預約訂製
                </a>
              </p>
            </div>
          </div>
        </section>
        <section
          id="goto192767"
          className="section-base contact_us background-setting bg_translucent_k"
          style={{
            backgroundImage:
              'url(https://img.holkee.com/site/upload/5e8c3737-8236ap0ab-eef4-a35d6839/2b0c1ec08b12d8cab438be1a62370199_double.jpg)',
          }}
        >
          <div className="ContentsBox  clearfix">
            <div className="aBox">
              <div className="mapBox">
                <iframe
                  width="600"
                  height="450"
                  src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDXtBW26A1ECFElryP7Qar7EPx78d3ptV0&q=%E5%8F%B0%E5%8C%97%E5%B8%82%E4%BF%A1%E7%BE%A9%E8%B7%AF%E4%BA%94%E6%AE%B57%E8%99%9F#amp=1"
                  title="map"
                />
              </div>
            </div>
            <div className="aBox">
              <h3
                className="main_title"
                style={{ textAlign: 'center', fontSize: '1.75rem' }}
              >
                來店諮詢
              </h3>
              <p
                className="sub_title"
                style={{ textAlign: 'center', fontSize: '0.9375rem' }}
              >
                歡迎各位 預約前來體驗
                <br />
                讓我們幫你打造屬於你的紳士西裝
              </p>
              <ul className="info">
                <li>
                  <p className="address">台北市信義路五段7號</p>
                </li>
                <li>
                  <p className="phone">
                    <a
                      className="ana-button-click"
                      href="tel:0908-000-1234"
                      data-vars-event-category="contact"
                      data-vars-event-label="0908-000-1234"
                    >
                      0908-000-1234
                    </a>
                  </p>
                </li>
                <li>
                  <p className="time">10:00 AM - 11:30 PM</p>
                </li>
                <li>
                  <p className="email">
                    <a
                      className="ana-button-click"
                      href="mailto:service@example.com"
                      data-vars-event-category="contact"
                      data-vars-event-label="service@example.com"
                    >
                      service@example.com
                    </a>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section
          id="goto192768"
          className="section-base follow_us background-setting"
          style={{
            backgroundImage:
              'url(https://img.holkee.com/site/upload/5e8c3737-8236ap0ab-eef4-a35d6839/aa3198ba59d38d73c36f160476e6fdb8_double.jpg)',
          }}
        >
          <div className="ContentsBox ">
            <h3
              className="main_title"
              style={{
                textAlign: 'center',
                fontSize: '1.75rem',
                color: '#FFFFFF',
              }}
            >
              追蹤我們
            </h3>
            <p
              className="sub_title"
              style={{
                textAlign: 'center',
                fontSize: '0.9375rem',
                color: '#FFFFFF',
              }}
            >
              追蹤我們，掌握第一手消息與最新優惠折扣喔。
            </p>
            <ul className="follow_us_icon">
              <li>
                <a
                  className="ana-social-click "
                  href="https://www.facebook.com/holkeego/"
                  target="_blank"
                  data-vars-event-category="social"
                  data-vars-event-label="Facebook"
                >
                  <i className="fab fa-facebook-square" />
                </a>
              </li>
              <li>
                <a
                  className="ana-social-click "
                  href="https://www.facebook.com/holkeego/"
                  target="_blank"
                  data-vars-event-category="social"
                  data-vars-event-label="Instagram"
                >
                  <i className="fab fa-instagram" />
                </a>
              </li>
              <li>
                <a
                  className="ana-social-click "
                  href="https://line.me/R/ti/p/@bca1493j"
                  target="_blank"
                  data-vars-event-category="social"
                  data-vars-event-label="LINE"
                >
                  <i className="fab fa-line" />
                </a>
              </li>
              <li>
                <a
                  className="ana-social-click "
                  href="https://www.facebook.com/holkeego/"
                  target="_blank"
                  data-vars-event-category="social"
                  data-vars-event-label="Twitter"
                >
                  <i className="fab fa-twitter" />
                </a>
              </li>
              <li>
                <a
                  className="ana-social-click "
                  href="https://www.youtube.com/channel/UCWdDQAD5syDC1nYlhMOVeMA"
                  target="_blank"
                  data-vars-event-category="social"
                  data-vars-event-label="YouTube"
                >
                  <i className="fab fa-youtube" />
                </a>
              </li>
            </ul>
          </div>
        </section>
        <footer className="FooterBox">
          <div className="ContentsBox clearfix">
            <ul className="share-tools">
              <li>
                <div
                  className="ana-share-click i-amphtml-element i-amphtml-layout-fixed i-amphtml-layout-size-defined amp-social-share-facebook i-amphtml-built i-amphtml-layout"
                  type="facebook"
                  data-param-app_id="1864999493718715"
                  width="75"
                  height="24"
                  data-vars-event-category="share"
                  data-vars-event-label="Facebook"
                  i-amphtml-layout="fixed"
                  style={{ width: '75px', height: '24px' }}
                  role="button"
                  tabIndex="0"
                  aria-label="Share by facebook"
                >
                  分享
                </div>
              </li>
              <li>
                <div
                  className="ana-share-click i-amphtml-element i-amphtml-layout-fixed i-amphtml-layout-size-defined amp-social-share-line i-amphtml-built i-amphtml-layout"
                  type="line"
                  width="75"
                  height="24"
                  data-vars-event-category="share"
                  data-vars-event-label="LINE"
                  i-amphtml-layout="fixed"
                  style={{ width: '75px', height: '24px' }}
                  role="button"
                  tabIndex="0"
                  aria-label="Share by line"
                >
                  分享
                </div>
              </li>
              <li>
                <div
                  className="ana-share-click i-amphtml-element i-amphtml-layout-fixed i-amphtml-layout-size-defined amp-social-share-twitter i-amphtml-built i-amphtml-layout"
                  type="twitter"
                  width="75"
                  height="24"
                  data-vars-event-category="share"
                  data-vars-event-label="Twitter"
                  i-amphtml-layout="fixed"
                  style={{ width: '75px', height: '24px' }}
                  role="button"
                  tabIndex="0"
                  aria-label="Share by twitter"
                >
                  分享
                </div>
              </li>
            </ul>
            <p
              className="copyrights"
              style={{
                textAlign: 'center',
                fontSize: '0.8125rem',
                color: '#000000',
              }}
            >
              Copyrights All Rights Reserved.
            </p>
          </div>
        </footer>
        <ModalGateway>
          {isOpen ? (
            <Modal onClose={this.handleClose}>
              <img
                src="https://img.holkee.com/site/upload/5f927880-1818ap0ab-0ee3-9284e4fc/59dc4041abc7b57f5e7d9587bd8d89d4_double.jpg"
                alt=""
              />
            </Modal>
          ) : null}
        </ModalGateway>
      </>
    );
  }
}

// const mapStateToProps = (state) => {
//   return {
//     auth: state.firebase.auth
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     createProject: (project) => dispatch(createProject(project))
//   }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(HostIndex)
export default StartUp4;
