import React, { Component } from 'react';
import { Container, Header, Form, Icon, Button } from 'semantic-ui-react';
import { Modal } from 'rsuite';
export class ModalEditShare extends Component {
  constructor(props) {
    super(props);
    //    console.log(props);
    this.state = {
      show: false,
      imagePreviewUrlArr: [],
      photo: '',
      form: {
        facebook: false,
        line: false,
        linkedin: false,
        pinterest: false,
        telegram: false,
        tumblr: false,
        twitter: false,
        whatsapp: false,
      },
    };
  }

  closeEdit = () => {
    this.setState({ show: false, form: {} });
  };

  onEdit = () => {
    this.setState({ show: true });
  };

  handleChange = id => {
    const { form } = this.state;
    const input = document.getElementById(id);
    this.setState({
      form: { ...form, [id]: (input && input.checked) || false },
    });
  };

  render() {
    const { handleShare, id } = this.props;
    const { show, form } = this.state;
    return (<>
      <a className="j-pop-image" onClick={this.onEdit}>
        編輯
      </a>
      <Modal open={show} onClose={this.closeEdit} size="lg">
        <Modal.Header>
          <Modal.Title>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="white-popup" id="popup-sharesetting">
            <form className="setBox" data-parsley-required-message="尚未填寫">
              <div className="top_title">
                <h3>分享設定</h3>
                <p>讓訪客輕鬆快速分享您的網站</p>
              </div>
              <div className="fillinBox">
                <ul className="shareBox_list clearfix" style={{ display: 'block' }}   >
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        name="share"
                        id="facebook"
                        value="facebook"
                        onChange={() => this.handleChange('facebook')}
                      />
                      <p className="facebook">
                        <i className="fab fa-facebook-square" />
                        <span>Facebook</span>
                      </p>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        name="share"
                        id="line"
                        value="line"
                        onChange={() => this.handleChange('line')}
                      />
                      <p className="line">
                        <i className="fab fa-line" />
                        <span>LINE</span>
                      </p>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        name="share"
                        id="linkedin"
                        value="linkedin"
                        onChange={() => this.handleChange('linkedin')}
                      />
                      <p className="linkedin">
                        <i className="fab fa-linkedin" />
                        <span>LinkedIn</span>
                      </p>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        name="share"
                        id="pinterest"
                        value="pinterest"
                        onChange={() => this.handleChange('pinterest')}
                      />
                      <p className="pinterest">
                        <i className="fab fa-pinterest" />
                        <span>Pinterest</span>
                      </p>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        name="share"
                        id="telegram"
                        value="telegram"
                        onChange={() => this.handleChange('telegram')}
                      />
                      <p className="telegram">
                        <i className="fab fa-telegram-plane" />
                        <span>Telegram</span>
                      </p>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        name="share"
                        id="tumblr"
                        value="tumblr"
                        onChange={() => this.handleChange('tumblr')}
                      />
                      <p className="tumblr">
                        <i className="fab fa-tumblr-square" />
                        <span>Tumblr</span>
                      </p>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        name="share"
                        id="twitter"
                        value="twitter"
                        onChange={() => this.handleChange('twitter')}
                      />
                      <p className="twitter">
                        <i className="fab fa-twitter" />
                        <span>Twitter</span>
                      </p>
                    </label>
                  </li>
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        name="share"
                        id="whatsapp"
                        value="whatsapp"
                        onChange={() => this.handleChange('whatsapp')}
                      />
                      <p className="whatsapp">
                        <i className="fab fa-whatsapp" />
                        <span>WhatsApp</span>
                      </p>
                    </label>
                  </li>
                </ul>
              </div>
            </form>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ backgroundColor: '#27d0ca', color: '#fff' }}
            content="套用"
            onClick={() => {
              handleShare(form);
              this.closeEdit();
            }}
          />
        </Modal.Footer>
      </Modal>
    </>);
  }
}

export default ModalEditShare;
