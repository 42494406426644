import React, { Component } from 'react';
import AliceCarousel from 'react-alice-carousel';
import Carousel, { ModalGateway, Modal } from 'react-images';
import { Container } from 'semantic-ui-react'
import { Button, FormGroup, Row, Col, Card, } from "reactstrap";
// import 'react-alice-carousel/lib/alice-carousel.css';
import Parse from 'widget/parse'
import { ReactSmartScroller } from 'react-smart-scroller'

const imgStyle = {
  margin: 0,
  padding: 0,
  verticalAlign: 'middle',
  border: 0,
  maxWidth: '100%',
  height: 'auto'
}


export class SectionYearPlan extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedIndex: 0,
      lightboxIsOpen: false,
      photos: [],
      album: [],
      rowData: {},
      isOpen: false,
      month: '01',
      isPress4: false,
      isPress5: false,
      isPress6: false,
      isPress7: false,
      isPress8: false,
      isPress9: false,
      isPress10: false,
      isPress11: false,
      isPress12: false,
      isPress1: true,
      isPress2: false,
      isPress3: false,
    };
  }

  componentDidMount = async () => {
    const { companyObj } = this.props
    const companyId = companyObj.objectId
    const photoData = await Parse.queryData('webimage', { companyId, albumId: 'SSGeWjVZsT' })
    this.setState({ photoData })
  }


  monthClick = (month, press) => {
    this.setState({
      month,
      isPress1: false,
      isPress2: false,
      isPress3: false,
      isPress4: false,
      isPress5: false,
      isPress6: false,
      isPress7: false,
      isPress8: false,
      isPress9: false,
      isPress10: false,
      isPress11: false,
      isPress12: false,
      [press]: true,
    })
  }

  renderImagesNew = (month) => {
    const { photoData } = this.state

    const imagesNew = photoData && photoData.map(item => ({
      img: item.img1File2.url,
      title: item.name.substr(0, 2)
    }))

    return imagesNew && imagesNew.filter(item => item.title === month).map((item, index) => (
      <>
        <img
          key={index}
          src={item.img}
          style={{
            width: '100%',
            height: 300,
            objectFit: 'cover'
          }}
        />
      </>
    ))

  }

  render() {
    // const { form, cookies, webimageArr } = this.props;
    // const { text2 = '標題', text3 = '', img1File = {} } = form;
    // const dbLang = cookies.get('dbLang') || 'name';
    // const arr = webimageArr.filter(item => item.navId === form.objectId)
    const { album, isOpen, photos, field = 'img1File2', rowData, month,
      isPress4 = false,
      isPress5 = false,
      isPress6 = false,
      isPress7 = false,
      isPress8 = false,
      isPress9 = false,
      isPress10 = false,
      isPress11 = false,
      isPress12 = false,
      isPress1 = false,
      isPress2 = false,
      isPress3 = false, } = this.state

    const cardStyle = {
      cursor: 'pointer',
      width: '7%',
      border: 'none',
      color: '#8E8E8E',
      fontSize: '1rem'
    }
    const btnColor4 = isPress4 ? '#c1c3c0' : '#f1f2f4'
    const btnColor5 = isPress5 ? '#c1c3c0' : '#f1f2f4'
    const btnColor6 = isPress6 ? '#c1c3c0' : '#f1f2f4'
    const btnColor7 = isPress7 ? '#c1c3c0' : '#f1f2f4'
    const btnColor8 = isPress8 ? '#c1c3c0' : '#f1f2f4'
    const btnColor9 = isPress9 ? '#c1c3c0' : '#f1f2f4'
    const btnColor10 = isPress10 ? '#c1c3c0' : '#f1f2f4'
    const btnColor11 = isPress11 ? '#c1c3c0' : '#f1f2f4'
    const btnColor12 = isPress12 ? '#c1c3c0' : '#f1f2f4'
    const btnColor1 = isPress1 ? '#c1c3c0' : '#f1f2f4'
    const btnColor2 = isPress2 ? '#c1c3c0' : '#f1f2f4'
    const btnColor3 = isPress3 ? '#c1c3c0' : '#f1f2f4'
    return (

      <>
        <br />
        <br />
        <br />
        <br />
        <br />
        {/* <Container style={{ margin: '100px 0px' }}>
          <h3 className="h3com01 mb-35">豊富なイベントで学内交流</h3>
          <p className="pb-60 text-content">
            1年を通して、さまざまなイベントが行われることも本校の魅力のひとつ。教員や周りの学生との親睦を深め、充実した学生生活を楽しんでください。
          </p>
          {month === '1月' ? <>
            <h3 className="h3com03">
              <span className="text01">1月</span>
              <span className="text02">冬季休暇</span>
            </h3>
          </> : month === '2月' ? <>
            <h3 className="h3com03">
              <span className="text01">2月</span>
              <span className="text02">期末試験・大学入試</span>
            </h3>
          </> : month === '3月' ? <>
            <h3 className="h3com03">
              <span className="text01">3月</span>
              <span className="text02">卒業式・春期休暇</span>
            </h3>
          </> : month === '4月' ? <>
            <h3 className="h3com03">
              <span className="text01">4月</span>
              <span className="text02">入学オリエンテーション</span>
            </h3>
          </> : month === '5月' ? <>
            <h3 className="h3com03">
              <span className="text01">5月</span>
              <span className="text02">ゴールデンウィーク</span>
            </h3>
          </> : month === '6月' ? <>
            <h3 className="h3com03">
              <span className="text01">6月</span>
              <span className="text02">定期試験・春見学旅行日本留学試験(EJU)</span>
            </h3>
          </> : month === '7月' ? <>
            <h3 className="h3com03">
              <span className="text01">7月</span>
              <span className="text02">大学見学・夏期休暇   日本語能力試験(JLPT)</span>
            </h3>
          </> : month === '8月' ? <>
            <h3 className="h3com03">
              <span className="text01">8月</span>
              <span className="text02">夏期休暇・短期研修(JLPT)</span>
            </h3>
          </> : month === '9月' ? <>
            <h3 className="h3com03">
              <span className="text01">9月</span>
              <span className="text02">定期試験</span>
            </h3>
          </> : month === '10月' ? <>
            <h3 className="h3com03">
              <span className="text01">10月</span>
              <span className="text02">入学オリエンテーション</span>
            </h3>
          </> : month === '11月' ? <>
            <h3 className="h3com03">
              <span className="text01">11月</span>
              <span className="text02">秋見学旅行日本留学試験(EJU)</span>
            </h3>
          </> : month === '12月' ? <>
            <h3 className="h3com03">
              <span className="text01">12月</span>
              <span className="text02">日本語能力試験(JLPT)</span>
            </h3>
          </> : null}
        </Container> */}
        <Container style={{ width: "50%" }}>
          <div>
            <Row style={{ textAlign: "center", display: "flex", justifyContent: "center", }}>
              <Card style={{ ...cardStyle, backgroundColor: btnColor4 }} onClick={() => this.monthClick('04', 'isPress4')}>4月</Card>&nbsp;
              <Card style={{ ...cardStyle, backgroundColor: btnColor5 }} onClick={() => this.monthClick('05', 'isPress5')}>5月</Card>&nbsp;
              <Card style={{ ...cardStyle, backgroundColor: btnColor6 }} onClick={() => this.monthClick('06', 'isPress6')}>6月</Card>&nbsp;
              <Card style={{ ...cardStyle, backgroundColor: btnColor7 }} onClick={() => this.monthClick('07', 'isPress7')}>7月</Card>&nbsp;
              <Card style={{ ...cardStyle, backgroundColor: btnColor8 }} onClick={() => this.monthClick('08', 'isPress8')}>8月</Card>&nbsp;
              <Card style={{ ...cardStyle, backgroundColor: btnColor9 }} onClick={() => this.monthClick('09', 'isPress9')}>9月</Card>&nbsp;
              <Card style={{ ...cardStyle, backgroundColor: btnColor10 }} onClick={() => this.monthClick('10', 'isPress10')}>10月</Card>&nbsp;
              <Card style={{ ...cardStyle, backgroundColor: btnColor11 }} onClick={() => this.monthClick('11', 'isPress11')}>11月</Card>&nbsp;
              <Card style={{ ...cardStyle, backgroundColor: btnColor12 }} onClick={() => this.monthClick('12', 'isPress12')}>12月</Card>&nbsp;
              <Card style={{ ...cardStyle, backgroundColor: btnColor1 }} onClick={() => this.monthClick('01', 'isPress1')}>1月</Card>&nbsp;
              <Card style={{ ...cardStyle, backgroundColor: btnColor2 }} onClick={() => this.monthClick('02', 'isPress2')}>2月</Card>&nbsp;
              <Card style={{ ...cardStyle, backgroundColor: btnColor3 }} onClick={() => this.monthClick('03', 'isPress3')}>3月</Card>
            </Row>
          </div>
        </Container>
        <br />
        <Container style={{ width: "50%" }}>
          <ReactSmartScroller spacing={5}>{this.renderImagesNew(month)}</ReactSmartScroller>
        </Container>
        {/* <Container style={{ margin: '100px 0px' }}>display: "flex",justifyContent: "center", alignItems: "center"
          <br />
          <br />
          <div className="contentWrap">
            <h3 className="h3com01 mb-35">豊富なイベントで学内交流</h3>
            <p className="pb-60 text-content">
              1年を通して、さまざまなイベントが行われることも本校の魅力のひとつ。教員や周りの学生との親睦を深め、充実した学生生活を楽しんでください。
            </p>

            <h3 className="h3com03">
              <span className="text01">4月</span>
              <span className="text02">入学オリエンテーション</span>
            </h3>

            <h3 className="h3com03">
              <span className="text01">5月</span>
              <span className="text02">ゴールデンウィーク</span>
            </h3>

            <h3 className="h3com03">
              <span className="text01">6月</span>
              <span className="text02">定期試験・春見学旅行日本留学試験(EJU)</span>
            </h3>

            <h3 className="h3com03">
              <span className="text01">7月</span>
              <span className="text02">大学見学・夏期休暇   日本語能力試験(JLPT)</span>
            </h3>

            <h3 className="h3com03">
              <span className="text01">8月</span>
              <span className="text02">夏期休暇・短期研修(JLPT)</span>
            </h3>

            <h3 className="h3com03">
              <span className="text01">9月</span>
              <span className="text02">定期試験</span>
            </h3>

            <h3 className="h3com03">
              <span className="text01">10月</span>
              <span className="text02">入学オリエンテーション</span>
            </h3>

            <h3 className="h3com03">
              <span className="text01">11月</span>
              <span className="text02">秋見学旅行日本留学試験(EJU)</span>
            </h3>

            <h3 className="h3com03">
              <span className="text01">12月</span>
              <span className="text02">日本語能力試験(JLPT)</span>
            </h3>

            <h3 className="h3com03">
              <span className="text01">1月</span>
              <span className="text02">冬季休暇</span>
            </h3>

            <h3 className="h3com03">
              <span className="text01">2月</span>
              <span className="text02">期末試験・大学入試</span>
            </h3>
            <h3 className="h3com03">
              <span className="text01">3月</span>
              <span className="text02">卒業式・春期休暇</span>
            </h3>
          </div>

        </Container> */}
      </>
    );
  }
}
export default SectionYearPlan;
