import React, { Component } from "react";
import { Modal } from 'rsuite';

// reactstrap components
import {
  Button,
  Card,
  CardTitle,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
const style = {
  borderStyle: 'solid',
  borderColor: '#d3d3d3',
  borderWidth: '1px',
  fontSize: '18px',
  textAlign: 'center'
}

const style2 = {
  borderStyle: 'solid',
  borderColor: '#d3d3d3',
  borderWidth: '1px',
  fontSize: '18px',
  textAlign: 'left'
}

const titleStyle = {
  borderStyle: 'solid', borderColor: '#d3d3d3',
  borderWidth: '1px', fontSize: '18px', textAlign: 'left'
}
// function SectionFeature3(props = {}) {
export class SectionFeature3 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openModal: false,
      num: 1,
      isPress1: true,
      isPress3: false,
      isPress4: false,
    };
  }
  componentDidMount = async () => {
    // const { companyObj } = this.props
    // // const companyId = companyObj.objectId
    // const data = await Parse.queryData('album', { companyId: 'aKDM0xR8py', isopen: '1' }, { sort: 'date_descend' });
    // this.setState({ album: data })
  }

  handleData = (num, press) => {
    this.setState({ num, isPress1: false, isPress3: false, isPress4: false, [press]: true })
  }

  render() {
    const { form, cookies, webimageArr = [], imagePreviewUrl } = this.props;
    const { num, isPress1 = false, isPress3 = false, isPress4 = false } = this.state
    const buttonColor1 = isPress1 ? '#c1c3c0' : '#f1f2f4'
    const buttonColor3 = isPress3 ? '#c1c3c0' : '#f1f2f4'
    const buttonColor4 = isPress4 ? '#c1c3c0' : '#f1f2f4'
    // console.log(form)
    const { text2 = '', text3 = '', img1File } = form;
    const dbLang = cookies.get('dbLang') || 'name';
    let arr = []
    arr = webimageArr.filter(item => item.navId === form.objectId)
    if (!arr.length) {
      arr = [{ icon: "nc-app", title1name: "標題", content1name: '內文' },
      { icon: "nc-app", title1name: "標題", content1name: '內文' },
      { icon: "nc-app", title1name: "標題", content1name: '內文' },
      { icon: "nc-app", title1name: "標題", content1name: '內文' }]
    }
    const arr2 = [];

    for (let i = 0; i < arr.length; i += 2) {
      const obj = {
        '1': arr[i],
        '2': i + 1 < arr.length ? arr[i + 1] : {},
      }
      arr2.push(obj);
    }
    // console.log("arr2:", arr2)
    const picUrl = (img1File && img1File.url) || imagePreviewUrl;
    return (
      <>
        {/* ********* FEATURES 3 ********* */}
        <div className="features-3">
          <Container>
            <Row>
              <Col md='4' xs='4'>
                <Card style={{ cursor: 'pointer' }} onClick={() => this.handleData(1, 'isPress1')}>
                  <CardBody style={{
                    backgroundColor: buttonColor1,
                    opacity: 0.9
                  }}>
                    {/* &emsp;進学２年コース、進学1年6ヶ月コース */}
                    {/* <h5 className="card-category" style={{ textAlign: 'center', color: '#66615B', fontSize: '1rem', fontWeight: 600 }}>
                      <i className='nc-icon nc-box' style={{ height: '30px', width: '30px', padding: '10px', color: '#65899e' }} />
                      {arr[2]['title1' + dbLang] || ''}
                    </h5> */}
                    <div style={{ display: 'flex' }}>
                      <i className='nc-icon nc-box' style={{ height: '30px', width: '30px', padding: '8px', color: '#65899e' }} />
                      <h5 dangerouslySetInnerHTML={{ __html: `<p style="text-align: center; color: #66615B; font-size: 20px;">${arr[2]['title1' + dbLang] || ''}<\p>` }}></h5>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md='4' xs='4'>
                <Card style={{ cursor: 'pointer' }} onClick={() => this.handleData(3, 'isPress3')}>
                  <CardBody style={{
                    backgroundColor: buttonColor3,
                    opacity: 0.9
                  }}>
                    {/* &emsp;Wスクールコース（4月入学) */}
                    {/* <h5 className="card-category" style={{ textAlign: 'center', color: '#66615B', fontSize: '1rem', fontWeight: 600 }}>
                      <i className='nc-icon nc-box' style={{ height: '30px', width: '30px', padding: '8px', color: '#9e6589' }} />
                      {arr[0]['title1' + dbLang] || ''}
                    </h5> */}
                    <div style={{ display: 'flex' }}>
                      <i className='nc-icon nc-box' style={{ height: '30px', width: '30px', padding: '8px', color: '#9e6589' }} />
                      <h5 dangerouslySetInnerHTML={{ __html: `<p style="text-align: center; color: #66615B; font-size: 20px;">${arr[0]['title1' + dbLang] || ''}<\p>` }}></h5>
                    </div>
                    {/* &emsp;Wスクールコース（4月入学) */}
                    {/* <h5 className="card-category" style={{ textAlign: 'center', color: '#66615B', fontSize: '1rem', fontWeight: 600 }}>
                      <i className='nc-icon nc-box' style={{ height: '30px', width: '30px', padding: '8px', color: '#9e6589' }} />
                      {arr[0]['title1' + dbLang] || ''}
                    </h5> */}
                    {/* <i className='nc-icon nc-box' style={{ height: '30px', width: '30px', padding: '8px', color: '#9e6589' }} />
                    <h5 dangerouslySetInnerHTML={{ __html: `<p style="text-align: center; color: #66615B; font-size: 29px; font-weight: 600;">${arr[0]['title1' + dbLang] || ''}<\p>` }}></h5> */}
                  </CardBody>
                </Card>
              </Col>
              <Col md='4' xs='4'>
                <Card style={{ cursor: 'pointer' }} onClick={() => this.handleData(4, 'isPress4')}>
                  <CardBody style={{
                    backgroundColor: buttonColor4,
                    opacity: 0.9
                  }}>
                    {/* &emsp;短期遊学コース（随時受入 */}
                    {/* <h5 className="card-category" style={{ textAlign: 'center', color: '#66615B', fontSize: '1rem', fontWeight: 600 }}>
                      <i className='nc-icon nc-box' style={{ height: '30px', width: '30px', padding: '8px', color: '#899e65' }} />
                      {arr[1]['title1' + dbLang] || ''}
                    </h5> */}
                    <div style={{ display: 'flex' }}>
                      <i className='nc-icon nc-box' style={{ height: '30px', width: '30px', padding: '8px', color: '#899e65' }} />
                      <h5 dangerouslySetInnerHTML={{ __html: `<p style="text-align: center; color: #66615B; font-size: 20px;">${arr[1]['title1' + dbLang] || ''}<\p>` }}></h5>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              {num === 1 ? <>
                <div style={{ display: 'flex' }}>
                  <div>
                    {/* <h2 style={{ color: '#65899e' }}>{arr[2]['title1' + dbLang] || ''}</h2> */}
                    <h2 dangerouslySetInnerHTML={{ __html: `<p style=" color: #65899e; font-size: 30px;">${arr[2]['title1' + dbLang] || ''}<\p>` }}></h2>
                    <div dangerouslySetInnerHTML={{ __html: `<p style=" color: #65899e; font-size: 20px;">${arr && arr[2] && arr[2]['content1' + dbLang] || ''}<\p>` }} />
                  </div>
                </div></> : num === 3 ? <>
                  <div style={{ display: 'flex' }}>
                    <div>
                      {/* <h2 style={{ color: '#9e6589' }}>{arr[0]['title1' + dbLang] || ''}</h2> */}
                      <h2 dangerouslySetInnerHTML={{ __html: `<p style=" color: #9e6589; font-size: 30px;">${arr[0]['title1' + dbLang] || ''}<\p>` }}></h2>
                      <div dangerouslySetInnerHTML={{ __html: `<p style=" color: #9e6589; font-size: 20px;">${arr && arr[0] && arr[0]['content1' + dbLang] || ''}<\p>` }} />
                    </div>
                  </div>
                </> : num === 4 ? <>
                  <div style={{ display: 'flex' }}>
                    <div >
                      {/* <h2 style={{ color: '#899e65' }}>{arr[1]['title1' + dbLang] || ''}</h2> */}
                      <h2 dangerouslySetInnerHTML={{ __html: `<p style=" color: #899e65; font-size: 30px;">${arr[1]['title1' + dbLang] || ''}<\p>` }}></h2>
                      <div dangerouslySetInnerHTML={{ __html: `<p style=" color: #9e6589; font-size: 20px;">${arr && arr[1] && arr[1]['content1' + dbLang] || ''}<\p>` }} />
                    </div>
                  </div>
                </> : null}
            </Row>
          </Container>
        </div>
        {/* ********* END FEATURES 3 ********* */}
      </>
    );
  }
}

export default SectionFeature3;
