import React from 'react';

// reactstrap components
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from 'reactstrap';

// core components

function SectionTestimonials3(props = {}) {
  // carousel - TESTIMONIALS 2
  const { form } = props;
  //  console.log(form)
  const {
    text2 = 'text2',
    text3 = 'text3',
    text4 = 'text4',
    text5 = 'text5',
    text6 = 'text6',
    text7 = 'text7',
    text8 = 'text8',
    text9 = 'text9',
    text10 = 'text10',
    text11 = 'text11',
    text12 = 'text12',
    text13 = 'text13',
  } = form;
  return (
    <>
      <div
        className="section section-testimonials cd-section"
        id="testimonials"
      >
        {/* ********* TESTIMONIALS 3 ********* */}
        <div className="testimonials-3">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                <h2 className="title" />
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto" md="3">
                <Card data-background="color" data-color="orange">
                  <CardBody>
                    <div className="author">
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        <img
                          alt="..."
                          className="avatar img-raised mr-2"
                          src={require('assets/img/faces/joe-gardner-2.jpg')}
                        />
                        <span>{text3}</span>
                      </a>
                    </div>
                    <span className="category-social pull-right">
                      <i className="fa fa-quote-right" />
                    </span>
                    <div className="clearfix" />
                    <p className="card-description">{text4}</p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card data-background="color" data-color="green">
                  <CardBody>
                    <div className="author">
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        <img
                          alt="..."
                          className="avatar img-raised mr-2"
                          src={require('assets/img/faces/erik-lucatero-2.jpg')}
                        />
                        <span>{text5}</span>
                      </a>
                    </div>
                    <span className="category-social pull-right">
                      <i className="fa fa-quote-right" />
                    </span>
                    <div className="clearfix" />
                    <p className="card-description">{text6}</p>
                  </CardBody>
                </Card>
              </Col>
              <Col className="mr-auto" md="3">
                <Card data-background="color" data-color="yellow">
                  <CardBody>
                    <div className="author">
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        <img
                          alt="..."
                          className="avatar img-raised mr-2"
                          src={require('assets/img/faces/kaci-baum-2.jpg')}
                        />
                        <span>{text7}</span>
                      </a>
                    </div>
                    <span className="category-social pull-right">
                      <i className="fa fa-quote-right" />
                    </span>
                    <div className="clearfix" />
                    <p className="card-description">{text8}</p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto" md="4">
                <Card data-background="color" data-color="blue">
                  <CardBody>
                    <div className="author">
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        <img
                          alt="..."
                          className="avatar img-raised mr-2"
                          src={require('assets/img/faces/clem-onojeghuo-2.jpg')}
                        />
                        <span>{text9}</span>
                      </a>
                    </div>
                    <span className="category-social pull-right">
                      <i className="fa fa-quote-right" />
                    </span>
                    <div className="clearfix" />
                    <p className="card-description">{text10}</p>
                  </CardBody>
                </Card>
              </Col>
              <Col className="mr-auto" md="6">
                <Card data-background="color" data-color="purple">
                  <CardBody>
                    <div className="author">
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        <img
                          alt="..."
                          className="avatar img-raised"
                          src={require('assets/img/faces/ayo-ogunseinde-2.jpg')}
                        />
                        <span>{text11}</span>
                      </a>
                    </div>
                    <span className="category-social pull-right">
                      <i className="fa fa-quote-right" />
                    </span>
                    <div className="clearfix" />
                    <p className="card-description">{text12}</p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        {/* ********* END TESTIMONIALS 3 ********* */}
      </div>
    </>
  );
}

export default SectionTestimonials3;
