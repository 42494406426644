import React, { Component } from 'react';
// import { connect } from 'react-redux'
// import { createProject } from '../../store/actions/projectActions'
// import { Redirect } from 'react-router-dom'
import { Container, Header, Form, Icon, Grid, Image } from 'semantic-ui-react';
import { ModalGateway, Modal } from 'react-images';
import ColorNavbar from 'components/Navbars/ColorNavbar.js';
import FooterBlack from 'components/Footers/FooterBlack.js';
import PageHeader from 'components/Headers/PageHeader';
import { Col, Row, CardImg } from 'reactstrap';
import Parse from 'widget/parse';
// import './css/startup2.css'
export class StartUp2 extends Component {
  constructor(props) {
    super(props);
    //    console.log(props);
    this.state = {
      isOpen: false,
    };
  }

  handleChange = (e, { value }) => this.setState({ value });

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  handleOpen = () => {
    this.setState({ isOpen: true });
  };
  //   handleChange = (e) => {
  //     // console.log(e);
  //     this.setState({
  //       [e.target.id]: e.target.value
  //     })
  //   }

  //   handleSubmit = (e) => {
  //     // console.log(e);
  //     e.preventDefault();
  //     this.props.createProject(this.state);
  //     this.props.history.push('/');
  //   }
  render() {
    const { isOpen } = this.state;
    //     const { auth } = this.props;
    //     if (!auth.uid) return <Redirect to='/signin' />
    // const { value } = this.state
    return (
      <>
        {/* <Container style={content}> */}
        <section
          className="section-base mainBox background-setting"
          style={{
            backgroundImage:
              'url(https://img.holkee.com/site/upload/5eeb0f4e-3322ap0ab-a916-9d40c74e/9bf06f542e65626e04e8399e6f46f011_double.jpg)',
          }}
        >
          <div className="ContentsBox style-one">
            <div className="imgBox">
              <img
                className="i-amphtml-element i-amphtml-layout-intrinsic i-amphtml-layout-size-defined i-amphtml-built i-amphtml-layout"
                src="https://img.holkee.com/site/upload/5eeb0f4e-3322ap0ab-a916-9d40c74e/1a194b797844807882db244aa1d854da_double.png"
                alt=""
              />
            </div>
            <p
              className="sub_title"
              style={{
                textAlign: 'center',
                fontSize: '1.1875rem',
                color: '#FFFFFF',
              }}
            >
              Enjoy life
            </p>
            <div className="aBox" />
          </div>
        </section>
        <section
          className="section-base about_us_box background-setting"
          style={{
            backgroundImage:
              'url(https://img.holkee.com/site/upload/5eeb0f4e-3322ap0ab-a916-9d40c74e/e38456b21cba313ec2ca973ca47656a7_double.jpg)',
          }}
        >
          <div className="ContentsBox default clearfix">
            <div className="aBox fl">
              <div className="imgBox">
                <img
                  src="https://img.holkee.com/site/upload/5eeb0f4e-3322ap0ab-a916-9d40c74e/d346aeb3d937e86f0c82a1c47ad1bf64_double.png"
                  alt=""
                />
              </div>
            </div>
            <div className="aBox fr">
              <h3
                className="main_title"
                style={{ textAlign: 'center', color: '#0077C6' }}
              >
                Who is Kelly Cat?
              </h3>
              <p className="sub_title">
                地區：奧地利 / 維也納
                <br />
                無人不知無人不曉的網路天后 - Kelly Cat
                <br />
                <br />
                現正就讀台大的Kelly
                Cat，當初因為喜愛台灣偶像劇、想研究蝴蝶生態所以來到這座島嶼，有想法、敢評論的她，不但建立起個人的影音平台，更用行動向世人證明，她真的很愛台灣。別看Kelly
                Cat生得一副外國人面孔，她骨子裡卻有一顆熱愛台灣的心。
              </p>
            </div>
          </div>
        </section>
        <section id="goto221867" className="carouselBox">
          <div className="ContentsBox">
            <div className="imgBox">
              <img
                src="https://img.holkee.com/site/upload/5eeb0f4e-3322ap0ab-a916-9d40c74e/0f4dfb239535f1c9fe97ab892834f3f5_double.jpg"
                alt=""
              />
            </div>
          </div>
        </section>
        <section
          id="goto221810"
          className="section-base slidesBox background-setting bg_translucent_k"
          style={{
            backgroundImage:
              'url(https://img.holkee.com/site/upload/5eeb0f4e-3322ap0ab-a916-9d40c74e/47784277f7e596101a7629d10e8dfce6_double.jpg)',
          }}
        >
          <div className="ContentsBox style-one">
            <div className="text-box">
              <h3 className="main_title">KOL Influence</h3>
            </div>
            <div className="slider-box i-amphtml-element i-amphtml-layout-fixed-height i-amphtml-layout-size-defined i-amphtml-built i-amphtml-layout">
              <div className="i-amphtml-carousel-content">
                <div className="i-amphtml-carousel-scroll">
                  <div className="content-box amp-carousel-slide amp-scrollable-carousel-slide">
                    <div className="photo">
                      <img
                        style={{ position: 'initial' }}
                        src="https://img.holkee.com/site/upload/5eeb0f4e-3322ap0ab-a916-9d40c74e/59ddf3901cc3ae496fd50b0c9563e0ad_double.jpg"
                        className="i-amphtml-fill-content i-amphtml-replaced-content"
                        alt=""
                      />
                    </div>
                    <div className="info">
                      <div className="description">
                        <p id="figure-221810-0">影音</p>
                      </div>
                      <div>
                        <p>
                          <a
                            className="ana-button-click btn"
                            href="https://www.holkee.com/"
                            target="_blank"
                            data-vars-event-category="看更多"
                            data-vars-event-label="https://www.holkee.com/"
                          >
                            看更多
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="content-box amp-carousel-slide amp-scrollable-carousel-slide">
                    <div className="photo">
                      <img
                        style={{ position: 'initial' }}
                        src="https://img.holkee.com/site/upload/5eeb0f4e-3322ap0ab-a916-9d40c74e/8b134900873b2459eaa4003c0c31400b_double.jpg"
                        className="i-amphtml-fill-content i-amphtml-replaced-content"
                        alt=""
                      />
                    </div>
                    <div className="info">
                      <div className="description">
                        <p id="figure-221810-1">Blog</p>
                      </div>
                      <div>
                        <p>
                          <a
                            className="ana-button-click btn"
                            href="https://www.holkee.com/"
                            target="_blank"
                            data-vars-event-category="看更多"
                            data-vars-event-label="https://www.holkee.com/"
                          >
                            看更多
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="content-box amp-carousel-slide amp-scrollable-carousel-slide">
                    <div className="photo">
                      <img
                        style={{ position: 'initial' }}
                        src="https://img.holkee.com/site/upload/5eeb0f4e-3322ap0ab-a916-9d40c74e/ec931e418ac4e0e0b8809d8a0f762cfd_double.jpg"
                        className="i-amphtml-fill-content i-amphtml-replaced-content"
                        alt=""
                      />
                    </div>
                    <div className="info">
                      <div className="description">
                        <p id="figure-221810-2">團購</p>
                      </div>
                      <div>
                        <p>
                          <a
                            className="ana-button-click btn"
                            href="https://www.holkee.com/"
                            target="_blank"
                            data-vars-event-category="看更多"
                            data-vars-event-label="https://www.holkee.com/"
                          >
                            看更多
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="content-box amp-carousel-slide amp-scrollable-carousel-slide">
                    <div className="photo">
                      <img
                        style={{ position: 'initial' }}
                        src="https://img.holkee.com/site/upload/5eeb0f4e-3322ap0ab-a916-9d40c74e/d80149c1550745c0e2c619afc2fba5da_double.jpg"
                        className="i-amphtml-fill-content i-amphtml-replaced-content"
                        alt=""
                      />
                    </div>
                    <div className="info">
                      <div className="description">
                        <p id="figure-221810-3">穿搭</p>
                      </div>
                      <div>
                        <p>
                          <a
                            className="ana-button-click btn"
                            href="https://www.holkee.com/"
                            target="_blank"
                            data-vars-event-category="看更多"
                            data-vars-event-label="https://www.holkee.com/"
                          >
                            看更多
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="content-box amp-carousel-slide amp-scrollable-carousel-slide">
                    <div className="photo">
                      <img
                        style={{ position: 'initial' }}
                        src="https://img.holkee.com/site/upload/5eeb0f4e-3322ap0ab-a916-9d40c74e/331192b9d6d0799acdb8d7be0342c52b_double.jpg"
                        className="i-amphtml-fill-content i-amphtml-replaced-content"
                        alt=""
                      />
                    </div>
                    <div className="info">
                      <div className="description">
                        <p id="figure-221810-4">攝影</p>
                      </div>
                      <div>
                        <p>
                          <a
                            className="ana-button-click btn"
                            href="https://www.holkee.com/"
                            target="_blank"
                            data-vars-event-category="看更多"
                            data-vars-event-label="https://www.holkee.com/"
                          >
                            看更多
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="content-box amp-carousel-slide amp-scrollable-carousel-slide">
                    <div className="photo">
                      <img
                        style={{ position: 'initial' }}
                        src="https://img.holkee.com/site/upload/5eeb0f4e-3322ap0ab-a916-9d40c74e/014d540d2c2afa4ffeae9652cb529b8b_double.jpg"
                        className="i-amphtml-fill-content i-amphtml-replaced-content"
                        alt=""
                      />
                    </div>
                    <div className="info">
                      <div className="description">
                        <p id="figure-221810-5">興趣</p>
                      </div>
                      <div>
                        <p>
                          <a
                            className="ana-button-click btn"
                            href="https://www.holkee.com/"
                            target="_blank"
                            data-vars-event-category="看更多"
                            data-vars-event-label="https://www.holkee.com/"
                          >
                            看更多
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="i-amphtml-carousel-arrows">
                  <div
                    tabIndex="0"
                    className="amp-carousel-button amp-carousel-button-prev amp-disabled"
                    role="presentation"
                    aria-disabled="true"
                    title="   "
                    dir="ltr"
                  />
                  <div
                    tabIndex="0"
                    className="amp-carousel-button amp-carousel-button-next amp-disabled"
                    role="presentation"
                    aria-disabled="true"
                    title="   "
                    dir="ltr"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          id="goto221735"
          className="section-base follow_us background-setting"
          style={{
            backgroundImage:
              'url(https://img.holkee.com/site/upload/5eeb0f4e-3322ap0ab-a916-9d40c74e/c1a05bea1a4df39e60c442a146dcfe35_double.jpg)',
          }}
        >
          <div className="ContentsBox style-three">
            <h3 className="main_title" style={{ color: '#0077C6' }}>
              Follow Kelly Cat
            </h3>
            <p className="sub_title">快來追蹤 Kelly Cat </p>
            <ul className="follow_us_icon">
              <li>
                <a
                  className="ana-social-click "
                  style={{ color: '#000000' }}
                  href="https://www.facebook.com/holkeego/"
                  target="_blank"
                  data-vars-event-category="social"
                  data-vars-event-label="Facebook"
                >
                  <i className="fab fa-facebook-square" />
                </a>
              </li>
              <li>
                <a
                  className="ana-social-click "
                  style={{ color: '#000000' }}
                  href="https://www.facebook.com/holkeego/"
                  target="_blank"
                  data-vars-event-category="social"
                  data-vars-event-label="Instagram"
                >
                  <i className="fab fa-instagram" />
                </a>
              </li>
              <li>
                <a
                  className="ana-social-click "
                  style={{ color: '#000000' }}
                  href="https://line.me/R/ti/p/@bca1493j"
                  target="_blank"
                  data-vars-event-category="social"
                  data-vars-event-label="LINE"
                >
                  <i className="fab fa-line" />
                </a>
              </li>
              <li>
                <a
                  className="ana-social-click "
                  style={{ color: '#000000' }}
                  href="https://twitter.com/holkeego"
                  target="_blank"
                  data-vars-event-category="social"
                  data-vars-event-label="Twitter"
                >
                  <i className="fab fa-twitter" />
                </a>
              </li>
              <li>
                <a
                  className="ana-social-click "
                  style={{ color: '#000000' }}
                  href="https://www.youtube.com/channel/UCWdDQAD5syDC1nYlhMOVeMA/"
                  target="_blank"
                  data-vars-event-category="social"
                  data-vars-event-label="YouTube"
                >
                  <i className="fab fa-youtube" />
                </a>
              </li>
            </ul>
          </div>
        </section>
        <footer className="FooterBox">
          <div className="ContentsBox clearfix">
            <ul className="share-tools">
              <li>
                <div
                  className="ana-share-click i-amphtml-element i-amphtml-layout-fixed i-amphtml-layout-size-defined amp-social-share-facebook i-amphtml-built i-amphtml-layout"
                  type="facebook"
                  data-param-app_id="1864999493718715"
                  width="75"
                  height="24"
                  data-vars-event-category="share"
                  data-vars-event-label="Facebook"
                  i-amphtml-layout="fixed"
                  style={{ width: '75px', height: '24px' }}
                  role="button"
                  tabIndex="0"
                  aria-label="Share by facebook"
                >
                  分享
                </div>
              </li>
              <li>
                <div
                  className="ana-share-click i-amphtml-element i-amphtml-layout-fixed i-amphtml-layout-size-defined amp-social-share-line i-amphtml-built i-amphtml-layout"
                  type="line"
                  width="75"
                  height="24"
                  data-vars-event-category="share"
                  data-vars-event-label="LINE"
                  i-amphtml-layout="fixed"
                  style={{ width: '75px', height: '24px' }}
                  role="button"
                  tabIndex="0"
                  aria-label="Share by line"
                >
                  分享
                </div>
              </li>
              <li>
                <div
                  className="ana-share-click i-amphtml-element i-amphtml-layout-fixed i-amphtml-layout-size-defined amp-social-share-twitter i-amphtml-built i-amphtml-layout"
                  type="twitter"
                  width="75"
                  height="24"
                  data-vars-event-category="share"
                  data-vars-event-label="Twitter"
                  i-amphtml-layout="fixed"
                  style={{ width: '75px', height: '24px' }}
                  s
                  role="button"
                  tabIndex="0"
                  aria-label="Share by twitter"
                >
                  分享
                </div>
              </li>
            </ul>
            <p className="copyrights">Copyrights All Rights Reserved.</p>
          </div>
        </footer>
        <ModalGateway>
          {isOpen ? (
            <Modal onClose={this.handleClose}>
              <img
                src="https://img.holkee.com/site/upload/5f927880-1818ap0ab-0ee3-9284e4fc/59dc4041abc7b57f5e7d9587bd8d89d4_double.jpg"
                alt=""
              />
            </Modal>
          ) : null}
        </ModalGateway>
        {/* </Container> */}
      </>
    );
  }
}

// const mapStateToProps = (state) => {
//   return {
//     auth: state.firebase.auth
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     createProject: (project) => dispatch(createProject(project))
//   }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(HostIndex)
export default StartUp2;
