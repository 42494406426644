import React, { Component } from "react";
import AliceCarousel from 'react-alice-carousel';
import { Icon, Segment, Button } from 'semantic-ui-react'
import Slider from "react-slick";
import Moment from 'moment';

// reactstrap components
import { Card, CardBody, CardFooter, Container, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
// import { Modal } from 'rsuite';
import Parse from 'widget/parse'
import PreloadFile, { previewUrl2 } from 'widget/material-table/PreloadFile';
// import 'react-alice-carousel/lib/alice-carousel.css';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
const hostname = (process.env.BROWSER && global && global.window && global.window.location.hostname) || '';

// core components
const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 3 },
};
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  // autoplay: true,
  // autoplaySpeed: 5000,
  cssEase: "linear",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};
const style = { width: '95%', height: '379px', cursor: 'pointer', opacity: 0.9 };
// const renderPrevButton = ({ isDisabled }) => {
//   return <button style={{ width: '30px', height: '30px', borderRadius: '50%', fontSize: '1rem', fontWeight: 500, border: 'none', outline: 'none' }}>&lt;</button>;
// };

// const renderNextButton = ({ isDisabled }) => {
//   return <button style={{ width: '30px', height: '30px', borderRadius: '50%', fontSize: '1rem', fontWeight: 500, border: 'none', outline: 'none' }}>&gt;</button>;
// };

export class SectionFeature2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedIndex: 0,
      lightboxIsOpen: false,
      photos: [],
      album: [],
      rowData: {},
      isOpen: false,
      show: false,
      fileArr: []
    };
  }

  componentDidMount = async () => {
    // const { companyObj } = this.props
    // // const companyId = companyObj.objectId
    // const data = await Parse.queryData('album', { companyId: 'aKDM0xR8py', isopen: '1' }, { sort: 'date_descend' });
    // this.setState({ album: data })
  }

  handleOpen = async (item) => {
    const { companyObj } = this.props
    const companyId = companyObj.objectId
    const data = await Parse.queryData('webimage', { companyId, pageId: item.objectId });
    this.setState({ rowData: item, fileArr: data, show: true })
  }

  handleClose = () => {
    this.setState({ show: false })
  }

  render() {
    const { form, cookies, webimageArr, imagePreviewUrl, announceCenterArr } = this.props;
    // console.log(form)
    const { text2 = '', text3 = '', img1File } = form;
    const { show, rowData, fileArr } = this.state
    const dbLang = cookies.get('dbLang') || 'name';
    let arr = []
    //announceCenterArr
    arr = announceCenterArr
    if (!arr.length) {
      arr = [
        { icon: "nc-app", title1name: "標題", content1name: '內文' },
        { icon: "nc-app", title1name: "標題", content1name: '內文' },
        { icon: "nc-app", title1name: "標題", content1name: '內文' },
        { icon: "nc-app", title1name: "標題", content1name: '內文' }]
    }
    const picUrl = (img1File && img1File.url) || imagePreviewUrl;
    //ff
    // const items = arr.map((item, index) => {
    //   const style = { width: '95%', height: '379px', cursor: 'pointer', opacity: 0.9 };
    //   return (<>
    //     <Card style={style} onClick={() => this.handleOpen(item)}>
    //       <CardBody style={{
    //         backgroundColor: 'rgba(241,242,244)',
    //       }}>
    //         <div className="card-icon" style={{
    //           backgroundImage:
    //             `url(${(item.file1 && item.file1.url) || require("assets/img/bg/rawpixel-comm.jpg")})`,
    //           backgroundSize: 'cover',
    //           backgroundPosition: 'center center',
    //           backgroundRepeat: 'no-repeat',
    //           height: '200px',
    //         }}>
    //         </div>
    //         <br />
    //         <br />
    //         <h5 className="card-category" style={{ textAlign: 'center', color: '#66615B', fontSize: '1rem', fontWeight: 600 }}>{item['title1' + dbLang] || ''}</h5>

    //         <CardFooter style={{ textAlign: 'center' }}>
    //           <br />
    //           <Button
    //             className="btn-neutral"
    //             color="info"
    //             href="#pablo"
    //             onClick={e => e.preventDefault()}
    //          >
    //             <i className="fa fa-book mr-1" />
    //           Show more
    //         </Button>
    //         </CardFooter>
    //       </CardBody>
    //     </Card></>)
    // });
    return (<>
      <div className="section section-feature cd-section" style={{
        backgroundImage:
          `url(${picUrl || require("assets/img/bg/rawpixel-comm.jpg")})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        marginBottom: '200px'
      }} id="features">
        {/* ********* FEATURES 2 ********* */}
        <div className="features-2">
          <Container>
            <div style={{ textAlign: 'center' }}>
              <span style={{ fontSize: '40px', color: '#000', fontFamily: 'sans-serif' }}>{form['text2' + dbLang] || ''}</span>
            </div>
            <br />
            <Slider {...settings}>
              {arr.sort((a, b) => new Date(b.date && b.date.iso) - new Date(a.date && a.date.iso)).map((item, index) =>
                <div style={{ width: '80%' }}>
                  <Card style={style} onClick={() => this.handleOpen(item)}>
                    <CardBody style={{
                      backgroundColor: 'rgba(241,242,244)',
                    }}>
                      <h5 style={{ textAlign: 'center' }}>{Moment((item.date && item.date.iso) || '').format('YYYY-MM-DD')}</h5>
                      <div className="card-icon" style={{
                        backgroundImage:
                          `url(${(item.img1File && item.img1File.url) || require("assets/img/bg/rawpixel-comm.jpg")})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center center',
                        backgroundRepeat: 'no-repeat',
                        height: '200px',
                      }}>
                      </div>
                      <br />
                      <br />
                      <h5 className="card-category" style={{ textAlign: 'center', color: '#66615B', fontSize: '1rem', fontWeight: 600 }} dangerouslySetInnerHTML={{ __html: item['title1' + dbLang] || '' }}></h5>

                      <CardFooter style={{ textAlign: 'center' }}>
                        <br />
                        <Button
                          className="btn-neutral"
                          color="info"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fa fa-book mr-1" />
                          Show more
                        </Button>
                      </CardFooter>
                    </CardBody>
                  </Card>
                </div>)}
            </Slider>
            {/* <AliceCarousel
                mouseTracking
                autoPlay
                autoPlayStrategy="all"
                autoPlayInterval={7000}
                animationDuration={300}
                animationType="fadeout"
                items={items}
                responsive={responsive}
                renderPrevButton={renderPrevButton}
                renderNextButton={renderNextButton}
              /> */}
            {/* <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title" style={{fontWeight:600}}>{text2}</h2>
                <br />
              </Col>
            </Row>
            <Row>
              {arr.map((item) =>
                <Col md="4">
                  <Card

                 >
                    <CardBody style={{
                      backgroundColor: '#f1f2f4',
                      opacity: 0.9
                    }}>
                      <div className="card-icon" style={{
                        backgroundImage:
                          `url(${(item.file1 && item.file1.url) || require("assets/img/bg/rawpixel-comm.jpg")})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center center',
                        backgroundRepeat: 'no-repeat',
                        height: '200px'
                      }}>
                      </div>
                      <br />
                      <br />
                      <h5 className="card-category" style={{ textAlign: 'center', color: '#66615B', fontSize: '1rem', fontWeight: 600 }}>{item['title1' + dbLang] || ''}</h5>

                      <CardFooter style={{ textAlign: 'center' }}>
                        <br />
                        <Button
                          className="btn-neutral"
                          color="info"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                       >
                          <i className="fa fa-book mr-1" />
                        Show more
                      </Button>
                      </CardFooter>
                    </CardBody>
                  </Card>
                </Col>)}
            </Row> */}
          </Container>
          <br />
          <br />
          {/* <a className="scroll-down-link scroll-down-arrow" data-iconfont="ETmodules" data-icon href="http://localhost:3000/#NlJ4XyyAI8" /> */}
          <a className="scroll-down-link scroll-down-arrow" data-iconfont="ETmodules" data-icon href={hostname === 'localhost' ? "http://localhost:3000/#NlJ4XyyAI8" : "https://aichitest.cloudoffice.tw/#NlJ4XyyAI8"} />
          <Modal isOpen={show} size="lg">
            <ModalHeader>
            </ModalHeader>
            <ModalBody>
              <h2 style={{ textAlign: 'center', fontSize: '30px' }} dangerouslySetInnerHTML={{ __html: `<p style="font-size: 30px;">${rowData['title1' + dbLang]}</p>` }}></h2>
              <br />
              <br />
              <Segment size='big' style={{ width: '70%', margin: '0px auto' }}><div dangerouslySetInnerHTML={{ __html: `<p>${rowData['content1' + dbLang]}` }} />
              </Segment>
              <br />
              <br />
              <div style={{ display: 'flex', flexWrap: 'wrap', width: '70%', margin: '0px auto' }}>
                {fileArr.map((item, index) => {
                  if (index % 4 === 0) {
                    return <div style={{ width: '20%', marginLeft: '4%', marginRight: '4%' }}> {previewUrl2(item.file1, '')}</div>
                  } else {
                    return <div style={{ width: '20%', marginRight: '4%' }}> {previewUrl2(item.file1, '')}</div>
                  }
                }
                  // <div>{item.file1 && item.file1.url}</div>
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button style={{ backgroundColor: '#27d0ca', color: '#fff' }} content='關閉' onClick={() => { this.handleClose() }} />
            </ModalFooter>
          </Modal>
        </div>
        {/* ********* END FEATURES 2 ********* */}
      </div>
    </>);
  }
}

export default SectionFeature2;
