import React from 'react';
// react plugin used to create DropdownMenu for selecting items
import Select from 'react-select';

// reactstrap components
import { Button, FormGroup, Container, Row, Col } from 'reactstrap';

// core components

function SectionFooterAreas1(props = {}) {
  const { form, companyObj } = props;
  // console.log(form)
  const {
    text2 = 'text2',
    text3 = 'text3',
    text4 = 'text4',
    text5 = 'text5',
    text6 = 'text6',
    text7 = 'text7',
    text8 = 'text8',
    text9 = 'text9',
    text10 = 'text10',
    text11 = 'text11',
    text12 = 'text12',
    text13 = 'text13',
    text14 = 'text14',
    text15 = 'text15',
    text16 = 'text16',
    text17 = 'text17',
  } = form;
  const [languageSelect, setLanguageSelect] = React.useState({
    value: 'en',
    label: 'English',
  });
  const [curencySelect, setCurencySelect] = React.useState({
    value: 'USD',
    label: 'USD',
  });
  return (
    <>
      <footer className="footer footer-big">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="6" sm="9">
              <div className="links">
                <ul className="uppercase-links">
                  <li className="mr-1">
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      {text2}
                    </a>
                  </li>
                  <li className="mr-1">
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      {text3}
                    </a>
                  </li>
                  <li className="mr-1">
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      {text4}
                    </a>
                  </li>
                  <li className="mr-1">
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      {text5}
                    </a>
                  </li>
                  <li className="mr-1">
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      {text6}
                    </a>
                  </li>
                  <li>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      {text7}
                    </a>
                  </li>
                </ul>
                <hr />
                <div className="copyright">
                  © {new Date().getFullYear()}, made with{' '}
                  <i className="fa fa-heart heart" /> by {companyObj.name}
                </div>
              </div>
            </Col>
            <Col md="4" sm="2">
              <div className="social-area">
                <Button
                  className="btn-round btn-just-icon mr-1"
                  color="facebook"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  <i className="fa fa-facebook" />
                </Button>
                <Button
                  className="btn-just-icon btn-round mr-1"
                  color="twitter"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  <i className="fa fa-twitter" />
                </Button>
                <Button
                  className="btn-just-icon btn-round mr-1"
                  color="google"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  <i className="fa fa-google-plus" />
                </Button>
                <Button
                  className="btn-just-icon btn-round"
                  color="pinterest"
                  href="#pablo"
                  onClick={e => e.preventDefault()}
                >
                  <i className="fa fa-pinterest-p" />
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default SectionFooterAreas1;
