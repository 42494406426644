import React, { Component } from 'react';
import AliceCarousel from 'react-alice-carousel';
import Carousel, { ModalGateway, Modal } from 'react-images';
import { Container } from 'semantic-ui-react'
import { Button, FormGroup, Row, Col } from "reactstrap";
import { Grid, Image, List } from 'semantic-ui-react'

import Parse from 'widget/parse'
const contentCss = {
  maxWidth: '1170px',
  margin: '100px auto',
  flexDirection: 'column',
}


export class SectionOpendata extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount = async () => {
    const { companyObj } = this.props
    this.handleFetch()
  }

  handleFetch = async () => {
    const { companyObj } = this.props
    const { objectId: companyId } = companyObj
    const filesObj = await Parse.queryData('companyFile', { companyId })
    let selfEvaluationfiles = []
    let learningSituationfiles = []

    filesObj.forEach(item => {
      if (item.filecategory === 'selfEvaluation') {
        selfEvaluationfiles.push(item.file1)
      } else if (item.filecategory === 'learningSituation') {
        learningSituationfiles.push(item.file1)
      }
    })

    this.setState({ selfEvaluationfiles, learningSituationfiles })
  }


  render() {
    const { form, cookies, webimageArr } = this.props;
    const { text2 = '標題', text3 = '', img1File = {} } = form;
    const { selfEvaluationfiles = [], learningSituationfiles = [], pageNumber, numPages } = this.state;
    const dbLang = cookies.get('dbLang') || 'name';
    let arr = []
    arr = webimageArr.filter(item => item.navId === form.objectId)
    return (
      <>
        <Container style={contentCss}>
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column >
                <List>
                  <List.Item >
                    <List.Icon name='pencil' verticalAlign='middle' />
                    <List.Content><p style={{ fontSize: '28px' }} dangerouslySetInnerHTML={{ __html: `<p style="text-align: left; font-size: 30px;">${arr && arr[0] && arr[0]['title1' + dbLang] || ''}</p>` }} /></List.Content>
                    {selfEvaluationfiles.map(item =>
                      <List.List style={{ fontSize: '22px' }}><a href={`${item.url}`}>&emsp;&ensp;{`${item.name.substring(item.name.length - 8, item.name.length - 4)}`}年度</a></List.List>
                    )}
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column>
                <List>
                  <List.Item>
                    <List.Icon name='pencil' verticalAlign='middle' />
                    <List.Content><p style={{ fontSize: '28px' }} dangerouslySetInnerHTML={{ __html: `<p style="text-align: left; font-size: 30px;">${arr && arr[1] && arr[1]['title1' + dbLang] || ''}</p>` }} /></List.Content>
                    {learningSituationfiles.map(item =>
                      <List.List style={{ fontSize: '22px' }}><a href={`${item.url}`}>&emsp;&ensp;{`${item.name.substring(item.name.length - 8, item.name.length - 4)}`}年度</a></List.List>
                    )}
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </>
    );
  }
}
export default SectionOpendata;