/*eslint-disable*/
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col
} from "reactstrap";

// core components

function SectionProject1(props = {}) {
  const { form } = props;
  //  console.log(form)
  const { text2 = 'text2', text3 = 'text3', text4 = 'text4', text5 = 'text5', text6 = 'text6',
    text7 = 'text7', text8 = 'text8', text9 = 'text9', text10 = 'text10', text11 = 'text11', text12 = 'text12',
    text13 = 'text13', text14 = 'text14', text15 = 'text15', text16 = 'text16', text17 = 'text17',
    text18 = 'text18', text19 = 'text19', text20 = 'text20' } = form;
  const [activePill, setActivePill] = React.useState("1");
  return (
    <>
      <div className="section section-project cd-section" id="projects">
        {/* ********* PROJECTS 1 ********* */}
        <div className="projects-1">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">{text2}</h2>
                <h5 className="description">
                  {text3}
                </h5>
              </Col>
              <div className="project-pills">
                <Nav className="nav-pills-danger" pills>
                  <NavItem>
                    <NavLink
                      className={activePill === "1" ? "active" : ""}
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        setActivePill("1");
                      }}
                    >
                      {text4}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activePill === "2" ? "active" : ""}
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        setActivePill("2");
                      }}
                    >
                      {text5}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activePill === "3" ? "active" : ""}
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        setActivePill("3");
                      }}
                    >
                      {text6}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activePill === "4" ? "active" : ""}
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        setActivePill("4");
                      }}
                    >
                      {text7}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activePill === "5" ? "active" : ""}
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        setActivePill("5");
                      }}
                    >
                      {text8}
                    </NavLink>
                  </NavItem>
                </Nav>
                {/* Pill panes */}
                <TabContent activeTab={"pill-" + activePill}>
                  <TabPane tabId={"pill-" + activePill} />
                  <TabPane tabId={"pill-" + activePill} />
                  <TabPane tabId={"pill-" + activePill} />
                  <TabPane tabId={"pill-" + activePill} />
                  <TabPane tabId={"pill-" + activePill} />
                </TabContent>
              </div>
            </Row>
            <div className="space-top" />
            <Row>
              <Col md="5">
                <Card
                  data-background="image"
                  style={{
                    backgroundImage:
                      "url(" +
                      require("assets/img/sections/pavel-kosov.jpg") +
                      ")"
                  }}
                >
                  <CardBody>
                    <h6 className="card-category">{text9}</h6>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <CardTitle tag="h3">{text10}</CardTitle>
                    </a>
                    <p className="card-description">
                      {text11}
                    </p>
                    <br />
                    <Button
                      className="btn-round"
                      color="danger"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fa fa-book mr-1" />
                      Read Article
                    </Button>
                  </CardBody>
                </Card>
              </Col>
              <Col md="7">
                <Card
                  data-background="image"
                  style={{
                    backgroundImage:
                      "url(" +
                      require("assets/img/sections/gukhwa-jang.jpg") +
                      ")"
                  }}
                >
                  <CardBody>
                    <h6 className="card-category">{text12}</h6>
                    <CardTitle tag="h3">
                      {text13}
                    </CardTitle>
                    <p className="card-description">
                      {text14}
                    </p>
                    <br />
                    <Button
                      className="btn-round"
                      color="primary"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fa fa-book mr-1" />
                      Read Article
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="7">
                <Card
                  data-background="image"
                  style={{
                    backgroundImage:
                      "url(" +
                      require("assets/img/sections/joshua-stannard.jpg") +
                      ")"
                  }}
                >
                  <CardBody>
                    <h6 className="card-category">{text15}</h6>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <CardTitle tag="h3">{text16}</CardTitle>
                    </a>
                    <p className="card-description">
                      {text17}
                    </p>
                    <br />
                    <Button
                      className="btn-round"
                      color="info"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fa fa-book mr-1" />
                      Read Article
                    </Button>
                  </CardBody>
                </Card>
              </Col>
              <Col md="5">
                <Card
                  data-background="image"
                  style={{
                    backgroundImage:
                      "url(" +
                      require("assets/img/sections/ilya-yakover.jpg") +
                      ")"
                  }}
                >
                  <CardBody>
                    <h6 className="card-category">{text18}</h6>
                    <CardTitle tag="h3">
                      {text19}
                    </CardTitle>
                    <p className="card-description">
                      {text20}
                    </p>
                    <br />
                    <Button
                      className="btn-round"
                      color="warning"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fa fa-book mr-1" />
                      Read Article
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        {/* ********* END PROJECTS 1 ********* */}
      </div>
    </>
  );
}

export default SectionProject1;
