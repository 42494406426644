import React from "react";
// react plugin used to create DropdownMenu for selecting items
// import Select from "react-select";
import { withCookies } from 'react-cookie';
import intl from 'react-intl-universal';
import { Container, Image, Segment, Grid, List, Header, Divider } from 'semantic-ui-react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
// import { Link } from 'react-router-dom'
// import { Modal } from 'rsuite'
import Link from 'components/Link/Link';

import PersonalPolicy from './PersonalPolicy'
import PrivacyPolicy from './PrivacyPolicy'

// reactstrap components
// import { Button, FormGroup, Container, Row, Col } from "reactstrap";

// core components


// function SectionFooterAreas7(props = {}) {
//   const { form, companyObj, cookies, history, imagePreviewUrl } = props;
// console.log(companyObj)
const footerMain = {
  background: '#fff',
  color: '#555',
  borderTop: '5px solid rgba(0, 0, 0, 0.2)',
  padding: 0,
  margin: 0
}
const flexRow = {
  display: 'flex',
  alignItems: 'center',
}
const flexCenter = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'baseline'
}
const _margin = {
  margin: 0,
  fontSize: '14px',
  fontFamily: 'inherit'
  // borderBottom: '1px dotted #444'
}
const flexCol = {
  display: 'flex',
  flexDirection: 'column',
  fontFamily: 'inherit',
  fontSize: '1.3rem'
}

const listDes = {
  fontWeight: 500,
  fontSize: '1.3rem',
  fontFamily: 'inherit',
  color: '#575757'
}
const bgPadding = {
  padding: '55px 0px 20px 0px'
}
const paddingReset = {
  padding: 0
}

const footerDiv = {
  margin: '0 3px',
  color: '#333'
}
const footerCopyright = {
  padding: '15px 0 10px',
  background: '#DDD'
}
const copyrightText = {
  textAlign: 'center',
  fontSize: 14,
  fontWeight: 400,
  marginTop: 0
}
// console.log(history)
// console.log(history.location)

class SectionFooterAreas7 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      PPopen1: false,
      PPopen2: false,
    };
  }

  setOpen1 = () => {
    this.setState({ PPopen1: true })
  }
  setOpen2 = () => {
    this.setState({ PPopen2: true })
  }
  setClose1 = () => {
    this.setState({ PPopen1: false })
  }
  setClose2 = () => {
    this.setState({ PPopen2: false })
  }

  render() {
    const { form, companyObj, cookies, history, imagePreviewUrl } = this.props;
    const { PPopen1 = false, PPopen2 = false } = this.state;

    const dbLang = cookies.get('dbLang') || 'name';
    const path = history.location.pathname
    const { img1File, text2 } = form
    const picUrl = (img1File && img1File.url) || imagePreviewUrl || '/hp1/24.jpg';

    return (<>
      <div style={{
        height: '400px', width: '100%', backgroundSize: 'cover', backgroundImage: "url(" +
          picUrl +
          ")", backgroundPosition: 'center center', backgroundRepeat: 'no-repeat'
      }}>
        {/* <Image style={{ width: '38%', left: '60%', top: '70%', opacity: 0.7, maxWidth: '400px' }} src={(img1File && img1File.url) || ''} /> */}
      </div>
      <Segment style={footerMain} className='no-print'>
        <Container>
          <Grid className='footerArea6' columns={3} style={bgPadding}>
            <Grid.Row style={{ ...paddingReset, display: 'flex', justifyContent: 'center' }}>
              <Grid.Column>
                <div style={flexCol}>
                  住所：
                </div>
                <div style={{ ...flexCol, marginBottom: '1%', marginRight: '4%' }}>
                  <List>
                    <List.Item style={flexRow}>
                      <List.Description style={listDes} as='p'>〒460-0007</List.Description>
                    </List.Item>
                    <List.Item style={flexRow}>
                      <List.Description style={listDes} as='p'>愛知県名古屋市中区新栄1－30－3</List.Description>
                    </List.Item>
                    <List.Item style={flexRow}>
                      <List.Description style={listDes} as='p'>愛知県名古屋市中区新栄1－30－29</List.Description>
                    </List.Item>
                  </List>
                </div>
              </Grid.Column>
              <Grid.Column>
                <div style={flexCol}>
                  {/* {intl.get('お問い合わせ：')} */}
                  お問い合わせ：
                </div>
                <div style={{ ...flexCol, marginBottom: '1%', marginRight: '4%' }}>
                  <List>
                    <List.Item style={flexRow}>
                      <List.Header as='h6' style={_margin}>TEL :&nbsp;</List.Header>
                      <List.Description style={listDes} as='p'>(052) 262-3366</List.Description>
                    </List.Item>
                    <List.Item style={flexRow}>
                      <List.Header as='h6' style={_margin}>Fax : &nbsp;</List.Header>
                      <List.Description style={listDes} as='p'>(052) 262-3369</List.Description>
                    </List.Item>
                    <List.Item style={flexRow}>
                      <List.Header as='h6' style={_margin}>Email : &nbsp;</List.Header>
                      <List.Description style={listDes} as='p'>school＠aiaso.gr.jp</List.Description>
                    </List.Item>
                  </List>
                </div>
              </Grid.Column>
              <Grid.Column>
                <div style={flexCol}>
                  SNS：
                </div>
                <div style={{ ...flexCol, marginBottom: '1%', marginRight: '4%' }}>
                  <List>
                    <List.Item style={flexRow}>
                      <List.Header as='h6' style={_margin}>QQ : &nbsp;</List.Header>
                      <List.Description style={listDes} as='p'>572014702</List.Description>
                    </List.Item>
                    <List.Item style={flexRow}>
                      <List.Header as='h6' style={_margin}>Skype :&nbsp; </List.Header>
                      <List.Description style={listDes} as='p'>aiasoso</List.Description>
                    </List.Item>
                    <List.Item style={flexRow}>
                      <List.Header as='h6' style={_margin}>Wechat/LINE :&nbsp; </List.Header>
                      <List.Description style={listDes} as='p'>aiajapanese</List.Description>
                    </List.Item>
                  </List>
                </div>
              </Grid.Column>
              {/* <List.Item style={flexRow}>
              <List.Header as='h6' style={_margin}>Wechat : &nbsp;</List.Header>
              <List.Description as='p'>aiajapanese</List.Description>
            </List.Item>
            <List.Item style={flexRow}>
              <List.Header as='h6' style={_margin}>Line :&nbsp; </List.Header>
              <List.Description as='p'>aiajapanese</List.Description>
            </List.Item> */}
            </Grid.Row>
            <Grid.Row>
              <div style={{ fontFamily: 'inherit', color: '#29231f', fontSize: '1rem', paddingLeft: '31%' }}>
                {/* {intl.get('受付時間：')} 9:00  -  17:30 ({intl.get('休日：')} 星期六、星期日、法定假日) */}
                受付時間： 9:00  -  17:30 (休日： 土曜日、日曜日、法定休日)
              </div>
            </Grid.Row>
          </Grid>
        </Container>
        <div style={footerCopyright}>
          <List style={flexCenter}>
            <List.Item style={footerDiv}>
              <Button
                onClick={this.setOpen1}
                color="link"
              >
                個人情報保護に関する方針
              </Button>
              <Modal
                isOpen={PPopen1}
                size='lg'
              >
                <ModalHeader style={{ backgroundColor: '#e2f2f2' }}>
                  個人情報保護に関する方針
                </ModalHeader>
                <ModalBody style={{ backgroundColor: '#f3f9f9' }}>
                  {/* <Container fluid style={{ backgroundColor: '#e8e7e6', }}> */}
                  <PersonalPolicy />
                  {/* </Container> */}
                </ModalBody>
                <ModalFooter style={{ backgroundColor: '#e2f2f2' }}>
                  <Button color="success" onClick={this.setClose1} textAlign='right' size='sm'>
                    x
                  </Button>
                </ModalFooter>
              </Modal>
            </List.Item>
            <List.Item style={footerDiv}>
              <Button
                onClick={this.setOpen2}
                color="link"
              >
                プライバシーポリシー
              </Button>
              <Modal
                isOpen={PPopen2}
                size="lg"
              >
                <ModalHeader style={{ backgroundColor: '#e2f2f2' }}>
                  プライバシーポリシー
                </ModalHeader>
                <ModalBody style={{ backgroundColor: '#f3f9f9' }}>
                  <PrivacyPolicy />
                </ModalBody>
                <ModalFooter style={{ backgroundColor: '#e2f2f2' }}>
                  <Button color="success" onClick={this.setClose2} textAlign='right'>
                    x
                  </Button>
                </ModalFooter>
              </Modal>
            </List.Item>
          </List>
          <Header as='p' style={copyrightText}>
            Copyright® {companyObj.en} All Rights Reserved.
          </Header>
        </div>
      </Segment>
    </>);
  }
}
export default SectionFooterAreas7;
// export default withCookies(SectionFooterAreas7);
