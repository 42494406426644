import React, { Component } from 'react';
import { Container, Header, Icon, Grid } from 'semantic-ui-react';
import { Col, Button, Row, Card, CardBody, CardTitle, } from 'reactstrap';
// import ColorNavbar from 'components/Navbars/ColorNavbar.js';
// import FooterBlack from 'components/Footers/FooterBlack.js';
import PageHeader from 'components/Headers/PageHeader';

export class Cooperation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = (e, { value }) => this.setState({ value });

  render() {

    const bannerImg = {
      height: 390,
      backgroundImage: `url(${'/images/aichi/g2/DSC_8099.jpg'})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: '50% 40%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    };
    const bannerText = {
      fontSize: '2.8rem',
      fontWeight: '600',
      letterSpacing: '2px',
      color: 'rgba(255, 255, 255, .9)',
      width: '1140px',
      textAlign: 'right',
    };
    const content = {
      maxWidth: '1170px',
      margin: '80px auto 110px',
      flexDirection: 'column',
    };
    const list = {
      listStyleType: 'circle',
    };
    const item = {};
    const itemTitle = {
      fontWeight: 400,
      margin: '0 0 17px 0',
      lineHeight: '1.8',
    };
    const itemText = {
      margin: '0 0 30px 0',
      lineHeight: '1.8',
    };
    const itemTextL = {
      margin: '0 0 47px 0',
      lineHeight: '1.8',
    };
    const font = {
      red: {
        color: 'crimson',
      },
      bold: {
        fontWeight: 700,
      },
    };

    return (
      <>
        <PageHeader url="/hp1/24.jpg" text="企業合作(月租)" />
        {/* <Container style={content}>
          <FormGroup tag="fieldset" row>
            <legend className="col-form-label col-sm-2">點數</legend>
            <Col sm={3}>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="radio2" />{' '}
                100點
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="radio2" />{' '}
                200點
                 </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="radio2" />{' '}
                300點
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="radio2" />{' '}
                500點
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="radio2" />{' '}
                1000點
                </Label>
              </FormGroup>
            </Col>
            <Col sm={5}>
              <Select options={payWaysOpt}></Select>
            </Col>
          </FormGroup>
        </Container> */}
        <Container style={content}>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="6">
              <h2 className="title">月租方案</h2>
              <h5 className="description">
                You have Free Unlimited Updates and Premium Support on each
                package.
              </h5>
            </Col>
          </Row>
          <div className="space-top" />
          <Row>
            <Col md="3">
              <Card className="card-pricing">
                <CardBody>
                  <h6 className="card-category text-success">1</h6>
                  <CardTitle tag="h1">$200</CardTitle>
                  <ul>
                    <li>
                      <i className="fa fa-check mr-1" />
                      Sharing Tools
                    </li>
                    <li>
                      <i className="fa fa-check mr-1" />
                      Design Tools
                    </li>
                    <li>
                      <i className="fa fa-times mr-1" />
                      Private Messages
                    </li>
                    <li>
                      <i className="fa fa-times mr-1" />
                      Personal Brand
                    </li>
                  </ul>
                  <Button
                    className="btn-round"
                    color="success"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                    outline
                  >
                    Current plan
                  </Button>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card className="card-pricing">
                <CardBody>
                  <h6 className="card-category text-success">2</h6>
                  <CardTitle tag="h1">$350</CardTitle>
                  <ul>
                    <li>
                      <i className="fa fa-check mr-1" />
                      Sharing Tools
                    </li>
                    <li>
                      <i className="fa fa-check mr-1" />
                      Design Tools
                    </li>
                    <li>
                      <i className="fa fa-times mr-1" />
                      Private Messages
                    </li>
                    <li>
                      <i className="fa fa-times mr-1" />
                      Personal Brand
                    </li>
                  </ul>
                  <Button
                    className="btn-round"
                    color="success"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                    outline
                  >
                    Current plan
                  </Button>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card className="card-pricing">
                <CardBody>
                  <h6 className="card-category text-success">3</h6>
                  <CardTitle tag="h1">$600</CardTitle>
                  <ul>
                    <li>
                      <i className="fa fa-check mr-1" />
                      Sharing Tools
                    </li>
                    <li>
                      <i className="fa fa-check mr-1" />
                      Design Tools
                    </li>
                    <li>
                      <i className="fa fa-times mr-1" />
                      Private Messages
                    </li>
                    <li>
                      <i className="fa fa-times mr-1" />
                      Personal Brand
                    </li>
                  </ul>
                  <Button
                    className="btn-round"
                    color="success"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                    outline
                  >
                    Current plan
                  </Button>
                </CardBody>
              </Card>
            </Col>
            <Col md="3">
              <Card className="card-pricing">
                <CardBody>
                  <h6 className="card-category text-success">4</h6>
                  <CardTitle tag="h1">$1300</CardTitle>
                  <ul>
                    <li>
                      <i className="fa fa-check mr-1" />
                      Sharing Tools
                    </li>
                    <li>
                      <i className="fa fa-check mr-1" />
                      Design Tools
                    </li>
                    <li>
                      <i className="fa fa-times mr-1" />
                      Private Messages
                    </li>
                    <li>
                      <i className="fa fa-times mr-1" />
                      Personal Brand
                    </li>
                  </ul>
                  <Button
                    className="btn-round"
                    color="success"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                    outline
                  >
                    Current plan
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <br />
        </Container>
      </>
    );
  }
}

export default Cooperation;
