import React from 'react';

import intl from 'react-intl-universal';
import { Image } from 'semantic-ui-react'
function PageHeader(props = {}) {
  const { form, imagePreviewUrl, cookies, companyObj } = props;
  const { img1File, text2 } = form;
  const dbLang = cookies.get('dbLang') || 'name';
  document.documentElement.classList.remove('nav-open');
  // React.useEffect(() => {
  //   document.body.classList.add("product-page");
  //   return function cleanup() {
  //     document.body.classList.remove("product-page");
  //   };
  // });
  // 自己点検・自己評価
  const picUrl = (img1File && img1File.url) || imagePreviewUrl || '/hp1/24.jpg';
  return (
    <>
      <div
        className="page-header page-header-xs"
        style={{
          backgroundImage: `url(${picUrl})`,
        }}
      >
        {/* <Image style={{ width: '38%', left: '60%', top: '70%', opacity: 0.7, maxWidth: '400px' }} src={(img1File && img1File.url) || ''} /> */}
        <div className="filter" />
        <div className="content-center">
          <div className="motto">
            {/* <h1 className="text-center">Paper Kit PRO React</h1>
            <h3 className="text-center">Components</h3> */}
            {/* <h3 style={{ fontSize: '50px', marginLeft: '27%' }}>{intl.get(text2)}</h3> */}
            {/* <span className='pageHeaderH3' style={{ fontSize: '50px' }}>{intl.get(text2)}</span> */}
            {form['text2' + dbLang] ? (
              <span dangerouslySetInnerHTML={{ __html: `<span style="font-family: sans-serif; font-weight: 300; font-size: 70px;color: #000000; background-color: rgb(255,255,255,0.1);">${form['text2' + dbLang] || ''}</sapn>` }} >
                {/* {text2} */}
              </span>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

export default PageHeader;
