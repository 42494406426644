import React, { Component } from 'react';
import { Container, Header, Form, Icon, Button } from 'semantic-ui-react';
import { Modal } from 'rsuite';

export class ModalEditText extends Component {
  constructor(props) {
    super(props);
    //    console.log(props);
    this.state = {
      show: false,
      text: '',
      orginalText: '',
    };
  }

  componentDidMount = () => {
    const { id, obj } = this.props;
    // const orginalText = (obj.text[id] && obj.text.find(item => item.title === id).content) || (id && document.getElementById(id).innerHTML) || ''
    // this.setState({ orginalText })
  };

  handleChange = (e, { value }) => this.setState({ value });

  closeEdit = () => {
    this.setState({ show: false });
  };

  onEdit = () => {
    this.setState({ show: true });
  };

  handleEditorChange = (id, value, content) => {
    this.setState({ [id]: value, content });
  };

  render() {
    const { handleUse, id } = this.props;
    const { show, text, orginalText, content } = this.state;

    //     if (!auth.uid) return <Redirect to='/signin' />
    // const { value } = this.state
    return (<>
      <a className="j-pop-text" onClick={this.onEdit}>
        編輯
      </a>
      <Modal open={show} onClose={this.closeEdit}
        size="lg">
        <Modal.Header>
          <Modal.Title>

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="white-popup" id="popup-textsetting">
            <form className="setBox" data-parsley-required-message="尚未填寫">
              <div className="top_title">
                <h3>編輯文字</h3>
              </div>
              <div className="fillinBox text-editbox">
                {/* <JoditEditor
                    config={{
                      height: 400,
                      buttons: [
                        'bold',
                        'strikethrough',
                        'underline',
                        'italic',
                        '|',
                        'ul',
                        'ol',
                        '|',
                        'font',
                        'fontsize',
                        'brush',
                        'paragraph',
                        'align',
                        'undo',
                        'redo',
                      ],
                      toolbarAdaptive: false,
                    }}
                    style={{ height: 400 }}
                    name="text"
                    value={orginalText}
                    tabIndex={2}
                    onBlur={newContent =>
                      this.handleEditorChange(
                        'text',
                        newContent.target.innerHTML,
                        newContent.target.innerText,
                      )
                    }
                  /> */}
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ backgroundColor: '#27d0ca', color: '#fff' }}
            content="套用"
            onClick={() => {
              handleUse(text, id, content);
              this.closeEdit();
            }}
          />
        </Modal.Footer>
      </Modal>
    </>);
  }
}

export default ModalEditText;
