import React, { Component } from 'react';
// import { connect } from 'react-redux'
// import { createProject } from '../../store/actions/projectActions'
// import { Redirect } from 'react-router-dom'
import { Container, Header, Form, Icon, Grid, Image } from 'semantic-ui-react';
import { ModalGateway, Modal } from 'react-images';
import ColorNavbar from 'components/Navbars/ColorNavbar.js';
import FooterBlack from 'components/Footers/FooterBlack.js';
import PageHeader from 'components/Headers/PageHeader';
import { Col, Row, CardImg } from 'reactstrap';
import Parse from 'widget/parse';
// import './css/startup1.css'
export class StartUp3 extends Component {
  constructor(props) {
    super(props);
    //    console.log(props);
    this.state = {
      isOpen: false,
    };
  }

  handleChange = (e, { value }) => this.setState({ value });

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  handleOpen = () => {
    this.setState({ isOpen: true });
  };
  //   handleChange = (e) => {
  //     // console.log(e);
  //     this.setState({
  //       [e.target.id]: e.target.value
  //     })
  //   }

  //   handleSubmit = (e) => {
  //     // console.log(e);
  //     e.preventDefault();
  //     this.props.createProject(this.state);
  //     this.props.history.push('/');
  //   }
  render() {
    const { isOpen } = this.state;
    //     const { auth } = this.props;
    //     if (!auth.uid) return <Redirect to='/signin' />
    // const { value } = this.state
    return (
      <>
        {/* <Container style={content}> */}
        <section
          id="goto221257"
          className="section-base mainBox background-setting"
          style={{
            backgroundImage:
              'url(https://img.holkee.com/site/upload/5ee9d5ea-4366ap0ab-12d3-8d4838bf/d6f29f4d3ec34fea1da54401d119a785_double.jpg)',
          }}
        >
          <div className="ContentsBox default">
            <div className="imgBox" />
            <p
              className="sub_title"
              style={{
                fontWeight: 'bold',
                textAlign: 'center',
                fontSize: '1.0625rem',
              }}
            >
              鐘錶匠專業維修中心秉持
              <br />
              「誠信服務、專業維修、耐心解說、價錢公道」
            </p>
            <div className="aBox" />
          </div>
        </section>
        <section
          id="goto221258"
          className="section-base about_us_box background-setting"
          style={{
            backgroundImage:
              'url(https://img.holkee.com/site/upload/5ee9d5ea-4366ap0ab-12d3-8d4838bf/c616faf5ed7dcf7f04566eced764d187_double.jpg)',
          }}
        >
          <div className="ContentsBox style-two clearfix">
            <div className="aBox">
              <div className="imgBox">
                <img
                  src="https://img.holkee.com/site/upload/5ee9d5ea-4366ap0ab-12d3-8d4838bf/6e20444753317837f51132e790e57af4_double.png"
                  className="i-amphtml-fill-content i-amphtml-replaced-content"
                  alt=""
                />
              </div>
            </div>
            <div className="aBox">
              <h3
                className="main_title"
                style={{
                  textAlign: 'center',
                  fontSize: '1.5rem',
                  color: '#FFFFFF',
                }}
              >
                時間工匠
              </h3>
              <p className="sub_title" style={{ color: '#FFFFFF' }}>
                手錶維修是一門需要技術和經驗的工藝，若是一等一的技師需要培養細心、耐心、一雙巧手和一對好眼力，從取得客人的手錶，評估手錶狀況、分類服務項目、排除手錶問題、對手錶進行檢測調整，這樣才能確保修復完成的手錶能正常運作。
                <br />
                <br />
                如果有鐘錶維修的需求歡迎聯絡我們，透過網路，無需出門，在家也可輕鬆享受最專業、高品質、有效率的手錶維修保養服務。{' '}
              </p>
            </div>
          </div>
        </section>
        <section
          id="goto221325"
          className="section-base video_box background-setting"
          style={{
            backgroundImage:
              'url(https://img.holkee.com/site/upload/5ee9d5ea-4366ap0ab-12d3-8d4838bf/02e4435893fbbe45f02d5e98d25dc511_double.jpg)',
          }}
        >
          <div className="ContentsBox">
            <h3 className="main_title" style={{ color: '#FFFFFF' }}>
              保養手錶的七種方式
            </h3>
            <p className="sub_title" style={{ color: '#FFFFFF' }}>
              Source：YouTube / WatchBox Studios
            </p>
            <div className="embedBox">
              <iframe
                width="800"
                height="450"
                src="https://www.youtube.com/embed/jQRLDpqYH0Q?enablejsapi=1&amp=1&playsinline=1&iv_load_policy=3"
                title="youtube"
              />
            </div>
          </div>
        </section>
        <section
          id="goto221260"
          className="section-base contact_us background-setting bg_translucent_k"
          style={{
            backgroundImage:
              'url(https://img.holkee.com/site/upload/5ee9d5ea-4366ap0ab-12d3-8d4838bf/18f7492bfdc2b2e0485a795d807975bf_double.jpg)',
          }}
        >
          <div className="ContentsBox default clearfix">
            <div className="aBox">
              <div className="mapBox">
                <iframe
                  className="i-amphtml-element i-amphtml-layout-fill i-amphtml-layout-size-defined i-amphtml-built i-amphtml-layout"
                  sandbox="allow-scripts allow-same-origin allow-popups"
                  title="google"
                  src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDXtBW26A1ECFElryP7Qar7EPx78d3ptV0&q=%E5%8F%B0%E5%8C%97%E5%B8%82%E4%BF%A1%E7%BE%A9%E8%B7%AF%E4%BA%94%E6%AE%B57%E8%99%9F#amp=1"
                  width="100%"
                  height="100%"
                />
              </div>
            </div>
            <div className="aBox">
              <h3
                className="main_title"
                style={{ textAlign: 'center', fontSize: '1.75rem' }}
              >
                鐘錶匠專業
                <br />
                維修中心
              </h3>
              <p
                className="sub_title"
                style={{ textAlign: 'center', fontSize: '0.9375rem' }}
              >
                最專業、高品質、有效率的鐘錶維修保養服務
              </p>
              <ul className="info">
                <li>
                  <p className="address">台北市信義路五段7號</p>
                </li>
                <li>
                  <p className="phone">
                    <a
                      className="ana-button-click"
                      href="tel:0908-000-1234"
                      data-vars-event-category="contact"
                      data-vars-event-label="0908-000-1234"
                    >
                      0908-000-1234
                    </a>
                  </p>
                </li>
                <li>
                  <p className="time">10:00 AM - 11:30 PM</p>
                </li>
                <li>
                  <p className="email">
                    <a
                      className="ana-button-click"
                      href="mailto:service@example.com"
                      data-vars-event-category="contact"
                      data-vars-event-label="service@example.com"
                    >
                      service@example.com
                    </a>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section
          id="goto221261"
          className="section-base follow_us background-setting"
          style={{
            backgroundImage:
              'url(https://img.holkee.com/site/upload/5ee9d5ea-4366ap0ab-12d3-8d4838bf/1f84e42fd4a085b4a768ac7ab12d57b0_double.jpg)',
          }}
        >
          <div className="ContentsBox default">
            <h3
              className="main_title"
              style={{
                textAlign: 'center',
                fontSize: '1.75rem',
                color: '#FFFFFF',
              }}
            >
              追蹤我們
            </h3>
            <p
              className="sub_title"
              style={{
                textAlign: 'center',
                fontSize: '0.9375rem',
                color: '#FFFFFF',
              }}
            >
              追蹤我們，掌握第一手消息與最新優惠折扣喔。
            </p>

            <ul className="follow_us_icon">
              <li>
                <a
                  className="ana-social-click "
                  style={{ backgroundColor: '#b4936f' }}
                  href="https://www.holkee.com"
                  target="_blank"
                  data-vars-event-category="social"
                  data-vars-event-label="Facebook"
                >
                  <i className="fab fa-facebook-square" />
                </a>
              </li>
              <li>
                <a
                  className="ana-social-click "
                  style={{ backgroundColor: '#b4936f' }}
                  href="https://www.holkee.com"
                  target="_blank"
                  data-vars-event-category="social"
                  data-vars-event-label="Instagram"
                >
                  <i className="fab fa-instagram" />
                </a>
              </li>
              <li>
                <a
                  className="ana-social-click "
                  style={{ backgroundColor: '#b4936f' }}
                  href="https://www.holkee.com"
                  target="_blank"
                  data-vars-event-category="social"
                  data-vars-event-label="LINE"
                >
                  <i className="fab fa-line" />
                </a>
              </li>
              <li>
                <a
                  className="ana-social-click "
                  style={{ backgroundColor: '#b4936f' }}
                  href="https://www.holkee.com"
                  target="_blank"
                  data-vars-event-category="social"
                  data-vars-event-label="Twitter"
                >
                  <i className="fab fa-twitter" />
                </a>
              </li>
              <li>
                <a
                  className="ana-social-click "
                  style={{ backgroundColor: '#b4936f' }}
                  href="https://www.holkee.com"
                  target="_blank"
                  data-vars-event-category="social"
                  data-vars-event-label="YouTube"
                >
                  <i className="fab fa-youtube" />
                </a>
              </li>
            </ul>
          </div>
        </section>
        <footer className="FooterBox">
          <div className="ContentsBox clearfix">
            <ul className="share-tools">
              <li>
                <div
                  className="ana-share-click i-amphtml-element i-amphtml-layout-fixed i-amphtml-layout-size-defined amp-social-share-facebook i-amphtml-built i-amphtml-layout"
                  type="facebook"
                  data-param-app_id="1864999493718715"
                  width="75"
                  height="24"
                  data-vars-event-category="share"
                  data-vars-event-label="Facebook"
                  i-amphtml-layout="fixed"
                  style={{ width: '75px', height: '24px' }}
                  role="button"
                  tabIndex="0"
                  aria-label="Share by facebook"
                >
                  分享
                </div>
              </li>
              <li>
                <div
                  className="ana-share-click i-amphtml-element i-amphtml-layout-fixed i-amphtml-layout-size-defined amp-social-share-line i-amphtml-built i-amphtml-layout"
                  type="line"
                  width="75"
                  height="24"
                  data-vars-event-category="share"
                  data-vars-event-label="LINE"
                  i-amphtml-layout="fixed"
                  style={{ width: '75px', height: '24px' }}
                  role="button"
                  tabIndex="0"
                  aria-label="Share by line"
                >
                  分享
                </div>
              </li>
              <li>
                <div
                  className="ana-share-click i-amphtml-element i-amphtml-layout-fixed i-amphtml-layout-size-defined amp-social-share-twitter i-amphtml-built i-amphtml-layout"
                  type="twitter"
                  width="75"
                  height="24"
                  data-vars-event-category="share"
                  data-vars-event-label="Twitter"
                  i-amphtml-layout="fixed"
                  style={{ width: '75px', height: '24px' }}
                  role="button"
                  tabIndex="0"
                  aria-label="Share by twitter"
                >
                  分享
                </div>
              </li>
            </ul>
            <p className="copyrights">Copyrights All Rights Reserved.</p>
          </div>
        </footer>
        <ModalGateway>
          {isOpen ? (
            <Modal onClose={this.handleClose}>
              <img
                src="https://img.holkee.com/site/upload/5f927880-1818ap0ab-0ee3-9284e4fc/59dc4041abc7b57f5e7d9587bd8d89d4_double.jpg"
                alt=""
              />
            </Modal>
          ) : null}
        </ModalGateway>
        {/* </Container> */}
      </>
    );
  }
}

// const mapStateToProps = (state) => {
//   return {
//     auth: state.firebase.auth
//   }
// }

// const mapDispatchToProps = (dispatch) => {
//   return {
//     createProject: (project) => dispatch(createProject(project))
//   }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(HostIndex)
export default StartUp3;
