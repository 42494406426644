import React from 'react';
import { Dimmer, Loader, Image, Segment } from 'semantic-ui-react';

import intl from 'react-intl-universal';
import { injectIntl, FormattedMessage, } from 'react-intl';
import shortParagraph from './shortParagraph.png';

export const DimmerImg = ({
  style = {},
  text = `${(intl.get('読み込み中')) || '載入中'}...`,
}) => (
  <Segment style={{ position: 'relative', zIndex: 0, height: '100px', ...style }} >
    <Dimmer active inverted>
      <Loader inverted>
        <span style={{ color: '#999' }}>
          <FormattedMessage
            id="読み込み中"
            description=""
            defaultMessage="載入中"
          />
          ...
        </span>
      </Loader>
    </Dimmer>
    <Image src={shortParagraph} />
  </Segment>
);

export const MyDimmer = ({ text = `${(intl.get('ページの読み込み') || '載入中')}...` }) => (
  <Dimmer active inverted>
    <Loader>
      {/* {companyObj.name} {companyObj.sectorText}管理系統 <br /> */}
      <span style={{ color: '#999', fontFamily: 'serif', fontSize: '1.2em' }}>
        <FormattedMessage
          id="ページの読み込み"
          description=""
          defaultMessage="Loading"
        />
        ...
      </span>
      {/* <Image src="icon_mai_load.png" size="small" /> */}
    </Loader>
  </Dimmer>
);

export default MyDimmer;
