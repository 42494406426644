import React, { Component } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col
} from "reactstrap";
import 'react-alice-carousel/lib/alice-carousel.css';

// core components
const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 3 },
};
export class SectionCourse1 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      num: 1,
      isPress1: true,
      isPress2: false,
      isPress3: false,
      isPress4: false,
    };
  }

  componentDidMount = async () => {
    // const { companyObj } = this.props
    // // const companyId = companyObj.objectId
    // const data = await Parse.queryData('album', { companyId: 'aKDM0xR8py', isopen: '1' }, { sort: 'date_descend' });
    // this.setState({ album: data })
  }

  handleData = (num, press) => {
    this.setState({ num, isPress1: false, isPress2: false, isPress3: false, isPress4: false, [press]: true })
  }

  render() {
    const { form, cookies, webimageArr = [] } = this.props;
    const dbLang = cookies.get('dbLang') || 'name';
    let arr = []
    arr = webimageArr.filter(item => item.navId === form.objectId)
    // console.log(form)
    const { text2 = '', text3 = '', img1File } = form;
    const { num, isPress1 = false, isPress2 = false, isPress3 = false, isPress4 = false } = this.state
    const buttonColor1 = isPress1 ? '#c1c3c0' : '#f1f2f4'
    const buttonColor2 = isPress2 ? '#c1c3c0' : '#f1f2f4'
    const buttonColor3 = isPress3 ? '#c1c3c0' : '#f1f2f4'
    const buttonColor4 = isPress4 ? '#c1c3c0' : '#f1f2f4'
    // console.log("arr:", arr)
    // if (arr && arr[0] && arr[0].file1 && arr[0].file1.url) {
    //   console.log("file1.url:", arr[0].file1.url)
    // }
    return (
      <>
        <br />
        <br />
        <br />
        <br />
        <Container>
          <Row>
            <Col md='3' xs='3'>
              <Card style={{ cursor: 'pointer' }} onClick={() => this.handleData(1, 'isPress1')}>
                <CardBody style={{
                  backgroundColor: buttonColor1,
                  opacity: 0.9,
                  padding: '15px 5px'
                }}>
                  {/* <h5 className="card-category" style={{ textAlign: 'center', color: '#66615B', fontSize: '1rem', fontWeight: 600 }}>
                    {(arr && arr[0] && arr[0]['title1' + dbLang]) || ''}
                  </h5> */}
                  <h5 dangerouslySetInnerHTML={{ __html: `<p style="text-align: center; color: '#66615B'; font-size: 20px;">${(arr && arr[0] && arr[0]['title1' + dbLang]) || ''}</p>` }}></h5>
                </CardBody>
              </Card>
            </Col>
            <Col md='3' xs='3'>
              <Card style={{ cursor: 'pointer' }} onClick={() => this.handleData(2, 'isPress2')}>
                <CardBody style={{
                  backgroundColor: buttonColor2,
                  opacity: 0.9,
                  padding: '15px 5px'
                }}>
                  {/* <h5 className="card-category" style={{ textAlign: 'center', color: '#66615B', fontSize: '1rem', fontWeight: 600 }}>
                    {(arr && arr[1] && arr[1]['title1' + dbLang]) || ''}
                  </h5> */}
                  <h5 dangerouslySetInnerHTML={{ __html: `<p style="text-align: center; color: '#66615B'; font-size: 20px;">${(arr && arr[1] && arr[1]['title1' + dbLang]) || ''}</p>` }}></h5>
                </CardBody>
              </Card>
            </Col>
            <Col md='3' xs='3'>
              <Card style={{ cursor: 'pointer' }} onClick={() => this.handleData(3, 'isPress3')}>
                <CardBody style={{
                  backgroundColor: buttonColor3,
                  opacity: 0.9,
                  padding: '15px 5px'
                }}>
                  {/* <h5 className="card-category" style={{ textAlign: 'center', color: '#66615B', fontSize: '1rem', fontWeight: 600 }}>
                    {(arr && arr[2] && arr[2]['title1' + dbLang]) || ''}
                  </h5> */}
                  <h5 dangerouslySetInnerHTML={{ __html: `<p style="text-align: center; color: '#66615B'; font-size: 19px;">${(arr && arr[2] && arr[2]['title1' + dbLang]) || ''}</p>` }}></h5>
                </CardBody>
              </Card>
            </Col>
            <Col md='3' xs='3'>
              <Card style={{ cursor: 'pointer' }} onClick={() => this.handleData(4, 'isPress4')}>
                <CardBody style={{
                  backgroundColor: buttonColor4,
                  opacity: 0.9,
                  padding: '15px 5px'
                }}>
                  {/* <h5 className="card-category" style={{ textAlign: 'center', color: '#66615B', fontSize: '1rem', fontWeight: 600 }}>
                    {(arr && arr[3] && arr[3]['title1' + dbLang]) || ''}
                  </h5> */}
                  <h5 dangerouslySetInnerHTML={{ __html: `<p style="text-align: center; color: '#66615B'; font-size: 20px;">${(arr && arr[3] && arr[3]['title1' + dbLang]) || ''}</p>` }}></h5>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row style={{ marginTop: '100px' }}>
            {num === 1 ? <>
              <div style={{ display: 'flex' }}>
                <img style={{ marginLeft: '0px', width: '600px', height: '400px', borderRadius: '5%' }} id="courseImg1" src={arr && arr[0] && arr[0].file1 && arr[0].file1.url} />
                <div style={{ marginLeft: '4%' }}>
                  {/* <h2>{(arr && arr[0] && arr[0]['title1' + dbLang]) || ''}</h2> */}
                  <h2 dangerouslySetInnerHTML={{ __html: `<p style="font-size: 30px;">${(arr && arr[0] && arr[0]['title1' + dbLang]) || ''}</p>` }}></h2>
                  <div dangerouslySetInnerHTML={{ __html: (arr && arr[0] && arr[0]['content1' + dbLang]) || '' }} />
                </div>
              </div></> : num === 2 ? <>
                <div style={{ display: 'flex' }}>
                  <img style={{ marginLeft: '0px', width: '600px', height: '400px', borderRadius: '5%' }} id="courseImg2" src={arr && arr[1] && arr[1].file1 && arr[1].file1.url} />
                  <div style={{ marginLeft: '4%', }}>
                    {/* <h2>{(arr && arr[1] && arr[1]['title1' + dbLang]) || ''}</h2> */}
                    <h2 dangerouslySetInnerHTML={{ __html: `<p style="font-size: 30px;">${(arr && arr[1] && arr[1]['title1' + dbLang]) || ''}</p>` }}></h2>
                    <div dangerouslySetInnerHTML={{ __html: (arr && arr[1] && arr[1]['content1' + dbLang]) || '' }} />
                  </div>
                </div></> : num === 3 ? <>
                  <div style={{ display: 'flex' }}>
                    <img style={{ marginLeft: '0px', width: '600px', height: '400px', borderRadius: '5%' }} id="courseImg3" src={arr && arr[2] && arr[2].file1 && arr[2].file1.url} />
                    <div style={{ marginLeft: '4%' }}>
                      {/* <h2>{(arr && arr[2] && arr[2]['title1' + dbLang]) || ''}</h2> */}
                      <h2 dangerouslySetInnerHTML={{ __html: `<p style="font-size: 30px;">${(arr && arr[2] && arr[2]['title1' + dbLang]) || ''}</p>` }}></h2>
                      <div dangerouslySetInnerHTML={{ __html: (arr && arr[2] && arr[2]['content1' + dbLang]) || '' }} />
                    </div>
                  </div>
                </> : num === 4 ? <>
                  <div style={{ display: 'flex' }}>
                    <img style={{ marginLeft: '0px', width: '600px', height: '400px', borderRadius: '5%' }} id="courseImg4" src={arr && arr[3] && arr[3].file1 && arr[3].file1.url} />
                    <div style={{ marginLeft: '4%' }}>
                      {/* <h2>{(arr && arr[3] && arr[3]['title1' + dbLang]) || ''}</h2> */}
                      <h2 dangerouslySetInnerHTML={{ __html: `<p style="font-size: 30px;">${(arr && arr[3] && arr[3]['title1' + dbLang]) || ''}</p>` }}></h2>
                      <div dangerouslySetInnerHTML={{ __html: (arr && arr[3] && arr[3]['content1' + dbLang]) || '' }} />
                    </div>
                  </div>
                </> : null}
          </Row>
        </Container>
      </>
    );
  }
}

export default SectionCourse1;
