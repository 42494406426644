import React from 'react';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
} from 'reactstrap';

// core components

function SectionPricing5(props = {}) {
  const { form } = props;
  //  console.log(form)
  const {
    text2 = 'text2',
    text3 = 'text3',
    text4 = 'text4',
    text5 = 'text5',
    text6 = 'text6',
    text7 = 'text7',
    text8 = 'text8',
    text9 = 'text9',
    text10 = 'text10',
    text11 = 'text11',
    text12 = 'text12',
    text13 = 'text13',
    text14 = 'text14',
    text15 = 'text15',
    text16 = 'text16',
    text17 = 'text17',
    text18 = 'text18',
    text19 = 'text19',
    text20 = 'text20',
    text21 = 'text21',
    text22 = 'text22',
    text23 = 'text23',
    text24 = 'text24',
    text25 = 'text25',
    text26 = 'text26',
    text27 = 'text27',
    text28 = 'text28',
    text29 = 'text29',
  } = form;
  const [activePill, setActivePill] = React.useState('1');
  // pills for the last pricing
  const [pillActive, setPillActive] = React.useState('personal');
  return (
    <>
      <div className="section section-pricing cd-section" id="pricing">
        {/* ********* PRICING 5 ********* */}
        <div className="pricing-5 section-gray">
          <Container>
            <Row>
              <Col md="4">
                <h2 className="title">{text2}</h2>
                <div className="choose-plan">
                  <Nav className="nav-pills-danger" pills role="tablist">
                    <NavItem>
                      <NavLink
                        className={pillActive === 'personal' ? 'active' : ''}
                        href="#pablo"
                        onClick={e => {
                          e.preventDefault();
                          setPillActive('personal');
                        }}
                      >
                        {text3}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={pillActive === 'commercial' ? 'active' : ''}
                        href="#pablo"
                        onClick={e => {
                          e.preventDefault();
                          setPillActive('commercial');
                        }}
                      >
                        {text4}
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
                <br />
                <p className="description text-gray">{text5}</p>
              </Col>
              <Col className="ml-auto" md="7">
                <TabContent className="text-center" activeTab={pillActive}>
                  <TabPane tabId="personal">
                    <div className="space-top" />
                    <Row>
                      <Col md="6">
                        <Card className="card-pricing">
                          <CardBody>
                            <h6 className="card-category text-primary">
                              {text6}
                            </h6>
                            <CardTitle tag="h1">{text7}</CardTitle>
                            <ul>
                              <li>{text8}</li>
                              <li>{text9}</li>
                              <li>{text10}</li>
                            </ul>
                            <Button
                              className="btn-round"
                              color="primary"
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              {text11}
                            </Button>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md="6">
                        <Card className="card-pricing" data-color="orange">
                          <CardBody>
                            <h6 className="card-category text-success">
                              {text12}
                            </h6>
                            <CardTitle tag="h1">{text13}</CardTitle>
                            <ul>
                              <li>{text14}</li>
                              <li>{text15}</li>
                              <li>{text16}</li>
                            </ul>
                            <Button
                              className="btn-neutral btn-round"
                              color="default"
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              {text17}
                            </Button>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="commercial">
                    <div className="space-top" />
                    <Row>
                      <Col md="6">
                        <Card className="card-pricing">
                          <CardBody>
                            <h6 className="card-category text-warning">
                              {text18}
                            </h6>
                            <CardTitle tag="h1">{text19}</CardTitle>
                            <ul>
                              <li>{text20}</li>
                              <li>{text21}</li>
                              <li>{text22}</li>
                            </ul>
                            <Button
                              className="btn-round"
                              color="warning"
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              {text23}
                            </Button>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md="6">
                        <Card className="card-pricing" data-color="orange">
                          <CardBody>
                            <h6 className="card-category text-success">
                              {text24}
                            </h6>
                            <CardTitle tag="h1">{text25}</CardTitle>
                            <ul>
                              <li>{text26}</li>
                              <li>{text27}</li>
                              <li>{text28}</li>
                            </ul>
                            <Button
                              className="btn-neutral btn-round"
                              color="default"
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              {text29}
                            </Button>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Container>
        </div>
        {/* ********* END PRICING 5 ********* */}
      </div>
    </>
  );
}

export default SectionPricing5;
