import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import { Modal } from 'rsuite';


import Parse from 'widget/parse';
import MaterialTable, { imgBtnCol, imgPreviewCol, exportPdf, } from 'widget/material-table';
import { readFileAsURL } from 'widget/material-table/RowImage';
import ModalUploadFiles from 'widget/material-table/ModalUploadFiles';

const columns = ({ isMultiLang, photos }) => [
  { width: 60, title: '順序', field: 'order', editable: 'never', type: 'numeric' },
  // { title: '封面', field: 'iscover', type: 'boolean', initialEditValue: false },
  imgBtnCol({ field: 'img1File' }),
  imgPreviewCol({ title: '照片', field: 'img1File', photos }),
  { title: `說明${isMultiLang ? '(繁體中文)' : ''}`, field: 'name' },
  { title: '描述', field: 'description' },
  { title: '簡體中文', field: 'zhHant', hidden: !isMultiLang },
  { title: '日文', field: 'ja', hidden: !isMultiLang },
  { title: '英文', field: 'en', hidden: !isMultiLang },
  { title: '越南文', field: 'vi', hidden: !isMultiLang },
  { title: '備註', field: 'ps' },
];

export class ModalEditPhotoes extends Component {
  constructor(props) {
    super(props);
    //    console.log(props);
    this.state = {
      show: false,
      imagePreviewUrlArr: [],
      photo: '',
      colorDropdown: false,
      data1: [],
    };
  }

  componentDidMount = () => {
    this.handleFetch(this.props);
  };

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async (props = this.props) => {
    const { companyObj, pageObj, group } = this.props;
    const companyId = companyObj.objectId;
    this.setState({ loading1: true });
    const data = await Parse.queryData('webimage', {
      companyId,
      pageId: pageObj.objectId,
      group,
    });
    this.setState({ loading1: false, data1: data });
  };

  handleState = (dataArr, arrName = 'dataArr') => {
    this.setState({ [arrName]: dataArr });
  };

  handleChange = (e, { value }) => this.setState({ value });

  closeEdit = () => {
    this.setState({ show: false });
  };

  onEdit = () => {
    this.setState({ show: true });
  };

  handleColorDropdown = () => {
    const { colorDropdown } = this.state;
    if (colorDropdown === false) {
      const colorDropdown = document.getElementById('colorDropdown');
      colorDropdown.style.display = 'grid';
      this.setState({ colorDropdown: true });
    } else {
      const colorDropdown = document.getElementById('colorDropdown');
      colorDropdown.style.display = 'none';
      this.setState({ colorDropdown: false });
    }
  };

  handleUpdate = async () => {
    const { handleFetch } = this.props;
    const { newData } = this.state;
    await Parse.saveData('webimage', newData);
    this.setState({ newData: {}, oldData: {} });
    handleFetch();
  };

  handleDelete = async () => {
    const { handlePhotoDelete } = this.props;
    const { oldData } = this.state;
    await Parse.deleteData('webimage', oldData);
    handlePhotoDelete(oldData);
    this.setState({ oldData: {} });
  };

  handleChangeFile = async file => {
    this.setState({ file, changeConfirm: false });
  };

  handleChange = async e => {
    e.preventDefault();
    const { files } = e.target;
    if (!files || !files.length) {
      return;
    }
    const promises = [];
    const item = files[0];
    const element = readFileAsURL(item);
    promises.push(element);
    const imagePreviewUrlArr = await Promise.all(promises).catch(error => console.log('error', error));
    this.setState({ imagePreviewUrlArr });
  };

  render() {
    const { handleChangeFile, pageObj, photo, companyObj } = this.props;
    const { show, imagePreviewUrlArr, data1 } = this.state;
    return (<>
      <a className="j-pop-gallery" data-popup="#popup-gallerysetting" onClick={this.onEdit} >編輯</a>
      <Modal open={show} onClose={this.closeEdit} size="lg">
        <Modal.Header>
          <Modal.Title>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalUploadFiles  {...this.props} isImage="1" pageObj={pageObj} handleData={() => { this.handleFetch(pageObj); }} />
          <br />
          <br />
          <MaterialTable
            // isLoading={loading1}
            columns={columns({
              isMultiLang: companyObj.isMultiLang,
              photos: data1,
            })}
            options={{
              exportButton: true,
              exportPdf: (columns, data) => exportPdf(columns, data, '照片'),
              pageSize: 10,
              search: false,
            }}
            tableRef={this.tableRef}
            data={data1}
            title="照片"
            editable={{
              // isEditHidden: () => true,
              // isDeleteHidden: () => true,
              // onRowAdd: newData =>
              //   new Promise(async (resolve, reject) => {
              //     const db = Parse.Object.extend("webimage");
              //     const newDoc = new db();
              //     const docRef = await newDoc.save();
              //     const data = [...data1];
              //     let obj = {
              //       ...newData,
              //       id: docRef.id,
              //       _id: docRef.id,
              //       objectId: docRef.id,
              //       companyId: companyObj.objectId,
              //       companyName: companyObj.name,
              //       albumId: rowData.objectId
              //     }
              //     data.push(obj);
              //     this.setState({ data1: data, newData: obj }, () => resolve());
              //   }).then(() => this.handleUpdate('2')),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  let data = [...data1];
                  const index = data.indexOf(oldData);
                  if (newData.iscover === true) {
                    data = data1.map(item => ({ ...item, iscover: false }));
                  }
                  data[index] = newData;
                  const obj = { ...newData };

                  this.setState({ data1: data, newData: obj, oldData }, () =>
                    resolve(),
                  );
                }).then(() => this.handleUpdate('2')),
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  const data = [...data1];
                  const index = data.indexOf(oldData);
                  data.splice(index, 1);
                  this.setState({ data1: data, oldData }, () => resolve());
                }).then(() => this.handleDelete('2')),
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button style={{ backgroundColor: '#27d0ca', color: '#fff' }} content="關閉" onClick={this.closeEdit} />
        </Modal.Footer>
      </Modal>
    </>);
  }
}

export default ModalEditPhotoes;
