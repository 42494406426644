import React from 'react';

// reactstrap components
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from 'reactstrap';

// core components

function SectionTestimonials2(props = {}) {
  // carousel - TESTIMONIALS 2
  const { form } = props;
  //  console.log(form)
  const {
    text2 = 'text2',
    text3 = 'text3',
    text4 = 'text4',
    text5 = 'text5',
    text6 = 'text6',
    text7 = 'text7',
    text8 = 'text8',
    text9 = 'text9',
    text10 = 'text10',
    text11 = 'text11',
    text12 = 'text12',
    text13 = 'text13',
  } = form;
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  const items = [
    {
      src: 'https://s3.amazonaws.com/uifaces/faces/twitter/mlane/128.jpg',
      content: (
        <CardBody>
          <h5 className="card-description">{text2}</h5>
          <CardFooter>
            <CardTitle tag="h4">{text3}</CardTitle>
            <h6 className="card-category">{text4}</h6>
            <div className="card-stars">
              <i className="fa fa-star mr-1" />
              <i className="fa fa-star mr-1" />
              <i className="fa fa-star mr-1" />
              <i className="fa fa-star mr-1" />
              <i className="fa fa-star" />
            </div>
          </CardFooter>
        </CardBody>
      ),
      altText: '',
      caption: '',
    },
    {
      src:
        'https://s3.amazonaws.com/creativetim_bucket/photos/134607/thumb.?1487680276',
      content: (
        <CardBody>
          <h5 className="card-description">{text5}</h5>
          <CardFooter>
            <CardTitle tag="h4">{text6}</CardTitle>
            <h6 className="card-category">{text7}</h6>
            <div className="card-stars">
              <i className="fa fa-star mr-1" />
              <i className="fa fa-star mr-1" />
              <i className="fa fa-star mr-1" />
              <i className="fa fa-star mr-1" />
              <i className="fa fa-star" />
            </div>
          </CardFooter>
        </CardBody>
      ),
      altText: '',
      caption: '',
    },
    {
      src:
        'https://s3.amazonaws.com/creativetim_bucket/photos/125268/thumb.jpeg?1497799215',
      content: (
        <CardBody>
          <h5 className="card-description">{text8}</h5>
          <CardFooter>
            <CardTitle tag="h4">{text9}</CardTitle>
            <h6 className="card-category">{text10}</h6>
            <div className="card-stars">
              <i className="fa fa-star mr-1" />
              <i className="fa fa-star mr-1" />
              <i className="fa fa-star mr-1" />
              <i className="fa fa-star mr-1" />
              <i className="fa fa-star" />
            </div>
          </CardFooter>
        </CardBody>
      ),
      altText: '',
      caption: '',
    },
  ];
  return (
    <>
      <div
        className="section section-testimonials cd-section"
        id="testimonials"
      >
        {/* ********* TESTIMONIALS 2 ********* */}
        <div className="testimonials-2 section section-testimonials">
          <Container>
            <Row>
              <Col className="mr-auto" md="2">
                <div className="testimonials-people">
                  <img
                    alt="..."
                    className="left-first-person add-animation"
                    src="https://s3.amazonaws.com/creativetim_bucket/photos/110862/thumb.?1482812727"
                  />
                  <img
                    alt="..."
                    className="left-second-person add-animation"
                    src="https://s3.amazonaws.com/creativetim_bucket/photos/139481/thumb.jpg?1485460613"
                  />
                  <img
                    alt="..."
                    className="left-third-person add-animation"
                    src="https://s3.amazonaws.com/creativetim_bucket/photos/152441/thumb.jpg?1488233314"
                  />
                  <img
                    alt="..."
                    className="left-fourth-person add-animation"
                    src="https://s3.amazonaws.com/creativetim_bucket/photos/134607/thumb.?1487680276"
                  />
                  <img
                    alt="..."
                    className="left-fifth-person add-animation"
                    src="https://s3.amazonaws.com/creativetim_bucket/photos/161506/thumb.?1489848178"
                  />
                  <img
                    alt="..."
                    className="left-sixth-person add-animation"
                    src="https://s3.amazonaws.com/creativetim_bucket/photos/77627/thumb.jpg?1487360092"
                  />
                </div>
              </Col>
              <Col className="ml-auto mr-auto" md="6">
                <div className="page-carousel">
                  <Carousel
                    activeIndex={activeIndex}
                    next={next}
                    previous={previous}
                  >
                    <CarouselIndicators
                      items={items}
                      activeIndex={activeIndex}
                      onClickHandler={goToIndex}
                    />
                    {items.map(item => {
                      return (
                        <CarouselItem
                          onExiting={onExiting}
                          onExited={onExited}
                          key={item.src}
                        >
                          <Card className="card-testimonial card-plain">
                            <div className="card-avatar">
                              <img alt="..." className="img" src={item.src} />
                            </div>
                            {item.content}
                          </Card>
                        </CarouselItem>
                      );
                    })}
                    <a
                      className="left carousel-control carousel-control-prev"
                      data-slide="prev"
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        previous();
                      }}
                      role="button"
                    >
                      <span className="fa fa-angle-left" />
                      <span className="sr-only">{text11}</span>
                    </a>
                    <a
                      className="right carousel-control carousel-control-next"
                      data-slide="next"
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        next();
                      }}
                      role="button"
                    >
                      <span className="fa fa-angle-right" />
                      <span className="sr-only">{text12}</span>
                    </a>
                  </Carousel>
                </div>
              </Col>
              <Col className="ml-auto" md="2">
                <div className="testimonials-people">
                  <img
                    alt="..."
                    className="right-first-person add-animation"
                    src="https://s3.amazonaws.com/uifaces/faces/twitter/mlane/128.jpg"
                  />
                  <img
                    alt="..."
                    className="right-second-person add-animation"
                    src="https://s3.amazonaws.com/creativetim_bucket/photos/125268/thumb.jpeg?1497799215"
                  />
                  <img
                    alt="..."
                    className="right-third-person add-animation"
                    src="https://s3.amazonaws.com/creativetim_bucket/photos/123334/thumb.JPG?1479459618"
                  />
                  <img
                    alt="..."
                    className="right-fourth-person add-animation"
                    src="https://s3.amazonaws.com/creativetim_bucket/photos/118235/thumb.?1477435947"
                  />
                  <img
                    alt="..."
                    className="right-fifth-person add-animation"
                    src="https://s3.amazonaws.com/creativetim_bucket/photos/18/thumb.png?1431433244"
                  />
                  <img
                    alt="..."
                    className="right-sixth-person add-animation"
                    src="https://s3.amazonaws.com/creativetim_bucket/photos/167683/thumb.?1491014996"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* ********* END TESTIMONIALS 2 ********* */}
      </div>
    </>
  );
}

export default SectionTestimonials2;
